import React, { Fragment, useState } from 'react';
import { Row, Col, Modal } from 'antd';

import HeaderFilter from './HeaderFilter/TopTitleSection';
import MemberProfile from './PremiumProfile';
import CataloguesGrid from './PremiumGrid';

import { SelectionModelComp, InventoryModelComp } from '../../../Containers';

const PremiumCatalogue = () => {
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState(false);

  const showModal = (value) => {
    setVisible(true);
    setModalType(value);
  };

  const handleCancel = e => {
    setVisible(false);
  };

  return (
    <Fragment>
      <Row gutter={16}>
        <Col className="mb25" span={24}>
          <HeaderFilter />
          <MemberProfile />
          <CataloguesGrid showModal={showModal} />
        </Col>
      </Row>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        className={modalType === "inventory" ? "detail-model" : "modal-style"}
        width={450}
      >
        {
          modalType === "add_to_selection" &&
          <SelectionModelComp handleCloseSelection={handleCancel} />
        }
        {
          modalType === "inventory" &&
          <InventoryModelComp showTableEditor={showModal} />
        }
      </Modal>
    </Fragment>
  )
}

export default PremiumCatalogue;