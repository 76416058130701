import { apiNew } from '../../service/newApi';
import * as types from '../Constants';


export const getTradingList = (currentTab, limit, skip, name) => {
  const options = {
    url: `trading-tasks?type=${currentTab}&skip=${skip}&limit=${limit}&name=${name}`
  };

  options.types = [
    types.GET_TRADING_TASKS_SUCCESS,
    types.GET_TRADING_TASKS_FAILURE
  ];

  return apiNew.get(options);
}

export const getUserAllList = () => {
  const options = {
    url: `users/all`
  };

  options.types = [
    types.GET_USER_LIST_SUCCESS,
    types.GET_USER_LIST_FAILURE
  ];

  return apiNew.get(options);
}

export const getDepartmentsWithUsers = () => {
  const options = {
    url: `department-users`
  };

  options.types = [
    types.GET_DEPARTMENTS_WITH_USERS_SUCCESS,
    types.GET_DEPARTMENTS_WITH_USERS_FAILURE
  ];

  return apiNew.get(options);
}

export const getTradingTask = (id, data) => {
  const options = {
    url: `trading-tasks/${id}`
  };

  options.types = [
    types.PATCH_TRADING_TASK_SUCCESS,
    types.PATCH_TRADING_TASK_FAILURE
  ];

  return apiNew.patch(options, data);
}

export const updateTradingTaskApproval = (task_id, approval_id, data) => {
  const options = {
    url: `trading-tasks/${task_id}/approvals/${approval_id}/status`
  };

  options.types = [
    types.PATCH_TRADING_TASK_APPROVAL_SUCCESS,
    types.PATCH_TRADING_TASK_APPROVAL_FAILURE
  ];

  return apiNew.put(options, data);
}





