import React from "react";
import { Table, Space, Tooltip, Card, Button, Spin, Empty } from "antd";
import { Link } from "react-router-dom";
import { getOnlyMinutes } from "../../Common/reUseFunctions";
import PermissionsComponent from "./../../Common/PermissionsComponent";
import { getRolesPermissions } from "../../Common/reUseFunctions";
import { CaretUpOutlined, CaretDownFilled } from "@ant-design/icons";

const { Meta } = Card;

const DataMaintenanceList = ({
  data,
  showModal,
  loading,
  delConfirmModal,
  mainUserRes,
  setorderDisorder,
  orderDisorder,
}) => {
  // const onConfirmDelete = (selectedData) => {
  //   onDeleteAsset(selectedData && selectedData._id).then(() => {
  //     getAssestsList(search, currentPage);
  //     message.success("Deleted Successfully");
  //   });
  // };

  const tableTitle = (value) => {
    return (
      value && (
        <div className="title-icon-flex">
          {value}
          <strong className="inventory-title-icon">
            <CaretUpOutlined
              style={{ opacity: orderDisorder === true ? 1 : 0.4 }}
              onClick={() => setorderDisorder(true)}
            />
            <span className="mt-disasendingorder">
              <CaretDownFilled
                style={{ opacity: orderDisorder === true ? 0.4 : 1 }}
                onClick={() => setorderDisorder(false)}
              />
            </span>
          </strong>
        </div>
      )
    );
  };

  const columns = [
    {
      title: tableTitle("STATUS"),
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        if (record.shared_at) {
          return "Published";
        } else {
          if (record.market_ready === true) {
            return "Market-Ready";
          }

          if (record.market_ready === false) {
            return "Non Market-Ready";
          }
        }
      },
    },
    {
      title: tableTitle("Title"),
      dataIndex: "title",
      key: "title.",
      render: (text, record) => {
        return getRolesPermissions(mainUserRes, "asset.edit") === false ? (
          <Link to={`/asset-management/${record._id}`}>
            <Meta
              className="meta-avatar-align meta-link-avatar"
              title={text && text.value}
            />
          </Link>
        ) : (
          <Link>
            <Meta
              className="meta-avatar-align meta-link-avatar"
              title={text && text.value}
            />
          </Link>
        );
      },
    },
    {
      title: tableTitle("LENGTH"),
      dataIndex: "versions",
      key: "versions",
      render: (text) => text[0] && getOnlyMinutes(text[0].duration),
    },
    // {
    //   title: "DIRECTOR",
    //   dataIndex: "directors",
    //   key: "directors",
    //   render: (text) => <>{text && text && <div>{text.join(", ")}</div>}</>,
    // },
    // {
    //   title: "LANGUAGES",
    //   key: "title",
    //   dataIndex: "title",
    //   render: (text) =>
    //     text && <Tag className="text-uppercase">{text.language}</Tag>,
    // },
    {
      title: tableTitle("PRODUCTION COMPANY"),
      dataIndex: "production_company",
      key: "production_company",
      render: (text) => <div>{text && text.value}</div>,
    },
    {
      title: tableTitle("PRODUCTION YEAR"),
      dataIndex: "production_date",
      key: "production_date",
    },
    // {
    //   title: "Created By",
    //   dataIndex: "owner",
    //   key: "owner",
    // },
    // {
    //   title: "Created Date",
    //   dataIndex: "created_at",
    //   key: "created_at",
    //   render: (text) => <span>{toDateString(text)}</span>,
    // },
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <Space size="middle" className="action-table">
          {getRolesPermissions(mainUserRes, "asset.show") === false ? (
            <Tooltip title="View">
              <Button onClick={() => showModal("view", record)} type="link">
                <u>View</u>
              </Button>
            </Tooltip>
          ) : (
            ""
          )}

          {getRolesPermissions(mainUserRes, "asset.edit") === false ? (
            <Tooltip title={"Edit"}>
              <Link to={`/asset-management/${record._id}`}>
                <Button onClick={() => showModal("edit", record)} type="link">
                  <u> Edit</u>
                </Button>
              </Link>
            </Tooltip>
          ) : (
            ""
          )}

          {getRolesPermissions(mainUserRes, "asset.delete") === false ? (
            <Tooltip title="Delete">
              <Button onClick={() => delConfirmModal(record)} type="link">
                <u>Delete</u>
              </Button>
            </Tooltip>
          ) : (
            ""
          )}

          {/* <Tooltip title="Publish">
            <Button type="link">
              <u>Publish</u>
            </Button>
          </Tooltip>

          <Button type="link" onClick={() => showTableEditor("update_asset")}>
            <SyncOutlined />
          </Button> */}
        </Space>
      ),
    },
  ];

  return (
    <Spin size="large" spinning={loading}>
      {getRolesPermissions(mainUserRes, "asset.list") === false ? (
        <Table
          pagination={false}
          className="table-style"
          columns={columns}
          dataSource={
            data && data.assets && data.assets.length > 0 && data.assets
          }
          rowClassName={(record, index) =>
            index % 2 === 0 ? "table-row-light" : "table-row-dark"
          }
          rowKey={(record) => `asset-${record._id}`}
        />
      ) : (
        <div className="d-flex justify-center full-width">
          <Empty />
        </div>
      )}
    </Spin>
  );
};

export default DataMaintenanceList;
