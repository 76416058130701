import React, { useState, Fragment, useEffect } from "react";
import { Row, Col, Modal } from "antd";

import SelectionVendorList from "./VendorAssetList";
import SelectionVendorView from "./VendorAssetListView";
import FormalLicenseEdit from "./FormalLicenseEdit";
import RequestProposalModal from "./RequestProposalModal";
import FilterHeader from "./HeaderFilter";

const selectionAssets = [
  {
    name: "Toonz Media Group",
    _id: "1",
    vendor_list: [
      {
        name: "Europakonzert 2020 from Berlin",
        _id: "1",
      },
      {
        name: "Gala from Berlin 2019",
        _id: "2",
      },
      {
        name: "Rachmaninoff revisited",
        _id: "3",
      },
    ],
  },
  {
    name: "August Media",
    _id: "2",
    vendor_list: [
      {
        name: "Europakonzert 2020 from Berlin",
        _id: "4",
      },
      {
        name: "Gala from Berlin 2019",
        _id: "5",
      },
      {
        name: "Rachmaninoff revisited",
        _id: "6",
      },
    ],
  },
  {
    name: "DMG Entertainment",
    _id: "3",
    vendor_list: [
      {
        name: "Europakonzert 2020 from Berlin",
        _id: "7",
      },
      {
        name: "Gala from Berlin 2019",
        _id: "8",
      },
      {
        name: "Rachmaninoff revisited",
        _id: "9",
      },
    ],
  },
];

const VendorAssets = ({
  onUpdateTradingList,
  updateTradingListRes,
  onCreateTradingList,
  onGetAllTradingTask,
  postTradingTaskRes,
  onGetFormalLicenseList,
  getFormalLicenseListRes,
  selectedItemData,
  location,
  onGetUserManagementList,
  userManagementList,
  onGetUserList,
  getUserListRes,
  mainUserRes,
  departmentsWithUsersRes,
  onGetDepartmentsWithUsers,
  assetSelectionFunc,
  history,
  selectionAssetsListRes,
  updateSelection,
  onUpdateSelection,
  selectionDetail,
}) => {
  const [visible, setVisible] = useState(false);
  const [modelType, setModelType] = useState(false);
  const [assetObject, setAssetObject] = useState([]);

  useEffect(() => {
    onGetUserManagementList(0, 9, "blocked", "pending", "active", "");
  }, []);

  useEffect(() => {
    onGetUserList();
    onGetDepartmentsWithUsers();
  }, []);

  const handleCancel = (e) => {
    setVisible(false);
  };

  const showModal = (e) => {
    setVisible(true);
    setModelType(e);
  };

  return (
    <Fragment>
      <div className="select-right-filter">
        <FilterHeader />
      </div>
      <Row gutter="16" style={{ marginTop: "0px" }}>
        <Col span={12}>
          <SelectionVendorList
            selectionAssets={selectionAssets}
            showModal={showModal}
            onCreateTradingList={onCreateTradingList}
            postTradingTaskRes={postTradingTaskRes}
            assetSelectionFunc={assetSelectionFunc}
            onGetFormalLicenseList={onGetFormalLicenseList}
            setAssetObject={setAssetObject}
            history={history}
            selectionAssetsListRes={selectionAssetsListRes}
            updateSelection={updateSelection}
            onUpdateSelection={onUpdateSelection}
            selectionDetail={selectionDetail}
            userManagementList={userManagementList}
            getUserListRes={getUserListRes}
            mainUserRes={mainUserRes}
            departmentsWithUsersRes={departmentsWithUsersRes}
          />
        </Col>
        <Col span={12}>
          <SelectionVendorView
            showModal={showModal}
            getFormalLicenseListRes={getFormalLicenseListRes}
            selectedItemData={selectedItemData}
            location={location}
            assetObject={assetObject}
          />
        </Col>
      </Row>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        className="modal-style"
        width={modelType === "request" ? 550 : 610}
      >
        {(modelType === "edit" || modelType === "add") && (
          <FormalLicenseEdit modelType={modelType} showModal={showModal} />
        )}
        {modelType === "request" && (
          <RequestProposalModal
            modelType={modelType}
            handleCancel={handleCancel}
          />
        )}
      </Modal>
    </Fragment>
  );
};

export default VendorAssets;
