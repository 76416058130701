import React from "react";

const AssetTriler = ({ getInventorySeachDetailRes }) => {
  return (
    <div className="asset-detial-video">
      <div class="container">
        {/* Image start */}
        <div class="image-wrapper">
          <img 
            src={
              getInventorySeachDetailRes &&
              getInventorySeachDetailRes.preview_image[0]
            }
            alt="images" 
          />
        </div>
        {/* Image end */}
      </div>
      {/* <img
        src={
          getInventorySeachDetailRes &&
          getInventorySeachDetailRes.preview_image[0]
        }
        alt="imagessssssss"
      /> */}
    </div>
  );
};

export default AssetTriler;
