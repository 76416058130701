import React from "react";
import ReactPlayer from "react-player";

const videoPlayer = ({ url }) => {
  return (
    <div className="width-video">
      <ReactPlayer url={url} controls={true} playing={true} light={true} />
    </div>
  );
};

export default videoPlayer;
