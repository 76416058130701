import React, { useEffect } from "react";
import { Form, Input, Button, message, Radio } from "antd";

const SelectionForm = ({
  search,
  selectSales,
  selectPurchase,
  handleCancel,
  modalType,
  onUpdateSelection,
  onGetSelectionsList,
  onCreateSelection,
  onGetSelectionDetail,
  selectedData,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (modalType === "edit") {
      onGetSelectionDetail(selectedData && selectedData._id).then((res) => {
        form.setFieldsValue({
          name: res && res.payload && res.payload.name,
          type: res && res.payload && res.payload.type,
        });
      });
    }
  }, []);

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinish = (values) => {
    if (modalType === "edit") {
      onUpdateSelection(selectedData && selectedData._id, values).then(
        (res) => {
          if (res && res.type === "UPDATE_SELECTION_SUCCESS") {
            onGetSelectionsList(search, selectSales, selectPurchase);
            message.success(res && res.payload && res.payload.message);
            handleCancel();
          }
        }
      );
    }
    if (modalType === "add-selection") {
      if (values && values.type) {
        onCreateSelection(values).then((res) => {
          if (res && res.type === "CREATE_SELECTION_SUCCESS") {
            onGetSelectionsList(search, selectSales, selectPurchase);
            message.success(res && res.payload && res.payload.message);
            handleCancel();
          }
        });
      } else {
        message.info("Please Select Selection Type");
      }
    }
  };

  return (
    <Form
      form={form}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      {modalType === "edit" ? (
        <h1 className="mb10">Update Selection</h1>
      ) : (
        <h1 className="mb10">Add Selection</h1>
      )}

      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: "Please input your Selection name!",
          },
        ]}
      >
        <Input placeholder="Selection Name" />
      </Form.Item>
      <Form.Item
        name="type"
        rules={[
          {
            required: true,
            message: "Please Selection Type!",
          },
        ]}
      >
        <Radio.Group>
          <Radio value="sale">Sale</Radio>
          <Radio value="purchase">Purchase</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item className="mb5">
        <div className="text-right asset-action-btn">
          <Button type="default" onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" className="ml10">
            {modalType === "edit" ? "Update" : "Create"}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default SelectionForm;
