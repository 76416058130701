import React, { useEffect } from "react";
import { List } from "antd";
import { getOnlyHoursMinutes } from "./../Common/reUseFunctions";

const Notifications = ({
  data,
  onGetNotifications,
  getNotificationsRes,
  onPutNotifications,
  putNotificationsRes,
}) => {
  const getNotificationsId = (id) => {
    onPutNotifications(id).then(() => {
      onGetNotifications();
    });
  };
  console.log("getNotificationsRes--->", getNotificationsRes);

  return (
    <div className="notifications-list-style">
      <List
        itemLayout="horizontal"
        dataSource={getNotificationsRes}
        renderItem={(item) => (
          <div onClick={() => getNotificationsId(item._id)}>
            <List.Item>
              <List.Item.Meta
                avatar={
                  <div className="notification-avatar">{item.avatar}</div>
                }
                title={<a href="!#">{item.message}</a>}
                description={getOnlyHoursMinutes(item.created_at)}
              />
            </List.Item>
          </div>
        )}
      />
    </div>
  );
};

export default Notifications;
