import React from "react";
import { Row, Col, Button, Pagination } from "antd";
import { Link } from "react-router-dom";

const GeneralCataloguesGrid = ({
  data,
  showModal,
  currentPage,
  setCurrentPage,
  setSelectedData,
}) => {
  const setSelection = (catalogue) => {
    setSelectedData(catalogue);
    showModal("add_to_selection");
  };

  return (
    <div className="p-cata-grid">
      <h2>General Catalogue</h2>
      {data && data.length ? (
        <>
          <Row gutter={16} className="column-10">
            {data.map((catalogue) => (
              <Col className="mb25" span={4} key={catalogue._id}>
                <div className="new-catalogues-grid">
                  <div class="container">
                    {/* Image start */}
                    <div class="image-wrapper">
                      <img 
                        src={catalogue.preview_image}
                        alt={catalogue.title.value} 
                      />
                    </div>
                    {/* Image end */}
                  </div>
                  {/* <img
                    src={catalogue.preview_image}
                    alt={catalogue.title.value}
                  /> */}
                  <div className="new-catalogues-info">
                    <h3>{catalogue.title.value}</h3>
                    <h4>
                      Production Year: <span>{catalogue.production_date}</span>
                    </h4>
                    <div className="catalouge-btns">
                      <Link to={`/inventory-search/${catalogue._id}`}>
                        {" "}
                        <Button type="link" className="p0 border-0">
                          {" "}
                          Detail
                        </Button>
                      </Link>
                      <Button
                        type="link"
                        className="p0 border-0"
                        onClick={() => setSelection(catalogue)}
                      >
                        {" "}
                        Add to Selection
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>

          <Row gutter={16}>
            <Col span={24} className="d-flex justify-center mt30">
              <Pagination
                defaultCurrent={currentPage}
                defaultPageSize={
                  data && data.pagination && data.pagination.limit
                }
                onChange={setCurrentPage}
                total={data && data.pagination && data.pagination.total}
              />
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default GeneralCataloguesGrid;
