import React, { useState, useEffect } from "react";
import Filter from "./Filter";
import SelectionList from "./List";

const Selection = ({
  assetID,
  mainUserRes,
  selectedData,
  onGetSelectionsList,
  selectionsList,
  onCreateSelection,
  onGetSelectionDetail,
  selectionDetail,
  onUpdateSelection,
  handleCloseSelection,
  onAddAssetInSelection,
}) => {
  const [search, setSearch] = useState("");

  useEffect(() => {
    onGetSelectionsList(search, true, true);
  }, [search]);

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div>
      <Filter onSearch={onSearch} search={search} />
      <SelectionList
        selectionsList={selectionsList}
        onCreateSelection={onCreateSelection}
        onGetSelectionsList={onGetSelectionsList}
        onUpdateSelection={onUpdateSelection}
        onGetSelectionDetail={onGetSelectionDetail}
        selectionDetail={selectionDetail}
        handleCloseSelection={handleCloseSelection}
        onAddAssetInSelection={onAddAssetInSelection}
        selectedData={selectedData}
        assetID={assetID}
        mainUserRes={mainUserRes}
      />
    </div>
  );
};

export default Selection;
