// Auth

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const GET_SESSION_SUCCESS = "GET_SESSION_SUCCESS";
export const GET_SESSION_FAILURE = "GET_SESSION_FAILURE";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

// WALLET

export const GET_WALLET_LIST_SUCCESS = "GET_WALLET_LIST_SUCCESS";
export const GET_WALLET_LIST_FAILURE = "GET_WALLET_LIST_FAILURE";

export const GET_WALLET_DETAIL_SUCCESS = "GET_WALLET_DETAIL_SUCCESS";
export const GET_WALLET_DETAIL_FAILURE = "GET_WALLET_DETAIL_FAILURE";

export const CREATE_WALLET_SUCCESS = "CREATE_WALLET_SUCCESS";
export const CREATE_WALLET_FAILURE = "CREATE_WALLET_FAILURE";

export const UPDATE_WALLET_SUCCESS = "UPDATE_WALLET_SUCCESS";
export const UPDATE_WALLET_FAILURE = "UPDATE_WALLET_FAILURE";

export const DELETE_WALLET_SUCCESS = "DELETE_WALLET_SUCCESS";
export const DELETE_WALLET_FAILURE = "DELETE_WALLET_FAILURE";

export const GET_WALLET_BALANCE_SUCCESS = "GET_WALLET_BALANCE_SUCCESS";
export const GET_WALLET_BALANCE_FAILURE = "GET_WALLET_BALANCE_FAILURE";

// ASSETS

export const GET_ASSETS_LIST_SUCCESS = "GET_ASSETS_LIST_SUCCESS";
export const GET_ASSETS_LIST_FAILURE = "GET_ASSETS_LIST_FAILURE";

export const GET_ASSET_DETAIL_SUCCESS = "GET_ASSET_DETAIL_SUCCESS";
export const GET_ASSET_DETAIL_FAILURE = "GET_ASSET_DETAIL_FAILURE";

export const GET_ASSET_DETAILS_SUCCESS = "GET_ASSET_DETAILS_SUCCESS";
export const GET_ASSET_DETAILS_FAILURE = "GET_ASSET_DETAILS_FAILURE";

export const CREATE_ASSET_SUCCESS = "CREATE_ASSET_SUCCESS";
export const CREATE_ASSET_FAILURE = "CREATE_ASSET_FAILURE";

export const UPDATE_ASSET_SUCCESS = "UPDATE_ASSET_SUCCESS";
export const UPDATE_ASSET_FAILURE = "UPDATE_ASSET_FAILURE";

export const DELETE_ASSET_SUCCESS = "DELETE_ASSET_SUCCESS";
export const DELETE_ASSET_FAILURE = "DELETE_ASSET_FAILURE";

// INVENTORY SEARCH

export const GET_MARKET_READY_SUCCESS = "GET_MARKET_READY_SUCCESS";
export const GET_MARKET_READY_FAILURE = "GET_MARKET_READY_FAILURE";

// ISCC CODE GENERATION

export const ISCC_CODE_GENERATION_SUCCESS = "ISCC_CODE_GENERATION_SUCCESS";
export const ISCC_CODE_GENERATION_FAILURE = "ISCC_CODE_GENERATION_FAILURE";

export const ISCC_CODE_GENERATION_PROCESS_SUCCESS =
  "ISCC_CODE_GENERATION_PROCESS_SUCCESS";
export const ISCC_CODE_GENERATION_PROCESS_FAILURE =
  "ISCC_CODE_GENERATION_PROCESS_FAILURE";

export const ISCC_CODE_UPDATING_SUCCESS = "ISCC_CODE_UPDATING_SUCCESS";
export const ISCC_CODE_UPDATING_FAILURE = "ISCC_CODE_UPDATING_FAILURE";

// LOAD SIGN LICENSE

export const LOAD_SIGN_LICENSE_SUCCESS = "LOAD_SIGN_LICENSE_SUCCESS";
export const LOAD_SIGN_LICENSE_FAILURE = "LOAD_SIGN_LICENSE_FAILURE";

// REQUEST SIGNATURE

export const REQUEST_SIGNATURE_SUCCESS = "REQUEST_SIGNATURE_SUCCESS";
export const REQUEST_SIGNATURE_FAILURE = "REQUEST_SIGNATURE_FAILURE";

export const LICENSE_REQUEST_SIGNATURE_PROCESS_SUCCESS =
  "LICENSE_REQUEST_SIGNATURE_PROCESS_SUCCESS";
export const LICENSE_REQUEST_SIGNATURE_PROCESS_FAILURE =
  "LICENSE_REQUEST_SIGNATURE_PROCESS_FAILURE";

// PUBLISH LICENSE BLOCKCHAIN

export const PUBLISH_LICENSE_BLOCKCHAIN_SUCCESS =
  "PUBLISH_LICENSE_BLOCKCHAIN_SUCCESS";
export const PUBLISH_LICENSE_BLOCKCHAIN_FAILURE =
  "PUBLISH_LICENSE_BLOCKCHAIN_FAILURE";

// PUBLISH ASSET METADATA

export const PUBLISH_ASSET_METADATA_SUCCESS = "PUBLISH_ASSET_METADATA_SUCCESS";
export const PUBLISH_ASSET_METADATA_FAILURE = "PUBLISH_ASSET_METADATA_FAILURE";

// UNPUBLISH ASSET METADATA

export const UNPUBLISH_ASSET_METADATA_SUCCESS =
  "UNPUBLISH_ASSET_METADATA_SUCCESS";
export const UNPUBLISH_ASSET_METADATA_FAILURE =
  "UNPUBLISH_ASSET_METADATA_FAILURE";

// SELECTIONS

export const GET_SELECTIONS_LIST_SUCCESS = "GET_SELECTIONS_LIST_SUCCESS";
export const GET_SELECTIONS_LIST_FAILURE = "GET_SELECTIONS_LIST_FAILURE";

export const GET_SELECTION_DETAIL_SUCCESS = "GET_SELECTION_DETAIL_SUCCESS";
export const GET_SELECTION_DETAIL_FAILURE = "GET_SELECTION_DETAIL_FAILURE";

export const GET_ASSETS_SELECTION_SUCCESS = "GET_ASSETS_SELECTION_SUCCESS";
export const GET_ASSETS_SELECTION_FAILURE = "GET_ASSETS_SELECTION_FAILURE";

export const CREATE_SELECTION_SUCCESS = "CREATE_SELECTION_SUCCESS";
export const CREATE_SELECTION_FAILURE = "CREATE_SELECTION_FAILURE";

export const UPDATE_SELECTION_SUCCESS = "UPDATE_SELECTION_SUCCESS";
export const UPDATE_SELECTION_FAILURE = "UPDATE_SELECTION_FAILURE";

export const DELETE_SELECTION_SUCCESS = "DELETE_SELECTION_SUCCESS";
export const DELETE_SELECTION_FAILURE = "DELETE_SELECTION_FAILURE";

export const DELETE_SELECTION_ASSET_SUCCESS = "DELETE_SELECTION_ASSET_SUCCESS";
export const DELETE_SELECTION_ASSET_FAILURE = "DELETE_SELECTION_ASSET_FAILURE";

export const ADD_FORMAL_LICENSE_SUCCESS = "ADD_FORMAL_LICENSE_SUCCESS";
export const ADD_FORMAL_LICENSE_FAILURE = "ADD_FORMAL_LICENSE_FAILURE";

export const GET_FORMAL_LICENSE_LIST_SUCCESS =
  "GET_FORMAL_LICENSE_LIST_SUCCESS";
export const GET_FORMAL_LICENSE_LIST_FAILURE =
  "GET_FORMAL_LICENSE_LIST_FAILURE";

export const DELETE_FORMAL_LICENSE_LIST_SUCCESS =
  "DELETE_FORMAL_LICENSE_LIST_SUCCESS";
export const DELETE_FORMAL_LICENSE_LIST_FAILURE =
  "DELETE_FORMAL_LICENSE_LIST_FAILURE";

export const UPATE_FORMAL_LICENSE_LIST_SUCCESS =
  "UPATE_FORMAL_LICENSE_LIST_SUCCESS";
export const UPATE_FORMAL_LICENSE_LIST_FAILURE =
  "UPATE_FORMAL_LICENSE_LIST_FAILURE";

export const GET_ALL_ORGANIZATION_SUCCESS = "GET_ALL_ORGANIZATION_SUCCESS";
export const GET_ALL_ORGANIZATION_FAILURE = "GET_ALL_ORGANIZATION_FAILURE";

// CHAT

export const ADD_MESSAGE = "ADD_MESSAGE";
export const MESSAGE_RECEIVED = "MESSAGE_RECEIVED";
export const ADD_USER = "ADD_USER";
export const USERS_LIST = "USERS_LIST";

// ROLES

//CREATE//
export const CREATE_ROLES_SUCCESS = "CREATE_ROLES_SUCCESS";
export const CREATE_ROLES_FAILURE = "CREATE_ROLES_FAILURE";
//GET LIST//
export const GET_ROLES_LIST_SUCCESS = "GET_ROLES_LIST_SUCCESS";
export const GET_ROLES_LIST_FAILURE = "GET_ROLES_LIST_FAILURE";
//GET ROLE WITH ID//
export const GET_SPECIFIC_ROLES_SUCCESS = "GET_SPECIFIC_ROLES_SUCCESS";
export const GET_SPECIFIC_ROLES_FAILURE = "GET_SPECIFIC_ROLES_FAILURE";
//UPDATE ROLE WITH ID//
export const UPDATE_ROLES_SUCCESS = "UPDATE_ROLES_SUCCESS";
export const UPDATE_ROLES_FAILURE = "UPDATE_ROLES_FAILURE";
//DELETE ROLE WITH ID//
export const DELETE_ROLES_SUCCESS = "DELETE_ROLES_SUCCESS";
export const DELETE_ROLES_FAILURE = "DELETE_ROLES_FAILURE";

// USERS MANAGEMENT

//GET LIST//
export const GET_USERS_LIST_SUCCESS = "GET_USERS_LIST_SUCCESS";
export const GET_USERS_LIST_FAILURE = "GET_USERS_LIST_FAILURE";

//GET DEPARTMENTS WITH USERS//
export const GET_DEPARTMENTS_WITH_USERS_SUCCESS =
  "GET_DEPARTMENTS_WITH_USERS_SUCCESS";
export const GET_DEPARTMENTS_WITH_USERS_FAILURE =
  "GET_DEPARTMENTS_WITH_USERS_FAILURE";

// GET ALL USERS
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAILURE = "GET_ALL_USERS_FAILURE";

//ADD NEW USER//
export const CREATE_NEW_USER_INVITE_SUCCESS = "CREATE_NEW_USER_INVITE_SUCCESS";
export const CREATE_NEW_USER_INVITE_FAILURE = "CREATE_NEW_USER_INVITE_FAILURE";

//A SPECIFIC USER DETAIL//
export const GET_SPECIFIC_USER_DETAIL_SUCCESS =
  "GET_SPECIFIC_USER_DETAIL_SUCCESS";
export const GET_SPECIFIC_USER_DETAIL_FAILURE =
  "GET_SPECIFIC_USER_DETAIL_FAILURE";

//GET USER PROFILE PICTURE//
export const GET_USER_PICTURE_SUCCESS = "GET_USER_PICTURE_SUCCESS";
export const GET_USER_PICTURE_FAILURE = "GET_USER_PICTURE_FAILURE";

//USER DELETE//
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILURE = "USER_DELETE_FAILURE";

//UPDATE NEW PASSWORD//
export const UPDATE_NEW_PASSWORD_SUCCESS = "UPDATE_NEW_PASSWORD_SUCCESS";
export const UPDATE_NEW_PASSWORD_FAILURE = "UPDATE_NEW_PASSWORD_FAILURE";

//UPDATE PROFILE AND USERNAME//
export const UPDATE_USERPRIFILE_AND_USERNAME_SUCCESS =
  "UPDATE_USERPRIFILE_AND_USERNAME_SUCCESS";
export const UPDATE_USERPRIFILE_AND_USERNAME_FAILURE =
  "UPDATE_USERPRIFILE_AND_USERNAME_FAILURE";

//POST FORGOT PASSWORD//
export const POST_FORGOT_PASSWORD_SUCCESS = "POST_FORGOT_PASSWORD_SUCCESS";
export const POST_FORGOT_PASSWORD_FAILURE = "POST_FORGOT_PASSWORD_FAILURE";

//BLOCK SPECIFIC USER//
export const GET_BLOCK_SPECIFIC_USER_SUCCESS =
  "GET_BLOCK_SPECIFIC_USER_SUCCESS";
export const GET_BLOCK_SPECIFIC_USER_FAILURE =
  "GET_BLOCK_SPECIFIC_USER_FAILURE";

//BLOCK SPECIFIC USER//
export const GET_UNBLOCK_SPECIFIC_USER_SUCCESS =
  "GET_UNBLOCK_SPECIFIC_USER_SUCCESS";
export const GET_UNBLOCK_SPECIFIC_USER_FAILURE =
  "GET_UNBLOCK_SPECIFIC_USER_FAILURE";

//PROFILE UPDATE SPECIFIC USER//
export const GET_PROFILE_STATUS_USER_SUCCESS =
  "GET_PROFILE_STATUS_USER_SUCCESS";
export const GET_PROFILE_STATUS_USER_FAILURE =
  "GET_PROFILE_STATUS_USER_FAILURE";

//PERMISSIONS//
export const GET_AVAILABLE_PERMISSIONS_SUCCESS =
  "GET_AVAILABLE_PERMISSIONS_SUCCESS";
export const GET_AVAILABLE_PERMISSIONS_FAILURE =
  "GET_AVAILABLE_PERMISSIONS_FAILURE";

// UESR PICTURE_//
export const POST_UESR_PICTURE_SUCCESS = "POST_UESR_PICTURE_SUCCESS";
export const POST_UESR_PICTURE_FAILURE = "POST_UESR_PICTURE_FAILURE";

// UPDATE USER INFORMATION //
export const UPDATE_USER_INFORMATION_SUCCESS =
  "UPDATE_USER_INFORMATION_SUCCESS";
export const UPDATE_USER_INFORMATION_FAILURE =
  "UPDATE_USER_INFORMATION_FAILURE";

//A SPECIFIC USER DETAIL//
export const GET_MAIN_USER_DETAIL_SUCCESS = "GET_MAIN_USER_DETAIL_SUCCESS";
export const GET_MAIN_USER_DETAIL_FAILURE = "GET_MAIN_USER_DETAIL_FAILURE";

// SET PASSWORD FOR USER//
export const POST_SET_USER_PASSWORD_SUCCESS = "POST_SET_USER_PASSWORD_SUCCESS";
export const POST_SET_USER_PASSWORD_FAILURE = "POST_SET_USER_PASSWORD_FAILURE";

//ORGANIZATIONS//

export const GET_ALL_ORGANIZATIONS_SUCCESS = "GET_ALL_ORGANIZATIONS_SUCCESS";
export const GET_ALL_ORGANIZATIONS_FAILURE = "GET_ALL_ORGANIZATIONS_FAILURE";

//ORGANIZATION//

export const CREATE_ORGANIZATION_SUCCESS = "CREATE_ORGANIZATION_SUCCESS";
export const CREATE_ORGANIZATION_FAILURE = "CREATE_ORGANIZATION_FAILURE";

export const GET_ORGANIZATION_DETAIL_SUCCESS =
  "GET_ORGANIZATION_DETAIL_SUCCESS";
export const GET_ORGANIZATION_DETAIL_FAILURE =
  "GET_ORGANIZATION_DETAIL_FAILURE";

export const GET_ORGANIZATION_IMAGE_SUCCESS = "GET_ORGANIZATION_IMAGE_SUCCESS";
export const GET_ORGANIZATION_IMAGE_FAILURE = "GET_ORGANIZATION_IMAGE_FAILURE";

export const UPDATE_ORGANIZATION_PROFILE_SUCCESS =
  "UPDATE_ORGANIZATION_PROFILE_SUCCESS";
export const UPDATE_ORGANIZATION_PROFILE_FAILURE =
  "UPDATE_ORGANIZATION_PROFILE_FAILURE";

export const GET_ORGANIZATION_PROFILE_SUCCESS =
  "GET_ORGANIZATION_PROFILE_SUCCESS";
export const GET_ORGANIZATION_PROFILE_FAILURE =
  "GET_ORGANIZATION_PROFILE_FAILURE";

//CATALOGUES//

export const GET_CATALOGUES_SUCCESS = "GET_CATALOGUES_SUCCESS";
export const GET_CATALOGUES_FAILURE = "GET_CATALOGUES_SUCCESS";

// CATALOGUE HIGHLIGHTS

export const GET_HIGHLIGHTS_OF_ORGANIZATION_SUCCESS =
  "GET_HIGHTLIGHTS_OF_ORGANIZATION_SUCCESS";
export const GET_HIGHLIGHTS_OF_ORGANIZATION_FAILURE =
  "GET_HIGHTLIGHTS_OF_ORGANIZATION_FAILURE";

export const UPDATE_HIGHLIGHTS_OF_ORGANIZATION_SUCCESS =
  "UPDATE_HIGHTLIGHTS_OF_ORGANIZATION_SUCCESS";
export const UPDATE_HIGHLIGHTS_OF_ORGANIZATION_FAILURE =
  "UPDATE_HIGHTLIGHTS_OF_ORGANIZATION_FAILURE";

// GENERAL CATALOGUES //
export const GET_GENERAL_CATALOGUES_SUCCESS = "GET_GENERAL_CATALOGUES_SUCCESS";
export const GET_GENERAL_CATALOGUES_FAILURE = "GET_GENERAL_CATALOGUES_SUCCESS";

// SPECIAL CATALOGUES //
export const GET_SPECIAL_CATALOGUES_SUCCESS = "GET_SPECIAL_CATALOGUES_SUCCESS";
export const GET_SPECIAL_CATALOGUES_FAILURE = "GET_SPECIAL_CATALOGUES_SUCCESS";

export const GET_SPECIAL_CATALOGUE_SUCCESS = "GET_SPECIAL_CATALOGUE_SUCCESS";
export const GET_SPECIAL_CATALOGUE_FAILURE = "GET_SPECIAL_CATALOGUE_SUCCESS";

export const CREATE_SPECIAL_CATALOGUE_SUCCESS =
  "CREATE_SPECIAL_CATALOGUE_SUCCESS";
export const CREATE_SPECIAL_CATALOGUE_FAILURE =
  "CREATE_SPECIAL_CATALOGUE_SUCCESS";

export const DELETE_SPECIAL_CATALOGUE_SUCCESS =
  "DELETE_SPECIAL_CATALOGUE_SUCCESS";
export const DELETE_SPECIAL_CATALOGUE_FAILURE =
  "DELETE_SPECIAL_CATALOGUE_SUCCESS";

export const UPDATE_SPECIAL_CATALOGUE_SUCCESS =
  "UPDATE_SPECIAL_CATALOGUE_SUCCESS";
export const UPDATE_SPECIAL_CATALOGUE_FAILURE =
  "UPDATE_SPECIAL_CATALOGUE_SUCCESS";

// SPECIAL CATALOGUE ASSETS

export const ADD_ASSET_TO_SPECIAL_CATALOGUE_SUCCESS =
  "ADD_ASSET_TO_SPECIAL_CATALOGUE_SUCCESS";
export const ADD_ASSET_TO_SPECIAL_CATALOGUE_FAILURE =
  "ADD_ASSET_TO_SPECIAL_CATALOGUE_FAILURE";

export const DELETE_ASSET_FROM_SPECIAL_CATALOGUE_SUCCESS =
  "DELETE_ASSET_FROM_SPECIAL_CATALOGUE_SUCCESS";
export const DELETE_ASSET_FROM_SPECIAL_CATALOGUE_FAILURE =
  "DELETE_ASSET_FROM_SPECIAL_CATALOGUE_FAILURE";

//////// DEPARTMENT MANAGEMENT //////

//CREATE//
export const CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS";
export const CREATE_DEPARTMENT_FAILURE = "CREATE_DEPARTMENT_FAILURE";
//GET DEPARTMENT LIST//
export const GET_DEPARTMENT_LIST_SUCCESS = "GET_DEPARTMENT_LIST_SUCCESS";
export const GET_DEPARTMENT_LIST_FAILURE = "GET_DEPARTMENT_LIST_FAILURE";
//GET DEPARTMENT WITH ID//
export const GET_SPECIFIC_DEPARTMENT_SUCCESS =
  "GET_SPECIFIC_DEPARTMENT_SUCCESS";
export const GET_SPECIFIC_DEPARTMENT_FAILURE =
  "GET_SPECIFIC_DEPARTMENT_FAILURE";
//UPDATE DEPARTMENT WITH ID//
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_FAILURE = "UPDATE_DEPARTMENT_FAILURE";
//DELETE DEPARTMENT WITH ID//
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAILURE = "DELETE_DEPARTMENT_FAILURE";

//////// INVENTORY SEARCH //////
export const GET_INVENTORY_SUCCESS = "GET_INVENTORY_SUCCESS";
export const GET_INVENTORY_FAILURE = "GET_INVENTORY_FAILURE";

export const GET_INVENTORY_LIST_SUCCESS = "GET_INVENTORY_LIST_SUCCESS";
export const GET_INVENTORY_LIST_FAILURE = "GET_INVENTORY_LIST_FAILURE";

export const ADD_ASSET_IN_SELECTION_SUCCESS = "ADD_ASSET_IN_SELECTION_SUCCESS";
export const ADD_ASSET_IN_SELECTION_FAILURE = "ADD_ASSET_IN_SELECTION_FAILURE";

export const GET_INVENTORY_DETAIL_SUCCESS = "GET_INVENTORY_DETAIL_SUCCESS";
export const GET_INVENTORY_DETAIL_FAILURE = "GET_INVENTORY_DETAIL_FAILURE";

export const UPDATE_INVENTORY_HEADER_SUCCESS =
  "UPDATE_INVENTORY_HEADER_SUCCESS";
export const UPDATE_INVENTORY_HEADER_FAILURE =
  "UPDATE_INVENTORY_HEADER_FAILURE";

export const GET_INVENTORY_HEADER_SUCCESS = "GET_INVENTORY_HEADER_SUCCESS";
export const GET_INVENTORY_HEADER_FAILURE = "GET_INVENTORY_HEADER_FAILURE";

//////////// Trading Tasks /////////////

export const GET_TRADING_TASKS_SUCCESS = "GET_TRADING_TASKS_SUCCESS";
export const GET_TRADING_TASKS_FAILURE = "GET_TRADING_TASKS_FAILURE";

export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILURE = "GET_USER_LIST_FAILURE";

export const PATCH_TRADING_TASK_SUCCESS = "PATCH_TRADING_TASK_SUCCESS";
export const PATCH_TRADING_TASK_FAILURE = "PATCH_TRADING_TASK_FAILURE";

export const PATCH_TRADING_TASK_APPROVAL_SUCCESS =
  "PATCH_TRADING_TASK_APPROVAL_SUCCESS";
export const PATCH_TRADING_TASK_APPROVAL_FAILURE =
  "PATCH_TRADING_TASK_APPROVAL_FAILURE";

//////////// Automated Tasks /////////////

export const GET_ASSIGNED_TASKS_SUCCESS = "GET_ASSIGNED_TASKS_SUCCESS";
export const GET_ASSIGNED_TASKS_FAILURE = "GET_ASSIGNED_TASKS_FAILURE";

export const CREATE_ASSIGNED_TASK_SUCCESS = "CREATE_ASSIGNED_TASK_SUCCESS";
export const CREATE_ASSIGNED_TASK_FAILURE = "CREATE_ASSIGNED_TASK_FAILURE";

export const UPDATE_ASSIGNED_TASK_SUCCESS = "UPDATE_ASSIGNED_TASK_SUCCESS";
export const UPDATE_ASSIGNED_TASK_FAILURE = "UPDATE_ASSIGNED_TASK_FAILURE";

export const DELETE_ASSIGNED_TASK_SUCCESS = "DELETE_ASSIGNED_TASK_SUCCESS";
export const DELETE_ASSIGNED_TASK_FAILURE = "DELETE_ASSIGNED_TASK_FAILURE";

/////////// Request Proposal //////////////

export const UPDATE_TRADING_TASKS_SUCCESS = "UPDATE_TRADING_TASKS_SUCCESS";
export const UPDATE_TRADING_TASKS_FAILURE = "UPDATE_TRADING_TASKS_FAILURE";

export const CREATE_TRADING_TASKS_SUCCESS = "CREATE_TRADING_TASKS_SUCCESS";
export const CREATE_TRADING_TASKS_FAILURE = "CREATE_TRADING_TASKS_FAILURE";

export const ADD_TRADING_TASKS_SUCCESS = "ADD_TRADING_TASKS_SUCCESS";
export const ADD_TRADING_TASKS_FAILURE = "ADD_TRADING_TASKS_FAILURE";

export const POST_TRADING_TASKS_SUCCESS = "POST_TRADING_TASKS_SUCCESS";
export const POST_TRADING_TASKS_FAILURE = "POST_TRADING_TASKS_FAILURE";

//////////////// Selection Proposal ////////////////////

export const GET_FORMAL_LICENSE_SUCCESS = "GET_FORMAL_LICENSE_SUCCESS";
export const GET_FORMAL_LICENSE_FAILURE = "GET_FORMAL_LICENSE_FAILURE";

export const CREATE_FORMAL_LICENSE_SUCCESS = "CREATE_FORMAL_LICENSE_SUCCESS";
export const CREATE_FORMAL_LICENSE_FAILURE = "CREATE_FORMAL_LICENSE_FAILURE";

export const UPDATE_FORMAL_LICENSE_SUCCESS = "UPDATE_FORMAL_LICENSE_SUCCESS";
export const UPDATE_FORMAL_LICENSE_FAILURE = "UPDATE_FORMAL_LICENSE_FAILURE";

export const DELETE_FORMAL_LICENSE_SUCCESS = "DELETE_FORMAL_LICENSE_SUCCESS";
export const DELETE_FORMAL_LICENSE_FAILURE = "DELETE_FORMAL_LICENSE_FAILURE";

export const CANCEL_NEGOTIATION_SUCCESS = "CANCEL_NEGOTIATION_SUCCESS";
export const CANCEL_NEGOTIATION_FAILURE = "CANCEL_NEGOTIATION_FAILURE";

export const UPLOAD_AGREEMENT_FILE_SUCCESS = "UPLOAD_AGREEMENT_FILE_SUCCESS";
export const UPLOAD_AGREEMENT_FILE_FAILURE = "UPLOAD_AGREEMENT_FILE_FAILURE";

export const SEND_INVITATIONS_SUCCESS = "SEND_INVITATIONS_SUCCESS";
export const SEND_INVITATIONS_FAILURE = "SEND_INVITATIONS_FAILURE";

// Upload CSV File

export const UPLOAD_CSV_FILE_SUCCESS = "UPLOAD_CSV_FILE_SUCCESS";
export const UPLOAD_CSV_FILE_FAILURE = "UPLOAD_CSV_FILE_FAILURE";

// Upload XML File

export const UPLOAD_XML_FILE_SUCCESS = "UPLOAD_XML_FILE_SUCCESS";
export const UPLOAD_XML_FILE_FAILURE = "UPLOAD_XML_FILE_FAILURE";

// GET TIMEZONES

export const GET_TIMEZONES_SUCCESS = "GET_TIMEZONES_SUCCESS";
export const GET_TIMEZONES_FAILURE = "GET_TIMEZONES_FAILURE";

export const GET_LOGS_LIST_SUCCESS = "GET_LOGS_LIST_SUCCESS";
export const GET_LOGS_LIST_FAILURE = "GET_LOGS_LIST_FAILURE";

//////////////////////////////// Add Message ////////////////////////

export const POST_ADD_MESSAGE_SUCCESS = "POST_ADD_MESSAGE_SUCCESS";
export const POST_ADD_MESSAGE_FAILURE = "POST_ADD_MESSAGE_FAILURE";

export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_FAILURE = "GET_MESSAGE_FAILURE";

/////////////////////////////////// Closing Room /////////////////////////

export const ADD_SIGN_SALE_SUCCESS = "ADD_SIGN_SALE_SUCCESS";
export const ADD_SIGN_SALE_FAILURE = "ADD_SIGN_SALE_FAILURE";

export const GET_SIGN_SUCCESS = "GET_SIGN_SUCCESS";
export const GET_SIGN_FAILURE = "GET_SIGN_FAILURE";

export const GET_SIGN_SALE_SUCCESS = "GET_SIGN_SALE_SUCCESS";
export const GET_SIGN_SALE_FAILURE = "GET_SIGN_SALE_FAILURE";

export const GET_ASSET_ID_SALE_SUCCESS = "GET_ASSET_ID_SALE_SUCCESS";
export const GET_ASSET_ID_SALE_FAILURE = "GET_ASSET_ID_SALE_FAILURE";

/////////////////////////////////// notifications /////////////////////////

export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";

export const PUT_NOTIFICATIONS_SUCCESS = "PUT_NOTIFICATIONS_SUCCESS";
export const PUT_NOTIFICATIONS_FAILURE = "PUT_NOTIFICATIONS_FAILURE";
