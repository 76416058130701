import { apiNew } from "../../service/newApi";
import * as types from "../Constants";

export const getNotifications = () => {
  const options = {
    url: `notifications`,
  };

  options.types = [
    types.GET_NOTIFICATIONS_SUCCESS,
    types.GET_NOTIFICATIONS_FAILURE,
  ];

  return apiNew.get(options);
};

export const putNotifications = (id) => {
  const options = {
    url: `notifications/${id}`,
  };

  options.types = [
    types.PUT_NOTIFICATIONS_SUCCESS,
    types.PUT_NOTIFICATIONS_FAILURE,
  ];

  return apiNew.put(options);
};
