import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TradingTask from "../Components/ContentArea/RequestProposal";

import {
  updateTradingList,
  createTradingList,
  getAllTradingTask,
  getFormalLicenseList,
  getUsersList,
  getDepartmentsWithUsers,
  getUserAllList,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    updateTradingListRes: state.userReducer.updateTradingListRes,
    createTradingListRes: state.userReducer.createTradingListRes,
    getAllTradingTaskRes: state.userReducer.getAllTradingTaskRes,
    postTradingTaskRes: state.requestProposalReducer.postTradingTaskRes,
    getFormalLicenseListRes: state.selectionReducer.getFormalLicenseListRes,
    selectionAssetsListRes: state.selectionReducer.selectionAssetsListRes,
    selectionDetail: state.selectionReducer.selectionDetail,
    userManagementList: state.userManagementReducer.userManagementList,
    getUserListRes: state.tradingTaskReducer.getUserListRes,
    mainUserRes: state.userReducer.mainUserRes,
    departmentsWithUsersRes: state.tradingTaskReducer.getDepartmentsWithUsersListRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateTradingList: bindActionCreators(updateTradingList, dispatch),
    onCreateTradingList: bindActionCreators(createTradingList, dispatch),
    onGetAllTradingTask: bindActionCreators(getAllTradingTask, dispatch),
    onGetFormalLicenseList: bindActionCreators(getFormalLicenseList, dispatch),
    onGetUserManagementList: bindActionCreators(getUsersList, dispatch),
    onGetUserList: bindActionCreators(getUserAllList, dispatch),
    onGetDepartmentsWithUsers: bindActionCreators(getDepartmentsWithUsers, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TradingTask);
