import React, { useEffect } from "react";
import { Row, Col, Button } from "antd";
import { exportIcon } from "./../../../Common/Icons";
import { Link } from "react-router-dom";
import { getOnlyYear, getOnlyMinutes } from "./../../../Common/reUseFunctions";

const NumberOfAssetModal = ({
  cancelTableEditor,
  assetsList,
  selectionAssetsListRes,
  onGetSelectionAssets,
  selectedData,
}) => {
  useEffect(() => {
    onGetSelectionAssets(selectedData._id);
  }, []);

  return (
    <Row gutter={16} className="mb5">
      <div className="no-asset-header">
        <h2>Assets</h2>
        <h3>
          {exportIcon} {selectedData.name}
        </h3>
      </div>
      <div className="no-asset-box-scroll column-tasks-area">
        {selectionAssetsListRes &&
          selectionAssetsListRes.assets &&
          selectionAssetsListRes.assets.length > 0 &&
          selectionAssetsListRes.assets.map((item, index) => {
            return (
              <Col key={index} span={24}>
                <div className="no-asset-box">
                  <h2>{item && item.asset.title.value}</h2>
                  <p className="duration">
                    {item && item.asset && getOnlyMinutes(item.asset.duration)}
                    <span>
                      {item &&
                        item.asset &&
                        getOnlyYear(item.asset.production_date)}
                    </span>
                  </p>
                  <p className="m-cata">
                    <span>Production Company:</span>{" "}
                    {item &&
                      item.asset &&
                      item.asset.production_company &&
                      item.asset.production_company.value}
                  </p>
                  <p className="m-cata mb0">
                    <span>Film Director:</span>{" "}
                    {item &&
                      item.asset &&
                      item.asset.directors &&
                      item.asset.directors.join(", ")}
                  </p>
                  <div className="asset-detail">
                    <Link to={`/inventory-search/${item.asset._id}`}>
                      <Button type="link">
                        <u>Details</u>
                      </Button>
                    </Link>
                  </div>
                </div>
              </Col>
            );
          })}
      </div>
      <div className="no-asset-btns">
        <Button onClick={cancelTableEditor} type="default">
          CLOSE
        </Button>
        <Button onClick={cancelTableEditor} type="primary">
          SELECTION DETAIL
        </Button>
      </div>
    </Row>
  );
};

export default NumberOfAssetModal;
