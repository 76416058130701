import React, { useState, useEffect, Fragment } from "react";
import { Modal, message } from "antd";
import Filter from "./HeaderFilter";
import SelectionList from "./List";
import SelectionForm from "./SelectionForm";
import TableEditor from "../../Common/TableEditor";
import DeleteConfirmation from "../../Common/Components/DeleteConfirmation";
import AssetListModal from "./NumberOfAssetModal/index";
import {
  addKeyInArray,
  getRolesPermissions,
} from "../../Common/reUseFunctions";

const Selection = ({
  onGetSelectionsList,
  selectionsList,
  onCreateSelection,
  onUpdateSelection,
  updateSelection,
  onDeleteSelection,
  selectionType,
  onClickSelection,
  sessionRes,
  onGetSelectionDetail,
  selectionAssetsListRes,
  onGetSelectionAssets,
  mainUserRes,
}) => {
  const [search, setSearch] = useState("");
  const [modalType, setModalType] = useState("");
  const [visible, setVisible] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [selectionData, setSelectionData] = useState([]);
  const [selectSales, setSelectSales] = useState(true);
  const [visibleTE, setVisibleTE] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [selectPurchase, setSelectPurchase] = useState(true);
  const [delConfirm, setDelConfirm] = useState(false);
  const [selectionTypeChange, setSelectionTypeChange] = useState(false);

  useEffect(() => {
    if (getRolesPermissions(mainUserRes, "selection.list") === false) {
      onGetSelectionsList(search, selectSales, selectPurchase);
    }
  }, [search, selectSales, selectPurchase, mainUserRes]);

  useEffect(() => {
    if (selectionsList && selectionsList.length > 0) {
      let selectionListData = addKeyInArray(selectionsList);
      setSelectionData(selectionListData);
    }
  }, [selectionsList]);

  const handleCancel = () => {
    setVisible(false);
  };

  const closeDelConfirmModal = () => {
    setDelConfirm(false);
  };

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const showTableEditor = (value) => {
    setVisibleTE(true);
  };

  const cancelTableEditor = (e) => {
    setVisibleTE(false);
  };

  const onChangeSelectionType = (e) => {
    setSelectionTypeChange(e);
  };

  const delConfirmModal = (data) => {
    setSelectedData(data);
    setDelConfirm(true);
  };

  const showModal = (modalType, selectedData) => {
    setVisible(true);
    setModalType(modalType);
    setSelectedData(selectedData);
  };

  const onConfirmDelete = () => {
    setDelLoading(true);
    onDeleteSelection(selectedData && selectedData._id).then((res) => {
      closeDelConfirmModal();
      setDelLoading(false);
      onGetSelectionsList(search, selectSales, selectPurchase);
      message.success(res && res.payload && res.payload.message);
    });
  };

  return (
    <Fragment>
      <Filter
        onSearch={onSearch}
        search={search}
        showModal={showModal}
        onChangeSelectionType={onChangeSelectionType}
        sessionRes={sessionRes}
        showTableEditor={showTableEditor}
        selectSales={selectSales}
        setSelectSales={setSelectSales}
        selectPurchase={selectPurchase}
        setSelectPurchase={setSelectPurchase}
        setSelectionTypeChange={setSelectionTypeChange}
        onGetSelectionDetail={onGetSelectionDetail}
        mainUserRes={mainUserRes}
      />
      <SelectionList
        data={selectionData}
        showModal={showModal}
        onDeleteSelection={onDeleteSelection}
        selectionType={selectionType}
        onClickSelection={onClickSelection}
        selectionTypeChange={selectionTypeChange}
        delConfirmModal={delConfirmModal}
        selectionsList={selectionsList}
        onGetSelectionsList={onGetSelectionsList}
        mainUserRes={mainUserRes}
      />
      {visible && (
        <Modal
          title={false}
          visible={visible}
          onCancel={handleCancel}
          footer={false}
          className="modal-style"
        >
          {visible &&
            (modalType === "edit" || modalType === "add-selection") && (
              <SelectionForm
                handleCancel={handleCancel}
                modalType={modalType}
                onCreateSelection={onCreateSelection}
                onUpdateSelection={onUpdateSelection}
                updateSelection={updateSelection}
                onGetSelectionsList={onGetSelectionsList}
                selectedData={selectedData}
                onGetSelectionDetail={onGetSelectionDetail}
                search={search}
                selectSales={selectSales}
                selectPurchase={selectPurchase}
              />
            )}
          {modalType === "asset" && (
            <AssetListModal
              cancelTableEditor={handleCancel}
              onGetSelectionAssets={onGetSelectionAssets}
              selectionAssetsListRes={selectionAssetsListRes}
              selectedData={selectedData}
            />
          )}
        </Modal>
      )}
      <Modal
        visible={visibleTE}
        onCancel={cancelTableEditor}
        footer={false}
        className="modal-style"
        width={740}
      >
        {<TableEditor />}
      </Modal>
      <DeleteConfirmation
        visible={delConfirm}
        handleCancel={closeDelConfirmModal}
        onDelete={onConfirmDelete}
        loading={delLoading}
        text="You will deleted this selection list for good. This action cannot be undone."
      />
    </Fragment>
  );
};

export default Selection;
