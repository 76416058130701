import React, { useState } from "react";
import { Form, Input, Select, Col } from "antd";

const { Option } = Select;

const CommnetsForm = ({ helperText, requiredField, field }) => {
  const [externalValue, setExternalValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const onChangeInputValue = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const onChangeExternal = (data) => {
    setExternalValue(data);
  };

  return (
    field && (
      <Col span={24}>
        <div className="metadata-field-row">
          <div className="metadata-field-name">
            <h3 className="">
              <span>Comments</span>
            </h3>
          </div>
          <div className="metadata-filed d-flex align-item-center">
            <Form.Item
              {...field}
              name={[field.name, "external"]}
              fieldKey={[field.fieldKey, "external"]}
              className="width-200 mr10 mb0"
              rules={[
                {
                  required: externalValue || inputValue ? true : false,
                  message: "Please enter the external",
                },
              ]}
            >
              <Select
                className="full-width"
                placeholder="Choose one"
                onChange={onChangeExternal}
                getPopupContainer={(trigger) => trigger.parentElement}
              >
                <Option value={true}>Yes</Option>
                <Option value={false}>No</Option>
              </Select>
            </Form.Item>

            <Form.Item
              {...field}
              name={[field.name, "value"]}
              fieldKey={[field.fieldKey, "value"]}
              className="full-width mb0"
              rules={[
                {
                  required: externalValue || inputValue ? true : false,
                  message: "Please enter the value!",
                },
              ]}
            >
              <Input
                placeholder="Type..."
                onChange={onChangeInputValue}
                className="full-width"
              />
            </Form.Item>
            {/* <Link
            className="remove-btn"
            onClick={() => {
              remove(field.name);
            }}
          >
            <span>Remove:</span>
            <MinusCircleOutlined
              className="ml10"
            />
          </Link> */}
          </div>
        </div>
      </Col>
    )
  );
};

export default CommnetsForm;
