import React, { Fragment, useState } from "react";
import { Button, Radio, message } from "antd";
import AddSelectionList from "./SelectionList";
import AddToSelectionBtn from "./AddNewSelectionBtn";
import { getRolesPermissions } from "./../../../../Common/reUseFunctions";

const SelectionList = ({
  assetID,
  mainUserRes,
  selectedData,
  onCreateSelection,
  onGetSelectionsList,
  selectionsList,
  onUpdateSelection,
  onGetSelectionDetail,
  selectionDetail,
  handleCloseSelection,
  onAddAssetInSelection,
}) => {
  const [selectedSelectionId, setSelectedSelectionId] = useState("");

  const onChangeSelection = (id) => {
    setSelectedSelectionId(id);
  };

  const onAddSelection = () => {
    if (getRolesPermissions(mainUserRes, "selection-item.create") === false) {
      const assetIdVar = assetID ? assetID : selectedData && selectedData._id;
      if (selectedSelectionId && assetIdVar) {
        const selectionId = {
          id: assetIdVar,
        };
        onAddAssetInSelection(selectedSelectionId, selectionId)
          .then((res) => {
            message.success("Successfully Added");
            handleCloseSelection();
          })
          .catch((res) => {
            message.error(
              res &&
                res.payload &&
                res.payload.response &&
                res.payload.response.data &&
                res.payload.response.data.errors
            );
          });
      } else {
        message.info("Please select the selection first");
      }
    }
  };

  return (
    <Fragment>
      <ul className="selection-radio column-tasks-area">
        <Radio.Group>
          {selectionsList &&
            selectionsList.selections &&
            selectionsList.selections.length > 0 &&
            selectionsList.selections.map((list, index) => {
              return (
                <li key={index}>
                  <Radio
                    value={list.name}
                    onChange={() => onChangeSelection(list._id)}
                  >
                    <AddSelectionList
                      list={list}
                      index={index}
                      onGetSelectionsList={onGetSelectionsList}
                      onGetSelectionDetail={onGetSelectionDetail}
                      selectionDetail={selectionDetail}
                      onUpdateSelection={onUpdateSelection}
                    />
                  </Radio>
                </li>
              );
            })}
        </Radio.Group>
      </ul>
      <div className="add-selections-style">
        <AddToSelectionBtn
          onCreateSelection={onCreateSelection}
          onGetSelectionsList={onGetSelectionsList}
        />
        <Button type="primary" onClick={onAddSelection}>
          ADD TO SELECTION
        </Button>
      </div>
    </Fragment>
  );
};

export default SelectionList;
