import React, { Fragment } from "react";
import { FileOutlined } from "@ant-design/icons";

const FileHistory = ({ getLogsListRes }) => {
  return (
    <div className="file-history">
      <h2>Negotiation Logs</h2>
      {getLogsListRes &&
        getLogsListRes.data &&
        getLogsListRes.data.length > 0 &&
        getLogsListRes.data.map((data, index) => (
          <Fragment key={index}>
            <ul>
              <li key={index}>
                <div className="file-history-name">
                  <FileOutlined />
                  <span>{data && data.note}</span>
                </div>
                {/* <h3>{toDateString(data.created_at)}</h3> */}
                {/* <div className="file-history-download">
                  <Button type="link"><DownloadOutlined /></Button>
                </div> */}
              </li>
            </ul>
          </Fragment>
        ))}
    </div>
  );
};

export default FileHistory;
