import React from "react";
import { Row, Col, Skeleton } from "antd";

const PremiumMemberProfile = ({ data, logoImage, heroImage }) => {
  return (
    data && (
      <Row gutter={24}>
        <Col className="mb40" span={12}>
          <div className="member-profile-text">
            {logoImage ? (
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}organizations/${logoImage}/image`}
                alt={data.name}
              />
            ) : (
              <Skeleton.Image />
            )}
            <h2>{data.name}</h2>
            <p>{data.description}</p>
            <div className="d-flex">
              <div className="total-assets">
                <h3>
                  {data.highlights &&
                    parseInt(data.highlights.assets.length) +
                      parseInt(data.highlights.special_catalogues.length)}
                </h3>
                <p>Total assets</p>
              </div>
            </div>
          </div>
        </Col>
        {/* Missing Information */}
        <Col className="mb40" span={12}>
          <div className="member-profile-img">
            {heroImage ? (
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}organizations/${heroImage}/image`}
                alt={data.name}
              />
            ) : (
              <Skeleton.Image />
            )}
          </div>
        </Col>
      </Row>
    )
  );
};

export default PremiumMemberProfile;
