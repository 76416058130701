import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Avatar, Button } from "antd";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  getUrlSecondLastId,
  getLocalCountrytime,
  getCountrytime,
} from "./../../Common/reUseFunctions";

import LicenseAssetCollapse from "./LicenseAssetCollapse";
import LicenceAgreementSec from "./LicenceAgreementSec";

const RoomPersons = ({
  showModal,
  sellingAsset,
  getAllTradingTaskRes,
  delConfirmModal,
  loading,
  logoImage,
  cancelNegotiationRes,
  onCancelNegotiation,
  history,
  getOrganizationProfileRes,
  getFormalLicenseRes,
  onGetFormalLicense,
  location,
}) => {
  const [tradingTaskId, setTradingTaskId] = useState();

  useEffect(() => {
    const locationUrl = location.pathname;
    const id = getUrlSecondLastId(locationUrl);
    setTradingTaskId(id);
    if (cancelNegotiationRes && cancelNegotiationRes.status === true) {
      history.push("/trading-tasks");
    }
  }, [cancelNegotiationRes]);

  const onCancelNego = () => {
    const locationUrl = location.pathname;
    const getUrlPath = getUrlSecondLastId(locationUrl);
    onCancelNegotiation(getUrlPath);
  };

  console.log("getOrganizationProfileRes--->", getOrganizationProfileRes);

  return (
    <Fragment>
      <div className="license-contract">
        <div className="contract-header">
          <Row gutter={16}>
            <Col span={10}>
              <div className="contract-asset-profile">
                <Avatar src={logoImage} size={60} />
                <div>
                  <h3>
                    {getAllTradingTaskRes &&
                      getAllTradingTaskRes.data &&
                      getAllTradingTaskRes.data.company_title}
                  </h3>
                  <p>
                    {getAllTradingTaskRes &&
                      getAllTradingTaskRes.data &&
                      getAllTradingTaskRes.data.assigned_to_object &&
                      getAllTradingTaskRes.data.assigned_to_object.description}
                  </p>
                </div>
              </div>
            </Col>
            <Col className="contract-time" span={6}>
              <p>Your Local Time {getLocalCountrytime()}</p>
            </Col>
            <Col className="contract-time" span={6}>
              <p>
                {getOrganizationProfileRes && getOrganizationProfileRes.country}
                , Local Time {getCountrytime(getOrganizationProfileRes)}
              </p>
            </Col>
          </Row>
        </div>
        {/* <div className="contract-upload">
          <h3 className="mb20">LICENSE CONTRACT</h3>
          <div style={{ maxWidth: "400px" }}>
            <Upload {...fileUploadProps}>
              <Button>
                <UploadOutlined /> Upload the file...
                </Button>
            </Upload>
          </div>
          <Button className="upload-history" type="link" onClick={() => showModal('file_history')}><HistoryOutlined /> File history</Button>
        </div> */}
        <div>
          {/* <RoomPersonsCom showModal={showModal} /> */}
          <LicenceAgreementSec
            getAllTradingTaskRes={getAllTradingTaskRes}
            showModal={showModal}
          />
          <LicenseAssetCollapse
            getAllTradingTaskRes={getAllTradingTaskRes}
            showModal={showModal}
            sellingAsset={sellingAsset}
            getFormalLicenseRes={getFormalLicenseRes}
            onGetFormalLicense={onGetFormalLicense}
            location={location}
          />
        </div>
        <div className="signature-btn-sec">
          <h5>SIGNATORIES</h5>
          <div className="signature-agreement-btns">
            {/* <div>
              <div className="signature-select">
                <Select defaultValue="Steve Baker" style={{ width: 180 }} disabled>
                  <Option value="Steve Baker">Steve Baker</Option>
                </Select>
                <Button type="default">INVITE SENT</Button>
              </div>
              <div className="signature-select">
                <Select defaultValue="Select Person" style={{ width: 180 }} onChange={handleChange}>
                  <Option value="Select Person">Select Person</Option>
                  <Option value="Last Invite Users">Last Invite Person</Option>
                  <Option value="New Invite Users">New Invite Person</Option>
                </Select>
                <Button type="primary">SEND INVITE</Button>
              </div>
            </div> */}
            <div>
              <Link to="#">
                <Button
                  loading={loading}
                  type="primary"
                  onClick={() => delConfirmModal("Sign Now")}
                >
                  SIGN NOW
                </Button>
              </Link>
            </div>
          </div>
          <div className="close-agreement-btns">
            <div>
              <Link to={`/trading-task/${tradingTaskId}/proposal`}>
                <Button type="link">
                  <ArrowLeftOutlined /> Back to Negotiation
                </Button>
              </Link>
              <Button onClick={() => onCancelNego()} type="link">
                <CloseOutlined /> CANCEL NEGOTIATION
              </Button>
            </div>
          </div>
        </div>

        {/* <div className="close-agreement-btns">
           <Button type="primary" onClick={()=> setVisibleSignature(!visibleSignature)}>CLOSE AGREEMENT</Button>
           <div>
           <Link to={`/selections/5f4f71666e3c8ee2126379e0/proposal`}><Button type="link"><ArrowLeftOutlined /> Back to Negotiation</Button></Link>
           <Link to={`!#`}><Button type="link"><CloseOutlined /> CANCEL NEGOTIATIONS</Button></Link>
           </div>
         </div> */}
      </div>
    </Fragment>
  );
};

export default RoomPersons;
