import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AssetMetadataComp from "../Components/ContentArea/DataMaintenance/Detail";

import {
  isccCodeUpdating,
  getAssetDetail,
  updateAsset,
  deleteAsset,
  isccCodeGeneration,
  isccGenerationProcess,
  loadSignLicense,
  signatureLicenseProcess,
  requestLicenseSignature,
  publishLicenseBlockchain,
  publishAssetMetadata,
  unpublishAssetMetadata,
  getWalletList,
  getWalletDetail,
  getSpecificUserDetail,
  mianUserDetail,
  getSpecificUserImage,
  logout,
  getNotifications,
  getInventorySeachDetail,
  getInventoryList,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    assetDetail: state.assetReducer.assetDetail,
    updateAsset: state.assetReducer.updateAsset,
    deleteAsset: state.assetReducer.deleteAsset,

    isccGeneration: state.assetMetadataReducer.isccGeneration,
    isccStatus: state.assetMetadataReducer.isccStatus,
    signLicense: state.assetMetadataReducer.signLicense,
    reqSignature: state.assetMetadataReducer.reqSignature,
    signatureStatus: state.assetMetadataReducer.signatureStatus,
    licensePublish: state.assetMetadataReducer.licensePublish,
    publishAsset: state.assetMetadataReducer.publishAsset,
    UnpublishAsset: state.assetMetadataReducer.UnpublishAsset,
    isccUpdating: state.assetMetadataReducer.isccUpdating,

    walletList: state.walletReducer.walletList,
    walletDetail: state.walletReducer.walletDetail,

    getSpecificUserDetail: state.userManagementReducer.getSpecificUserDetail,

    mainUserRes: state.userReducer.mainUserRes,

    specificUserImage: state.userManagementReducer.specificUserImage,
    getNotificationsRes: state.notificationsReducer.getNotificationsRes,
    getInventorySeachDetailRes:
      state.inventorySearchReducer.getInventorySeachDetailRes,

    inventoryListRes: state.inventorySearchReducer.inventoryListRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onIsccCodeUpdating: bindActionCreators(isccCodeUpdating, dispatch),
    onGetAssetDetail: bindActionCreators(getAssetDetail, dispatch),
    onUpdateAsset: bindActionCreators(updateAsset, dispatch),
    onDeleteAsset: bindActionCreators(deleteAsset, dispatch),

    onIsccCodeGeneration: bindActionCreators(isccCodeGeneration, dispatch),
    onIsccGenerationProcess: bindActionCreators(
      isccGenerationProcess,
      dispatch
    ),
    onLoadSignLicense: bindActionCreators(loadSignLicense, dispatch),
    onSignatureLicenseProcess: bindActionCreators(
      signatureLicenseProcess,
      dispatch
    ),
    onRequestLicenseSignature: bindActionCreators(
      requestLicenseSignature,
      dispatch
    ),
    onPublishLicenseBlockchain: bindActionCreators(
      publishLicenseBlockchain,
      dispatch
    ),
    onPublishAssetMetadata: bindActionCreators(publishAssetMetadata, dispatch),
    onUnpublishAssetMetadata: bindActionCreators(
      unpublishAssetMetadata,
      dispatch
    ),

    onGetWalletList: bindActionCreators(getWalletList, dispatch),
    onGetWalletDetail: bindActionCreators(getWalletDetail, dispatch),

    onGetSpecificUserDetail: bindActionCreators(
      getSpecificUserDetail,
      dispatch
    ),
    onGetMainUserDetail: bindActionCreators(mianUserDetail, dispatch),
    onGetSpecificUserImage: bindActionCreators(getSpecificUserImage, dispatch),
    onLogout: bindActionCreators(logout, dispatch),
    onGetNotifications: bindActionCreators(getNotifications, dispatch),
    onGetInventorySeachDetail: bindActionCreators(
      getInventorySeachDetail,
      dispatch
    ),
    onGetInventoryList: bindActionCreators(getInventoryList, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetMetadataComp);
