import React from 'react';
import {  Button } from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const TopTitleSection = () => {
  return (
    <div className="my-market-inventory" style={{ position: "fixed", width: "calc(100% - 300px)" }}>
      <div><h3><ImportOutlined /> Sundance Film Festival 2021</h3></div>
      <div className="header-btn-groups import-asset-menu">
        <Button className="mr20" type="link"><Link to="/selections/qwerty12345">Select Rights</Link></Button>
        <Button className="mr20 active" type="link">Request Proposal</Button>
        <Button className="mr20" type="link">Negotiation Room</Button>
        <Button className="mr20" type="link">Closing Room</Button>
      </div>
    </div>
  )
}

export default TopTitleSection;