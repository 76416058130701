import React, { useEffect, useState } from "react";
import { Button, Form, Select, DatePicker, message } from "antd";
import moment from "moment";
import SavedPresetFormFilter from "./SavedPresetFormFilter";
import { countryList } from "./../../../../statics/data/countryList";
import { countryListTerritories } from "./../../../../statics/data/countryListTerritories";
import {
  getUrlLastId,
  getRolesPermissions,
} from "./../../../Common/reUseFunctions";

const { Option } = Select;
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
const filteredOptions = [
  "All rights",
  "Audio only",
  "Download on Demand",
  "Download to Own",
  "DVD",
  "Educational",
  "Film Festivals",
  "Free TV",
  "Hotels",
  "in-flight",
  "non-commercial",
  "Pay TV",
  "Public Viewing",
  "Ships",
  "Stream on Demand",
  "Theatrical",
];

const FormalLicenseEdit = ({
  modelType,
  handleCancel,
  showModal,
  onAddFormalLicenseDefinition,
  selectedAssetId,
  selectionsID,
  onGetFormalLicenseList,
  onUpdateFormalLicense,
  updateFormalLicenseRes,
  licenseId,
  updateFormalLicense,
  location,
  onGetSelectionAssets,
  mainUserRes,
}) => {
  const [form] = Form.useForm();
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectTerritory, setSelectTerritory] = useState([]);
  const [selectChannels, setSelectChannels] = useState([]);

  useEffect(() => {
    if (
      updateFormalLicense &&
      updateFormalLicense.territories &&
      updateFormalLicense.territories.length === 0
    ) {
      setSelectTerritory("worldwide");
    }
    form.setFieldsValue({
      territories:
        updateFormalLicense &&
        updateFormalLicense.territories &&
        updateFormalLicense.territories.length === 0
          ? ["worldwide"]
          : updateFormalLicense && updateFormalLicense.territories,
      excluding: updateFormalLicense && updateFormalLicense.excluding,
      re_sales:
        updateFormalLicense &&
        ((updateFormalLicense.resale_allowed === true && "Yes") ||
          (updateFormalLicense.resale_allowed === false && "No")),
      channels: updateFormalLicense && updateFormalLicense.channels,
      exclusive_license:
        updateFormalLicense &&
        ((updateFormalLicense.exclusive_license === true && "Yes") ||
          (updateFormalLicense.exclusive_license === false && "No")),
      start_date: updateFormalLicense && moment(updateFormalLicense.start_date),
      end_date: updateFormalLicense && moment(updateFormalLicense.end_date),
    });
  }, [updateFormalLicense]);

  const handleChange = (selectedItems) => {
    setSelectedItems(selectedItems);
  };

  const onSelectTerritories = (value) => {
    if (value === "worldwide") {
      form.setFieldsValue({
        territories: [value],
      });
    }
    setSelectTerritory(value);
  };

  const onChangeExcluding = (value) => {
    console.log(`selected ${value}`);
  };

  const onSelectChannels = (value) => {
    if (value === "All rights") {
      form.setFieldsValue({
        channels: [value],
      });
    }
    setSelectChannels(value);
  };

  const onChangeResale = (value) => {
    if (value === "Yes") {
      form.setFieldsValue({
        exclusive_license: value,
      });
    }
  };

  const onChangeExclusive = (value) => {
    if (value === "No") {
      form.setFieldsValue({
        re_sales: value,
      });
    }
  };
  console.log("selectedAssetId--->", selectedAssetId);

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    const locationUrl = location.pathname;
    const getUrlPath = getUrlLastId(locationUrl);

    if (modelType === "add") {
      const itemID = selectedAssetId;
      const licenseBody = {
        channels: values && values.channels,
        excluding:
          values.territories &&
          values.territories.length > 0 &&
          values.territories[0] !== "worldwide"
            ? []
            : Array.isArray(values.excluding)
            ? values.excluding
            : [values.excluding],
        territories:
          values.territories &&
          values.territories.length > 0 &&
          values.territories[0] === "worldwide"
            ? []
            : Array.isArray(values.territories)
            ? values.territories
            : [values.territories],
        type: "only",
        resale_allowed:
          values.re_sales === "Yes"
            ? true
            : null || values.re_sales === "No"
            ? false
            : null,
        exclusive_license:
          values.exclusive_license === "Yes"
            ? true
            : null || values.exclusive_license === "No"
            ? false
            : null,
        start_date:
          values && values.start_date ? moment(values.start_date) : null,
        end_date: values && values.start_date ? moment(values.end_date) : null,
      };
      if (
        getRolesPermissions(mainUserRes, "selection-license.create") === false
      ) {
        onAddFormalLicenseDefinition(selectionsID, itemID, licenseBody).then(
          (res) => {
            message.success(res && res.payload && res.payload.message);
            handleCancel();
            onGetFormalLicenseList(selectionsID, itemID);
            onGetSelectionAssets(getUrlPath);
          }
        );
      }
    }

    if (modelType === "edit") {
      const itemId = selectedAssetId;
      const licenseUpdateBody = {
        channels: values && values.channels,
        excluding:
          values.territories &&
          values.territories.length > 0 &&
          values.territories[0] !== "worldwide"
            ? []
            : Array.isArray(values.excluding)
            ? values.excluding
            : [values.excluding],
        territories:
          values.territories &&
          values.territories.length > 0 &&
          values.territories[0] === "worldwide"
            ? []
            : Array.isArray(values.territories)
            ? values.territories
            : [values.territories],
        type: "only",
        resale_allowed:
          values.re_sales === "Yes"
            ? true
            : null || values.re_sales === "No"
            ? false
            : null,
        exclusive_license:
          values.exclusive_license === "Yes"
            ? true
            : null || values.exclusive_license === "No"
            ? false
            : null,
        start_date:
          values && values.start_date ? moment(values.start_date) : null,
        end_date: values && values.start_date ? moment(values.end_date) : null,
      };
      onUpdateFormalLicense(
        selectionsID,
        itemId,
        licenseId._id,
        licenseUpdateBody
      ).then((res) => {
        message.success(res && res.payload && res.payload.message);
        handleCancel();
        onGetFormalLicenseList(selectionsID, itemId);
        onGetSelectionAssets(getUrlPath);
      });
    }
  };

  return (
    <div className="license-edit-model">
      <h3>FORMAL LICENSE DEFINITION</h3>
      <SavedPresetFormFilter
        handleCancel={handleCancel}
        showModal={showModal}
      />
      <Form form={form} name="validate_other" onFinish={onFinish}>
        <div className="slection-asset-view">
          <div className="formal-license-view">
            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>TERRITORIES</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  name="territories"
                  rules={[{ required: true, message: "This is required" }]}
                >
                  {selectTerritory === "worldwide" ? (
                    <Select
                      mode="single"
                      style={{ width: "100%" }}
                      placeholder="Select Territories"
                      onSelect={onSelectTerritories}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {countryListTerritories.map((item, index) => (
                        <Option key={index} value={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      mode="multiple"
                      style={{ width: "100%" }}
                      placeholder="Select Territories"
                      onSelect={onSelectTerritories}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {countryListTerritories.map((item, index) => (
                        <Option key={index} value={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </div>
            </div>

            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>EXCLUDING</span>
              </div>
              <div className="formal-license-data">
                <Form.Item name="excluding" rules={[{ required: false }]}>
                  {selectTerritory === "worldwide" && (
                    <Select
                      placeholder="Select Excluding"
                      mode="multiple"
                      style={{ width: "100%" }}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      getPopupContainer={(trigger) => trigger.parentElement}
                    >
                      {countryList.map((item, index) => (
                        <Option key={index} value={item.code}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </div>
            </div>

            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>CHANNELS</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  name="channels"
                  rules={[{ required: true, message: "This is required" }]}
                >
                  {selectChannels === "All rights" ? (
                    <Select
                      mode="single"
                      placeholder="Select Channels"
                      value={selectedItems}
                      onChange={handleChange}
                      onSelect={onSelectChannels}
                      style={{ width: "100%" }}
                    >
                      {filteredOptions.map((item) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      mode="multiple"
                      placeholder="Select Channels"
                      value={selectedItems}
                      onChange={handleChange}
                      onSelect={onSelectChannels}
                      style={{ width: "100%" }}
                    >
                      {filteredOptions.map((item) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Form.Item>
              </div>
            </div>

            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>RESALE ALLOWED</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  name="re_sales"
                  rules={[{ required: true, message: "This is required" }]}
                >
                  <Select
                    placeholder="Select Resale Allowed"
                    onChange={onChangeResale}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>EXCLUSIVE LICENSE</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  name="exclusive_license"
                  rules={[{ required: true, message: "This is required" }]}
                >
                  <Select
                    placeholder="Select Exclusive License"
                    onChange={onChangeExclusive}
                  >
                    <Option value="Yes">Yes</Option>
                    <Option value="No">No</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>

            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>START DATE</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  name="start_date"
                  rules={[
                    {
                      required: true,
                      message: "This is required",
                      message: "Please choose the start date",
                    },
                  ]}
                >
                  <DatePicker format={dateFormatList} />
                </Form.Item>
              </div>
            </div>

            <div className="formal-license-row">
              <div className="formal-license-name">
                <span>END DATE</span>
              </div>
              <div className="formal-license-data">
                <Form.Item
                  name="end_date"
                  rules={[{ required: true, message: "This is required" }]}
                >
                  <DatePicker format={dateFormatList} />
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
        <div className="formal-license-btn">
          <Button htmlType="submit" type="primary">
            {(modelType === "add" && "ADD LICENSE DEFINITION") ||
              (modelType === "edit" && "SAVE CHANGES")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default FormalLicenseEdit;
