import React, { useState, useEffect } from "react";
import { Modal, Pagination, message, Select } from "antd";
import HeaderFiltter from "./HeaderFilter";
import List from "./List";
import DeleteConfirmation from "../../Common/Components/DeleteConfirmation";
import SaveSearchModal from "../../Common/Components/SaveSearchModal";
import SavedSearchPresetsModal from "../../Common/SavedSearchPresetsModal";
import AddNewAssetForm from "./AddNewAssetForm";
import AssetModal from "./AssetModal/index";
import UpdateAssetModal from "./UpdateAssetModal";
import TableEditor from "../../Common/TableEditor";
import CascadingShort from "../../Common/CascadingSort";
import { getRolesPermissions } from "../../Common/reUseFunctions";

import { SelectionModelComp } from "../../../Containers";
const { Option } = Select;

const DataMaintenance = ({
  onGetAssetsList,
  assetsList,
  onGetAssetDetail,
  assetDetail,
  onCreateAsset,
  createAsset,
  onUpdateAsset,
  onDeleteAsset,
  location,
  history,
  sessionRes,
  mainUserRes,
}) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [modalType, setModalType] = useState("");
  const [visible, setVisible] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [visibleTE, setVisibleTE] = useState(false);
  const [delConfirm, setDelConfirm] = useState(false);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [marketReady, setMarketReady] = useState(true);
  const [notMarketReady, setNotMarketReady] = useState(true);
  const [publishedAssets, setPublishedAssets] = useState(true);
  const [userPermissions, setUserPermissions] = useState([]);
  const [saveSearch, setSaveSearch] = useState(false);
  const [saveSearchResult, setSaveSearchResult] = useState([]);
  const [itemsLimit, setItemsLimit] = useState(10);
  const [orderDisorder, setorderDisorder] = useState(true);

  useEffect(() => {
    setLoading(true);
    getAssestsList();
  }, [
    currentPage,
    itemsLimit,
    marketReady,
    notMarketReady,
    publishedAssets,
    search,
    mainUserRes,
    orderDisorder,
  ]);

  useEffect(() => {
    if (mainUserRes) {
      getUserPermissions(mainUserRes);
    }
  }, [mainUserRes]);

  const getUserPermissions = (mainUser) => {
    let allPermissions = [];

    if (mainUser && mainUser.roles && mainUser.roles.length > 0) {
      mainUser.roles.map((data, index) => {
        allPermissions = [...allPermissions, ...data.permissions];
      });
    }
    const uniqPermissoins = allPermissions.filter(function (item, pos) {
      return allPermissions.indexOf(item) == pos;
    });
    setUserPermissions(uniqPermissoins);
  };

  const checkingNonMarketReady = () => {
    if (notMarketReady === true) {
      return "notMarketReady";
    }
    if (notMarketReady === false) {
      return "";
    }
  };

  const checkingPublished = () => {
    if (publishedAssets === true) {
      return "published";
    }
    if (publishedAssets === false) {
      return "";
    }
  };

  const checkingMarketReady = () => {
    if (marketReady === true) {
      return "marketReady";
    }
    if (marketReady === false) {
      return "";
    }
  };

  const getAssestsList = () => {
    if (getRolesPermissions(mainUserRes, "asset.list") === false) {
      const skip = currentPage === 1 ? 0 : (currentPage - 1) * itemsLimit;
      let order = orderDisorder === true ? "asc" : "desc";

      onGetAssetsList(
        skip,
        itemsLimit,
        marketReady,
        notMarketReady,
        publishedAssets,
        search,
        order
      )
        .then(() => {
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const showModal = (modalType, selectedData) => {
    setVisible(true);
    setModalType(modalType);
    setSelectedData(selectedData);
    onGetAssetDetail(selectedData && selectedData._id);
  };

  const cancelTableEditor = (e) => {
    setVisibleTE(false);
  };

  const delConfirmModal = (data) => {
    setSelectedData(data);
    setDelConfirm(true);
  };

  const closeDelConfirmModal = () => {
    setDelConfirm(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
  };

  const showTableEditor = (value) => {
    setVisible(false);
    setVisibleTE(true);
    setModalType(value);
  };

  const onConfirmDelete = () => {
    setLoading(true);
    onDeleteAsset(selectedData && selectedData._id).then(() => {
      closeDelConfirmModal();
      setLoading(false);
      getAssestsList();
      message.success("Deleted Successfully");
    });
  };

  const onSaveSearch = (modalType, searchRes) => {
    setSaveSearch(true);
    setModalType(modalType);
    setSaveSearchResult(searchRes);
  };
  const onCloseSaveSearch = () => {
    setSaveSearch(false);
  };

  const onHidePopover = () => {
    setVisiblePopover(true);
  };

  const onFinish = (values) => {
    if (values) {
      setVisibleTE(false);
    }
  };

  const handItemsLimit = (value) => {
    setItemsLimit(value);
  };

  return (
    <div>
      <HeaderFiltter
        showTableEditor={showTableEditor}
        setSearch={setSearch}
        showModal={showModal}
        sessionRes={sessionRes}
        search={search}
        setMarketReady={setMarketReady}
        marketReady={marketReady}
        setNotMarketReady={setNotMarketReady}
        notMarketReady={notMarketReady}
        setPublishedAssets={setPublishedAssets}
        publishedAssets={publishedAssets}
        userPermissions={userPermissions}
        onHidePopover={onHidePopover}
        setVisiblePopover={setVisiblePopover}
        visiblePopover={visiblePopover}
        onSaveSearch={onSaveSearch}
        mainUserRes={mainUserRes}
      />
      <List
        loading={loading}
        data={assetsList}
        showModal={showModal}
        onDeleteAsset={onDeleteAsset}
        getAssestsList={getAssestsList}
        selectedData={selectedData}
        location={location}
        search={search}
        currentPage={currentPage}
        sessionRes={sessionRes}
        showTableEditor={showTableEditor}
        delConfirmModal={delConfirmModal}
        mainUserRes={mainUserRes}
        setorderDisorder={setorderDisorder}
        orderDisorder={orderDisorder}
      />
      <div className="mt20 center d-flex align-item-center justify-center">
        <Pagination
          current={currentPage}
          pageSize={itemsLimit}
          total={
            assetsList && assetsList.pagination && assetsList.pagination.total
          }
          onChange={onChangePagination}
          showSizeChanger={false}
        />
        <Select
          defaultValue="10"
          style={{ width: 120, marginLeft: 20 }}
          onChange={handItemsLimit}
        >
          <Option value="10">10 Items</Option>
          <Option value="30">30 Items</Option>
          <Option value="50">50 Items</Option>
          <Option value="70">70 Items</Option>
          <Option value="100">100 Items</Option>
          <Option value="300">300 Items</Option>
          <Option value="500">500 Items</Option>
          <Option value="1000">1000 Items</Option>
        </Select>
      </div>

      {visible && (
        <Modal
          title={modalType === "add-asset" && "Add New Asset"}
          visible={visible}
          onCancel={handleCancel}
          footer={false}
          className={modalType === "view" ? "detail-model" : "modal-style"}
          centered
        >
          {modalType === "add-asset" && (
            <AddNewAssetForm
              handleCancel={handleCancel}
              modalType={modalType}
              selectedData={selectedData}
              getAssestsList={getAssestsList}
              onCreateAsset={onCreateAsset}
              createAsset={createAsset}
              onGetAssetDetail={onGetAssetDetail}
              assetDetail={assetDetail}
              onUpdateAsset={onUpdateAsset}
              history={history}
              search={search}
              currentPage={currentPage}
              mainUserRes={mainUserRes}
            />
          )}
          {modalType === "view" && (
            <AssetModal
              selectedData={selectedData}
              showTableEditor={showTableEditor}
              sessionRes={sessionRes}
              assetDetail={assetDetail}
            />
          )}
        </Modal>
      )}

      {visibleTE && (
        <Modal
          visible={visibleTE}
          onCancel={cancelTableEditor}
          footer={false}
          className="modal-style"
          width={
            (modalType === "add_to_selection" && 480) ||
            (modalType === "update_asset" && 480) ||
            (modalType === "table_editor" && 740) ||
            (modalType === "cascading_sort" && 500) ||
            (modalType === "search_preset" && 400)
          }
          centered
        >
          {modalType === "table_editor" && <TableEditor />}
          {modalType === "add_to_selection" && <SelectionModelComp />}
          {modalType === "update_asset" && (
            <UpdateAssetModal cancelTableEditor={cancelTableEditor} />
          )}
          {modalType === "cascading_sort" && (
            <CascadingShort onFinish={onFinish} />
          )}

          {modalType === "search_preset" && (
            <SavedSearchPresetsModal handleCloseSelection={cancelTableEditor} />
          )}
        </Modal>
      )}
      <DeleteConfirmation
        visible={delConfirm}
        handleCancel={closeDelConfirmModal}
        onDelete={onConfirmDelete}
        text="This production will be deleted from your inventory, your catalogues and also from the marketplace. 
        This action cannot be undone."
      />
      <SaveSearchModal
        visible={saveSearch}
        searchResult={saveSearchResult}
        setSearchResult={setSaveSearchResult}
        handleCancel={onCloseSaveSearch}
      />
    </div>
  );
};

export default DataMaintenance;
