import React, { useState, useEffect } from "react";
import { Row, Col, Spin } from "antd";

import FilterByAlphabet from "./FilterByAlphabet";
import CataloguesBanner from "./CataloguesBanner";
import CataloguesByLogo from "./CataloguesByLogo";
import HeaderFilter from "./HeaderFilter";
import { GET_CATALOGUES_SUCCESS } from "../../../store/Constants";
import { getRolesPermissions } from "./../../Common/reUseFunctions";

const DashboardPage = ({ onGetCataloguesList, onGetOrganization, mainUserRes, organizationRes }) => {
  const [name, setName] = useState("");
  const [startsWith, setStartsWith] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [catalogLogos, setCatalogLogos] = useState([]);

  useEffect(() => {
    if (getRolesPermissions(mainUserRes, "organization.list") === false) {
      setLoading(true);
      getCatalogueList();
    }
  }, [currentPage, startsWith, name, mainUserRes]);

  const getCatalogueList = () => {
    const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
    let central_service_organization_id = null

    onGetOrganization()
      .then(res => {
        central_service_organization_id = res.payload.central_service_organization_id

        console.log("SO ::: ", res.payload)
        return onGetCataloguesList(skip, 9, name, startsWith)
      }).then(res => {
        setLoading(false);

        const payload = res.payload.organizations.filter(organization => {
          return organization._id === central_service_organization_id
        })

        if (res.type === GET_CATALOGUES_SUCCESS) {
          setCatalogLogos(payload);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Row gutter={16}>
      <Col className="mb20" span={24}>
        <Spin spinning={loading}>
          {getRolesPermissions(mainUserRes, "organization.list") === false ? (
            <div>
              {/* <CataloguesBanner /> */}

              <HeaderFilter setSearchResult={setName} />
              <FilterByAlphabet setStartsWith={setStartsWith} />
              <CataloguesByLogo mainUserRes={mainUserRes} data={catalogLogos} />
            </div>
          ) : (
            ""
          )}
        </Spin>
      </Col>
    </Row>
  );
};

export default DashboardPage;
