import React from 'react';

import TopTitleSection from "./TopTitleSection";
import InventoryFilters from "./InventoryFilters";

const HeaderFilter = ({
  onChangeSelections,
  changeSelectionView,
  selection,
  showModal,
  selectionsList,
  location
}) => {

  return (
    <div>
      <TopTitleSection
        onChangeSelections={onChangeSelections}
        selection={selection}
        showModal={showModal}

        selectionsList={selectionsList}
        location={location}
      />
      <InventoryFilters changeSelectionView={changeSelectionView} />
    </div>
  )
}

export default HeaderFilter;