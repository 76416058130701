import React from 'react';
import { Radio } from 'antd';

const MyMarketInventory = ({ onChangeInventory }) => {

  return (
    <div className="my-market-inventory">
      <div><h3>Inventory Search</h3></div>
      <div className="radio-btn-style">
        <Radio.Group onChange={(e) => onChangeInventory(e.target.value)} defaultValue="My" buttonStyle="solid">
          <Radio.Button value="My">My Market Inventory</Radio.Button>
          <Radio.Button disabled={true} value="Market">Market Inventory</Radio.Button>
          <Radio.Button disabled={true} value="Both">Both</Radio.Button>
        </Radio.Group>
      </div>
    </div>
  )
}

export default MyMarketInventory;