import * as types from "../Constants";
import { openNotificationWithIcon } from "../../Components/Common/reUseFunctions";

export const selectionReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_SELECTIONS_LIST_SUCCESS:
      return { ...state, selectionsList: action.payload };
    case types.GET_SELECTIONS_LIST_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, selectionsList: action.payload };
    case types.GET_SELECTION_DETAIL_SUCCESS:
      return { ...state, selectionDetail: action.payload };
    case types.GET_SELECTION_DETAIL_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, selectionDetail: action.payload };

    case types.GET_ASSETS_SELECTION_SUCCESS:
      return { ...state, selectionAssetsListRes: action.payload };
    case types.GET_ASSETS_SELECTION_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, selectionAssetsListRes: action.payload };

    case types.CREATE_SELECTION_SUCCESS:
      return { ...state, createSelection: action.payload };
    case types.CREATE_SELECTION_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, createSelection: action.payload };
    case types.UPDATE_SELECTION_SUCCESS:
      return { ...state, updateSelection: action.payload };
    case types.UPDATE_SELECTION_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, updateSelection: action.payload };
    case types.DELETE_SELECTION_SUCCESS:
      return { ...state, deleteSelection: action.payload };
    case types.DELETE_SELECTION_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, deleteSelection: action.payload };

    case types.DELETE_SELECTION_ASSET_SUCCESS:
      return { ...state, deleteAssetSelectionRes: action.payload };
    case types.DELETE_SELECTION_ASSET_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, deleteAssetSelectionRes: action.payload };

    case types.ADD_FORMAL_LICENSE_SUCCESS:
      return { ...state, addFormalLicenseRes: action.payload };
    case types.ADD_FORMAL_LICENSE_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, addFormalLicenseRes: action.payload };

    case types.GET_FORMAL_LICENSE_LIST_SUCCESS:
      return { ...state, getFormalLicenseRes: action.payload };
    case types.GET_FORMAL_LICENSE_LIST_FAILURE:
      return { ...state, getFormalLicenseRes: action.payload };

    case types.DELETE_FORMAL_LICENSE_LIST_SUCCESS:
      return { ...state, deleteFormalLicenseRes: action.payload };
    case types.DELETE_FORMAL_LICENSE_LIST_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, deleteFormalLicenseRes: action.payload };

    case types.UPATE_FORMAL_LICENSE_LIST_SUCCESS:
      return { ...state, updateFormalLicenseRes: action.payload };
    case types.UPATE_FORMAL_LICENSE_LIST_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, updateFormalLicenseRes: action.payload };

    case types.GET_ALL_ORGANIZATION_SUCCESS:
      return { ...state, getAllOrganizationRes: action.payload };
    case types.GET_ALL_ORGANIZATION_FAILURE:
      openNotificationWithIcon("error", "Error", "Please Try Again");
      return { ...state, getAllOrganizationRes: action.payload };

    default:
      return state;
  }
};
