import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Select, Radio } from "antd";
import {
  MinusCircleOutlined,
  PlusOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const OPTIONS = ["PAL/NTSC", "HD", "UHD/4K", "8K", "Film 35 mm", "other"];

const Index = ({ helperText, requiredField }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [tabsChange, setTabsChange] = useState(true);

  const handleChange = (selectedItems) => {
    setSelectedItems(selectedItems);
  };

  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));

  return (
    <Row
      gutter={16}
      className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}
    >
      <Col className="pl0_important" span={24}>
        <Link to='#' onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={
              tabsChange === true
                ? "metadata-tab-title"
                : "metadata-tab-title metadata-tab-active"
            }
          >
            <h3>Video Track</h3>
            <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
          </div>
        </Link>
      </Col>

      {helperText && (
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text">
            <p>Indicate the specifications of the asset's video track.</p>
          </div>
        </Col>
      )}

      <Col span={24}>
        <div
          className={
            tabsChange === true
              ? "metadata-tab-body metadata-tab-body_hide"
              : "metadata-tab-body"
          }
        >
          <Form.List name="videoTrack">
            {(fields, { add, remove }) => {
              return (
                <div className="metadata-field-box">
                  {fields.map((field) => (
                    <div>
                      <Row gutter={16} className="mb20">
                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="">
                                <span>Resolution</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, "resolution"]}
                                validateTrigger={["onChange", "onBlur"]}
                                style={{ width: "100%" }}
                                className="full-width mr20 mb0"
                              >
                                <Select
                                  placeholder="Select resolution"
                                  value={selectedItems}
                                  onChange={handleChange}
                                  style={{ width: "100%" }}
                                  getPopupContainer={(trigger) =>
                                    trigger.parentElement
                                  }
                                >
                                  {filteredOptions.map((item) => (
                                    <Select.Option key={item} value={item}>
                                      {item}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="">
                                <span>Aspect Ratio</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, "aspect_ratio"]}
                                validateTrigger={["onChange", "onBlur"]}
                                rules={[
                                  {
                                    pattern: new RegExp(
                                      "[0-9]+(.[0-9]+)?:[0-9]+"
                                    ),
                                  },
                                ]}
                                className="mb20"
                              >
                                <Input placeholder="Aspect Ratio" />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="">
                                <span>ThreeD</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, "is_3d"]}
                              >
                                <Radio.Group>
                                  <Radio value="yes">Yes</Radio>
                                  <Radio value="no">No</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="">
                                <span>Color</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, "color"]}
                              >
                                <Radio.Group>
                                  <Radio value="colored">colored</Radio>
                                  <Radio value="black-white">black-white</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="">
                                <span>Video Track Id</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item {...field} name={[field.name, "id"]}>
                                <Input placeholder="Id" />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <MinusCircleOutlined
                            className="ml10 remove-gray-icon"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <div className="d-flex align-item-center flex-end add-more-btn">
                    <Form.Item className="m0">
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: "100%" }}
                      >
                        <PlusOutlined /> Add Video Track
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              );
            }}
          </Form.List>
        </div>
      </Col>
    </Row>
  );
};

export default Index;
