import React, { Fragment, useEffect, useState } from "react";
import { Collapse, Button, message } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import AssetList from "./AssetList";

import {
  getUrlLastId,
  getAssetArray,
  getRolesPermissions,
} from "../../../Common/reUseFunctions";

const { Panel } = Collapse;

const SelectionDetail = ({
  location,
  onDeleteSelectionAssets,
  selectionAssetsListRes,
  onGetSelectionAssets,
  selection,
  assetSelectionFunc,
  onPostTradingTask,
  history,
  hasLicense,
  noLicense,
  mainUserRes,
}) => {
  const [formalLicenseArr, setFormalLicenseArr] = useState();
  const [proceedLoader, setProceedLoader] = useState(false);
  const [selectionAssets, setSelectionAssets] = useState([]);
  const [openTabs, setOpenTabs] = useState("");

  useEffect(() => {
    if (getRolesPermissions(mainUserRes, "selection-item.list") === false) {
      const locationUrl = location.pathname;
      const getUrlPath = getUrlLastId(locationUrl);
      onGetSelectionAssets(getUrlPath);
    }
  }, [mainUserRes]);

  useEffect(() => {
    if (selectionAssetsListRes && selectionAssetsListRes.assets) {
      const isLicience = getAssetArray(
        selectionAssetsListRes && selectionAssetsListRes.assets
      );
      setFormalLicenseArr(isLicience);
    }
  }, [selectionAssetsListRes]);

  useEffect(() => {
    if (getRolesPermissions(mainUserRes, "selection-item.list") === false) {
      if (selectionAssetsListRes && selectionAssetsListRes.assets) {
        if (hasLicense === true && noLicense === true) {
          setSelectionAssets(selectionAssetsListRes.assets);
        }

        if (hasLicense === false && noLicense === true) {
          let noLicenseVar = [];

          selectionAssetsListRes.assets.map((data) => {
            if (
              data.formal_license_definitions &&
              data.formal_license_definitions.length === 0
            ) {
              noLicenseVar.push(data);
            }
          });
          setSelectionAssets(noLicenseVar);
        }

        if (hasLicense === true && noLicense === false) {
          let hasLicenseVar = [];
          selectionAssetsListRes.assets.map((data) => {
            if (
              data.formal_license_definitions &&
              data.formal_license_definitions.length > 0
            ) {
              hasLicenseVar.push(data);
            }
          });
          setSelectionAssets(hasLicenseVar);
        }

        if (hasLicense === false && noLicense === false) {
          setSelectionAssets([]);
        }
      }
    }
  }, [selectionAssetsListRes, hasLicense, noLicense, mainUserRes]);

  const genExtra = (data) => (
    <div className="i-selecion-btn s-col-actions">
      {getRolesPermissions(mainUserRes, "selection-item.delete") === false ? (
        <Button type="link" onClick={() => delAssetSelections(data)}>
          Remove
        </Button>
      ) : (
        ""
      )}

      <Button type="link">
        <span>
          (
          {data &&
            data.formal_license_definitions &&
            data.formal_license_definitions.length}
          ){" "}
        </span>{" "}
        License Definitions
      </Button>
    </div>
  );

  const delAssetSelections = (data) => {
    if (getRolesPermissions(mainUserRes, "selection-item.delete") === false) {
      const locationUrl = location.pathname;
      const getUrlPath = getUrlLastId(locationUrl);
      const assetID = data && data.asset && data.asset._id;
      if (assetID && getUrlPath) {
        onDeleteSelectionAssets(getUrlPath, assetID).then(() => {
          onGetSelectionAssets(getUrlPath);
        });
      }
    }
  };

  const onChangeTabs = (id) => {
    setOpenTabs(id);
    assetSelectionFunc(id);
  };

  const onProceedFunc = (assetObjData) => {
    setProceedLoader(true);
    let vendor = [];
    if (assetObjData && assetObjData.assets.length > 0) {
      assetObjData.assets.map((data) => {
        vendor.push({
          asset: data.asset._id,
          formal_license_definitions: data.formal_license_definitions,
        });
      });
    }
    if (
      getRolesPermissions(mainUserRes, "trading-task.vendor-determination") ===
      false
    ) {
      onPostTradingTask({ assets: vendor }).then((res) => {
        if (res && res.type === "POST_TRADING_TASKS_SUCCESS") {
          setProceedLoader(false);
          history.push("/request-proposal");
        }
      });
    } else {
      message.error("You Don't Have Permission");
      setProceedLoader(false);
    }
  };

  const onGetProceedFunc = () => {
    const locationUrl = location.pathname;
    const getUrlPath = getUrlLastId(locationUrl);
    onGetSelectionAssets(getUrlPath).then((res) => {
      onProceedFunc(res.payload);
    });
  };

  return (
    <Fragment>
      <div className="License-assets-collapse c-col-main select-right-scroll">
        <Collapse
          defaultActiveKey={[openTabs]}
          accordion
          onChange={onChangeTabs}
        >
          {selectionAssets &&
            selectionAssets.length > 0 &&
            selectionAssets.map((data, index) => (
              <Panel
                header={
                  data &&
                  data.asset &&
                  data.asset.title &&
                  data.asset.title.value
                }
                key={data && data.asset && data.asset._id}
                extra={genExtra(data)}
              >
                <AssetList data={data} />
              </Panel>
            ))}
        </Collapse>
      </div>
      {selectionAssetsListRes &&
        selectionAssetsListRes.assets &&
        selectionAssetsListRes.assets.length > 0 && (
          <Fragment>
            {selection === "selection-sales" ? (
              ""
            ) : (
              <div className="primary-link-btn">
                <Button
                  type="primary"
                  loading={proceedLoader}
                  disabled={formalLicenseArr}
                  onClick={() => onGetProceedFunc()}
                >
                  PROCEED <ArrowRightOutlined />
                </Button>
              </div>
            )}
          </Fragment>
        )}
    </Fragment>
  );
};

export default SelectionDetail;
