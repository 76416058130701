import React from "react";
import { Comment } from "antd";
import PropTypes from "prop-types";

const Message = ({ message, author }) => {
  const date = new Date().toLocaleTimeString();
  return (
    <div className="my-message-item">
      <div>
        <div className="my-username">
          {author}, {date}
        </div>
        <Comment className="message-box" content={<p>{message.message}</p>} />
      </div>
    </div>
  );
};

Message.propTypes = {
  message: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
};

export default Message;
