import React, { Fragment, useState } from "react";
import { Row, Col, Avatar, Button, Select } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  getLocalCountrytime,
  getCountrytime,
} from "./../../Common/reUseFunctions";

import LicenseAssetCollapse from "./LicenseAssetCollapse";
import LicenceAgreementSec from "./LicenceAgreementSec";
import RoomPersonsSellingCom from "./RoomPersonsSellings";

const { Option } = Select;

const LicenseSellingContract = ({
  showModal,
  sellingAsset,
  getAllTradingTaskRes,
  getOrganizationProfileRes,
  getFormalLicenseRes,
  onGetFormalLicense,
  location,
  SignSaleConfirmModal,
  signSaleloading,
}) => {
  return (
    <Fragment>
      <div className="license-contract">
        <div className="contract-header">
          <Row gutter={16}>
            <Col span={10}>
              <div className="contract-asset-profile">
                <Avatar
                  src={
                    getAllTradingTaskRes &&
                    getAllTradingTaskRes.data &&
                    getAllTradingTaskRes.data.contact_id_object &&
                    getAllTradingTaskRes.data.contact_id_object.photo
                  }
                  size={60}
                />
                <div>
                  <h3>
                    {getAllTradingTaskRes &&
                      getAllTradingTaskRes.data &&
                      getAllTradingTaskRes.data.contact_id_object &&
                      getAllTradingTaskRes.data.contact_id_object.name}
                  </h3>
                  <p>
                    {getAllTradingTaskRes &&
                      getAllTradingTaskRes.data &&
                      getAllTradingTaskRes.data.contact_id_object &&
                      getAllTradingTaskRes.data.contact_id_object.description}
                  </p>
                </div>
              </div>
            </Col>
            <Col className="contract-time" span={6}>
              <p>
                {getOrganizationProfileRes && getOrganizationProfileRes.country}
                , Local Time {getCountrytime(getOrganizationProfileRes)}
              </p>
            </Col>
            <Col className="contract-time" span={6}>
              <p>Your Local Time {getLocalCountrytime()}</p>
            </Col>
          </Row>
        </div>
        {/* <div className="contract-upload">
          <h3 className="mb20">LICENSE CONTRACT</h3>
          <div style={{ maxWidth: "400px" }}>
            <Upload {...fileUploadProps}>
              <Button>
                <UploadOutlined /> Upload the file...
                </Button>
            </Upload>
          </div>
          <Button className="upload-history" type="link" onClick={() => showModal('file_history')}><HistoryOutlined /> File history</Button>
        </div> */}
        <div>
          {/* <RoomPersonsSellingCom showModal={showModal} /> */}
          <LicenceAgreementSec
            showModal={showModal}
            getAllTradingTaskRes={getAllTradingTaskRes}
          />
          <LicenseAssetCollapse
            getAllTradingTaskRes={getAllTradingTaskRes}
            showModal={showModal}
            sellingAsset={sellingAsset}
            getFormalLicenseRes={getFormalLicenseRes}
            onGetFormalLicense={onGetFormalLicense}
            location={location}
          />
        </div>

        <div className="signature-btn-sec">
          <h5>SIGNATORIES</h5>
          <div className="signature-agreement-btns">
            <Link to="#">
              <Button
                disabled={
                  getAllTradingTaskRes &&
                  getAllTradingTaskRes.data &&
                  getAllTradingTaskRes.data.creator_signed === true
                    ? false
                    : true
                }
                loading={signSaleloading}
                onClick={() => SignSaleConfirmModal("Sign Sale")}
                type="primary"
              >
                SIGN NOW
              </Button>
            </Link>
            {/* <div>
              <div className="signature-select">
                <Select defaultValue="Steve Baker" style={{ width: 180 }} disabled>
                  <Option value="Steve Baker">Steve Baker</Option>
                </Select>
              </div>
            </div>
            <div>
            <Link ><Button type="default">PENDING SIGNATURE</Button></Link>
            </div> */}
          </div>
        </div>

        {/* <div className="close-agreement-btns">
           <Button type="primary" onClick={()=> setVisibleSignature(!visibleSignature)}>CLOSE AGREEMENT</Button>
         </div> */}
      </div>
    </Fragment>
  );
};

export default LicenseSellingContract;
