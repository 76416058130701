import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import HeaderFilter from "./HeaderFilter";
import ProfileSection from "./ProfileSection";
import EditProfileModal from "./EditProfileModal";
import HeaderComponent from "./../../../Header";
import SpecialCataloguesModal from "./SpecialCataloguesModal";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { singular } from "../../../../utils/helper";
import { GET_HIGHLIGHTS_OF_ORGANIZATION_SUCCESS } from "../../../../store/Constants";

const OrganizationProfile = ({
  sessionRes,

  organizationRes,
  onGetOrganization,

  organizationImageRes,
  onGetOrganizationImage,

  organizationProfileRes,
  onUpdateOrganization,

  getSpecificUserDetail,
  onGetSpecificUserDetail,

  specificUserImage,
  onGetSpecificUserImage,

  mainUserRes,
  onGetMainUserDetail,

  createOrganizationRes,
  onCreateOrganization,

  onGetAssetsList,
  onGetCatalogue,
  onUpdateHighlights,
  onGetHighlights,

  onGetTimezones,
  getNotificationsRes,
  onGetNotifications,
  onLogout,
}) => {
  const [modalType, setModalType] = useState("");
  const [visible, setVisible] = useState({
    special_catalogues: false,
    assets: false,
    "edit-organization": false,
  });
  const [heroImage, setHeroImage] = useState();
  const [logoImage, setLogoImage] = useState();
  const [organization, setOrganization] = useState({});
  const [items, setItems] = useState({});
  const [loading, setLoading] = useState(false);
  const [highlights, setHighlights] = useState({
    assets: [],
    special_catalogues: [],
  });
  const [searchResult, setSearchResult] = useState([]);

  useEffect(() => {
    getHighlights();
  }, []);

  const getHighlights = () => {
    onGetHighlights().then((res) => {
      const data = {};

      if (res.type === GET_HIGHLIGHTS_OF_ORGANIZATION_SUCCESS) {
        const highlightsRes = res.payload;

        delete res.payload.error;

        Object.keys(highlightsRes).forEach((key) => {
          const meta = singular(key);
          data[key] = highlightsRes[key].length
            ? highlightsRes[key].map((highlight) =>
                highlight[meta]
                  ? {
                      ...highlight,
                      order: highlight.order,
                      _id: highlight._id,
                      [`${meta}_data`]: highlight[meta],
                      [meta]: highlight[meta]._id,
                    }
                  : null
              )
            : [];
        });

        setHighlights(data);
      }
      setLoading(false);
    });
  };

  const updateHighlight = (data) => {
    onUpdateHighlights({ highlights: data }).then((res) => {
      getHighlights();
    });
  };

  const showModal = (modalType) => {
    setVisible({
      ...visible,
      [modalType]: true,
    });

    setModalType(modalType);
  };

  const handleCancel = () => {
    getOrganization();
    setVisible({
      special_catalogues: false,
      assets: false,
      "edit-organization": false,
    });
  };

  const getOrganization = () => {
    onGetOrganization()
      .then((res) => {
        setOrganization(res.payload);

        if (res && res.payload && res.payload.logo) {
          onGetOrganizationImage(res && res.payload && res.payload.logo)
            .then((res) => {
              const blob = new Blob([res.payload], {
                type: "application/octet-stream",
              });
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function () {
                var base64String = reader.result;
                setLogoImage(base64String);
              };
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        }

        if (res && res.payload && res.payload.promotional_banner) {
          onGetOrganizationImage(
            res && res.payload && res.payload.promotional_banner
          )
            .then((res) => {
              const blob = new Blob([res.payload], {
                type: "application/octet-stream",
              });
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function () {
                var base64String = reader.result;

                // Simply Print the Base64 Encoded String,
                // without additional data: Attributes.
                setHeroImage(base64String);
              };
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <HeaderFilter
        showModal={showModal}
        sessionRes={sessionRes}
        organizationRes={organizationRes}
        mainUserRes={mainUserRes}
      />

      <ProfileSection
        showModal={showModal}
        organizationRes={organizationRes}
        organizationImageRes={organizationImageRes}
        onGetOrganizationImage={onGetOrganizationImage}
        setHeroImage={setHeroImage}
        heroImage={heroImage}
        setLogoImage={setLogoImage}
        logoImage={logoImage}
        modalType={modalType}
        onGetCatalogue={onGetCatalogue}
        onGetAssetsList={onGetAssetsList}
        onUpdateOrganization={onUpdateOrganization}
        items={items}
        setItems={setItems}
        searchResult={searchResult}
        loading={loading}
        setLoading={setLoading}
        getOrganization={getOrganization}
        highlights={highlights}
        mainUserRes={mainUserRes}
      />

      {visible["edit-organization"] && (
        <Modal
          title={false}
          visible={visible["edit-organization"]}
          onCancel={handleCancel}
          footer={false}
          className="modal-style"
          width={555}
        >
          <EditProfileModal
            handleCancel={handleCancel}
            modalType={modalType}
            organizationRes={organizationRes}
            onGetOrganization={onGetOrganization}
            organizationProfileRes={organizationProfileRes}
            onUpdateOrganization={onUpdateOrganization}
            onGetOrganizationImage={onGetOrganizationImage}
            createOrganizationRes={createOrganizationRes}
            onCreateOrganization={onCreateOrganization}
            getOrganization={getOrganization}
            onGetTimezones={onGetTimezones}
            heroImage={heroImage}
            setHeroImage={setHeroImage}
            logoImage={logoImage}
            setLogoImage={setLogoImage}
          />
        </Modal>
      )}

      <Modal
        visible={visible.special_catalogues || visible.assets}
        onCancel={handleCancel}
        footer={false}
        className="modal-trading"
        width="100%"
      >
        <div className="app-header-trading-task">
          <HeaderComponent
            getSpecificUserDetail={getSpecificUserDetail}
            onGetSpecificUserDetail={onGetSpecificUserDetail}
            specificUserImage={specificUserImage}
            onGetSpecificUserImage={onGetSpecificUserImage}
            mainUserRes={mainUserRes}
            onGetMainUserDetail={onGetMainUserDetail}
            onLogout={onLogout}
            organizationRes={organizationRes}
            getNotificationsRes={getNotificationsRes}
            onGetNotifications={onGetNotifications}
          />
        </div>
        <div className="trading-task-detail">
          <Button type="link" className="back-button" onClick={handleCancel}>
            <ArrowLeftOutlined /> Back to {organization.name} Catalogue
          </Button>

          <SpecialCataloguesModal
            items={items}
            loading={loading}
            highlights={highlights}
            updateHighlight={updateHighlight}
            setHighlights={setHighlights}
            setSearchResult={setSearchResult}
            index={modalType}
          />
        </div>
      </Modal>
    </div>
  );
};

export default OrganizationProfile;
