import React, { useEffect, useState, Fragment } from "react";
import { Form, Button, Row, Col, Spin, message, Affix, Modal } from "antd";
import MetadaSwitchFilter from "../MetadataSwitchFilter";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import PermissionsComponent from "./../../../../Common/PermissionsComponent";
import HeaderComponent from "./../../../../Header";
import {
  newYearDate,
  getDateByYear,
  hhmmssFormate,
  getOnlyYear,
  setTiming,
} from "../../../../Common/reUseFunctions";

import OwnerForm from "./OwnerForm";
import InternalIdentification from "./InternalIdentification";
import ISCCGenerate from "./ISCCGenerate";
import FilmGenres from "./FilmGenres";
import MusicDanceGenres from "./MusicDanceGenres";
import CommentsForm from "./CommentForm/index";
import Series from "./Series";
import Chapters from "./Chapters";
import AgeRestrictions from "./AgeRestrictions";
import MultiLanguageTitles from "./MultiLanguageTitles";
import Titles from "./Titles";
import ShortSummaries from "./ShortSummaries";
import LongSummaries from "./LongSummaries";
import Trailer from "./Trailer";
import Screener from "./Screener";
import Awards from "./Awards";
import Festivals from "./Festivals";
import PromotionalMaterial from "./PromotionalMaterial";
import ProductionCompany from "./ProductionCompany";
import CoProductionCompany from "./CoProductionCompanies";
import Director from "./Directors";
import Contributors from "./Contributors";
import Status from "./Status";
import FilmSetting from "./FilmSetting";
import ProductionReleaseDate from "./ProductionReleaseDate";
import RightOffered from "./RightsOffered";
import VideoTrack from "./VideoTracks";
import AudioTrack from "./AudioTracks";
import SubtitleTrack from "./SubtitleTracks";
import Snippets from "./Snippets";
import BudgetForm from "./Budget";
import Versions from "./Versions";
import DurationForm from "./DurationForm";
import AssetModal from "./AssetModal";
import AssetDetails from "../../../AssetDetails";
import { data } from "jquery";

const AssetsDetailOptions = ({
  onGetAssetDetail,
  assetDetail,
  onUpdateAsset,
  onIsccCodeGeneration,
  isccGeneration,
  onIsccGenerationProcess,
  isccStatus,
  assetID,
  mainUserRes,
  setTitleEdit,
  onGetMainUserDetail,
  getSpecificUserDetail,
  onGetSpecificUserDetail,
  specificUserImage,
  onGetSpecificUserImage,
  onLogout,
  getNotificationsRes,
  onGetNotifications,
  onGetInventorySeachDetail,
  getInventorySeachDetailRes,
  idOfAsset,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tabsChange, setTabsChange] = useState(true);
  const [helperText, setHelperText] = useState(false);
  const [requiredField, setRequiredField] = useState(false);
  const [validationField, setValidationField] = useState([]);
  const [tabsCheckbox, setTabsCheckbox] = useState(false);
  const [allMetadata, setAllMetadata] = useState(true);
  const [top, setTop] = useState(10);
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState("");
  const [visibleCatalogue, setVisibleCatalogue] = useState(false);

  useEffect(() => {
    if (assetID) {
      onGetAssetDetail(assetID).then((res) => {});
    }
  }, [assetID]);

  /////////////////////////////   Functions Start   //////////////////////////////////////

  //////////////////////////////////////////////////// Series

  const seriesDataFunc = (obj) => {
    let seriesArr = [];
    seriesArr = [obj];
    let seriesData = [];
    if (seriesArr) {
      seriesArr.map((data, index) =>
        seriesData.push({
          name: data && data.title && data.title.value,
          language: data && data.title && data.title.language,
          multiLanguageTitle: data && data.alternative_titles,
          musicGenre: data && data.music_genres,
          danceGenre: data && data.dance_genres,
          season: data && data.season,
          episode: data && data.episode,
        })
      );
    }

    return seriesData;
  };

  //////////////////////////////////////////////////// Comments
  const commentDataFunc = (arr) => {
    let commentData = [];
    if (arr) {
      arr.map((data, index) =>
        commentData.push({
          external: data && data.external,
          value: data && data.value,
        })
      );
    }

    return commentData;
  };

  //////////////////////////////////////////////////// Chapters
  /////// Multi Laguage

  const contribuDataFunc = (arr) => {
    let contributDataBody = [];
    if (arr) {
      arr.map((data, index) =>
        contributDataBody.push({
          featured:
            (data && data.featured === null && "no") ||
            (data && data.featured === true && "yes"),
          function: data && data.function,
        })
      );
    }
    return contributDataBody;
  };

  const chaptersDataFunc = (arr) => {
    let chaptersData = [];
    if (arr) {
      arr.map((data, index) =>
        chaptersData.push({
          name: data && data.title && data.title.value,
          language: data && data.title && data.title.language,
          multiLanguageTitle: data && data.alternative_titles,
          contributers: data && contribuDataFunc(data.contributors),
          musicGenre: data && data.music_genres,
          danceGenre: data && data.dance_genres,
          duration:
            data &&
            data.duration &&
            new moment(setTiming(data.duration === null ? "" : data.duration)),
          id: data && data.id,
        })
      );
    }

    return chaptersData;
  };

  //////////////////////////////////////////////////// Awards
  const awardDataFunc = (arr) => {
    let awardsgData = [];
    if (arr) {
      arr.map((data, index) =>
        awardsgData.push({
          name: data && data.name,
          category: data && data.category,
          year:
            data &&
            data.year &&
            new moment(
              data.year.toString() && data.duration === null ? "" : data.year
            ),
          position: data && data.position,
          logo: [{ logo: data && data.url }],
        })
      );
    }

    return awardsgData;
  };

  //////////////////////////////////////////////////// Festivals
  const festivalDataFunc = (arr) => {
    let festivalData = [];
    if (arr) {
      arr.map((data, index) =>
        festivalData.push({
          name: data && data.name,
          category: data && data.category,
          year: data && data.year && new moment(data.year.toString()),
          url: data && data.url,
          logo: [{ logo: data && data.logo }],
        })
      );
    }

    return festivalData;
  };

  //////////////////////////////////////////////////// Directors
  const directorsDataFunc = (arr) => {
    let directorData = [];
    if (arr) {
      arr.map((data, index) =>
        directorData.push({
          name: data && data,
        })
      );
    }

    return directorData;
  };

  /////////////////////////////////////////////////////// Versions

  const versionsDataFunc = (arr) => {
    const getStringArray = (listArray, typeValue) => {
      let newArrayData = [];
      if (listArray && listArray.length > 0) {
        listArray.map((data, index) => {
          const newObj = {};
          newObj[typeValue] = data;
          newArrayData.push(newObj);
        });
      }
      return newArrayData;
    };
    let versionsData = [];
    if (arr) {
      arr.map((data, index) => {
        versionsData.push({
          version_id: data.id,
          duration:
            data && data.duration && new moment(setTiming(data.duration)),
          chapters: getStringArray(data.chapters, "chapter"),
          video_tracks: getStringArray(data.video_tracks, "video_track"),
          audio_tracks: getStringArray(data.audio_tracks, "audio_track"),
          subtitle_tracks: getStringArray(
            data.subtitle_tracks,
            "subtitle_track"
          ),
        });
      });
    }
    return versionsData;
  };

  //////////////////////////////////////////////////// Contributors
  const contributorsDataFunc = (arr) => {
    let contributorsData = [];
    if (arr) {
      arr.map((data, index) =>
        contributorsData.push({
          value: data && data.artist && data.artist.value,
          country: data && data.artist && data.artist.country,
          function: data && data.function,
          featured:
            (data && data.featured === true && "yes") ||
            (data && data.featured === null && "no"),
          agent: [
            {
              value: data && data.agent && data.agent.value,
              seat: data && data.agent && data.agent.seat,
              contact: data && data.agent && data.agent.contact,
            },
          ],
        })
      );
    }

    return contributorsData;
  };

  //////////////////////////////////////////////////// Film Setting
  const filmsettingDataFunc = (obj) => {
    let seriesArr = [];
    seriesArr = [obj];

    let filmsettingData = [];
    if (seriesArr) {
      seriesArr.map((data, index) =>
        filmsettingData.push({
          date:
            data && data.date && moment(data.date === null ? "" : data.date),
          location: data && data.location,
        })
      );
    }

    return filmsettingData;
  };
  //////////////////////////////////////////////////// Right Offered
  const territoriesDataFunc = (arr) => {
    let territoryData = [];
    if (arr) {
      arr.map((data, index) =>
        territoryData.push({
          territory: data && data === "false" ? "" : data,
        })
      );
    }

    return territoryData;
  };

  // const territoriesDataTypeFunc = (arr) => {
  //   let territoryDataType = [];
  //   if (arr) {
  //     arr.map((data, index) => territoryDataType.push(data && data.type));
  //   }

  //   return territoryDataType;
  // };

  const rigthOffersDataFunc = (arr) => {
    let rightData = [];
    if (arr) {
      arr.map((data, index) =>
        rightData.push({
          types: data && data.types,
          territory:
            data && data.type === "all_but" ? ["worldwide"] : data.territories,
          excluding: data && data.type === "all_but" ? data.territories : [],
          resale:
            (data && data.resale === true && "yes") ||
            (data && data.resale === null && "no"),
          type: data && data.type,
          beginDate: data && data.begin && new moment(data.begin),
          endDate: data && data.end && new moment(data.end),
          exclusive:
            (data && data.exclusive === true && "yes") ||
            (data && data.exclusive === null && "no"),
        })
      );
    }

    return rightData;
  };

  //////////////////////////////////////////////////// Video Track
  const videoTrackDataFunc = (arr) => {
    let videoTrackData = [];
    if (arr) {
      arr.map((data, index) =>
        videoTrackData.push({
          resolution: data && data.resolution,
          aspect_ratio: data && data.aspect_ratio,
          is_3d:
            (data && data.is_3d === true && "yes") ||
            (data && data.is_3d === null && "no"),
          color: data && data.color,
          id: data && data.id,
        })
      );
    }

    return videoTrackData;
  };

  //////////////////////////////////////////////////// Audio Track
  const audioTrackDataFunc = (arr) => {
    let audioTrackData = [];
    if (arr) {
      arr.map((data, index) =>
        audioTrackData.push({
          language: data && data.language,
          channels: data && data.channels,
          gapless_play:
            (data && data.gapless_play === true && "yes") ||
            (data && data.gapless_play === null && "no"),
          id: data && data.id,
        })
      );
    }

    return audioTrackData;
  };

  //////////////////////////////////////////////////// Snippets

  const snippetsDataFunc = (arr) => {
    let snippetsData = [];
    if (arr) {
      arr.map((data, index) =>
        snippetsData.push({
          snippetTitle: data.title.value,
          language: data.title.language,
          version: data.version,
          timeIn:
            data && data.time_in === null
              ? ""
              : new moment(setTiming(data.time_in)),
          timeOut:
            data && data.time_out === null
              ? ""
              : new moment(setTiming(data.time_out)),
          multiLanguageTitles: data && data.alternative_titles,
        })
      );
    }

    return snippetsData;
  };

  //////////////////////////////////////////////////// Trailer

  const trailerDataFunc = (arr) => {
    let trailerData = [];
    if (arr) {
      arr.map((data, index) => {
        trailerData.push({
          name: data.name,
          director: data.director,
          length: data && data.length === null ? "" : new moment(data.length),
          launch_date:
            data && data.launch_date === null
              ? ""
              : new moment(data.launch_date),
          url: data.url,
        });
      });
    }

    return trailerData;
  };

  //////////////////////////////////////////////////// Preview Image

  const previewMetaDataBodyFunc = (arr) => {
    let previewMetaData = [];
    if (arr) {
      arr.map((data, index) =>
        previewMetaData.push({
          previewImages: data && data === "false" ? "" : data,
        })
      );
    }

    return previewMetaData;
  };

  /////////////////////////////   Functions End   //////////////////////////////////////

  useEffect(() => {
    form.setFieldsValue({
      owner: assetDetail && assetDetail.owner,
      internalIdentification:
        assetDetail && assetDetail.internal_identification,
      name: assetDetail && assetDetail.title && assetDetail.title.value,
      language: assetDetail && assetDetail.title && assetDetail.title.language,
      alternativeTitle: assetDetail && assetDetail.alternative_titles,
      // trailerAndScreeners: trailerScreenerDataFunc(assetDetail && assetDetail.trailerAndScreeners),
      filmGenre: assetDetail && assetDetail.film_genres,
      musicGenre: assetDetail && assetDetail.music_genres,
      danceGenre: assetDetail && assetDetail.dance_genres,
      comments: commentDataFunc(assetDetail && assetDetail.comments),
      series: seriesDataFunc(assetDetail && assetDetail.series),
      // // duration: assetDetail && assetDetail.duration && new moment(setTiming(assetDetail.duration)),
      chapter: chaptersDataFunc(assetDetail && assetDetail.chapters),
      ageRestriction: assetDetail && assetDetail.age_restrictions,
      shortSummaries: assetDetail && assetDetail.content_summaries_short,
      longSummaries: assetDetail && assetDetail.content_summaries_long,
      trailers: trailerDataFunc(assetDetail && assetDetail.trailers),
      version: versionsDataFunc(assetDetail && assetDetail.versions),
      previewImages: previewMetaDataBodyFunc(
        assetDetail && assetDetail.preview_image
      ),
      awards: awardDataFunc(assetDetail && assetDetail.awards),
      festivals: festivalDataFunc(assetDetail && assetDetail.festivals),
      reviews:
        assetDetail &&
        assetDetail.promotional_material &&
        assetDetail.promotional_material.reviews,
      pressClip:
        assetDetail &&
        assetDetail.promotional_material &&
        assetDetail.promotional_material.links,
      titleImage:
        assetDetail &&
        assetDetail.promotional_material &&
        assetDetail.promotional_material.images,
      productionCompanyName:
        assetDetail &&
        assetDetail.production_company &&
        assetDetail.production_company.value,
      productionCompanySeat:
        assetDetail &&
        assetDetail.production_company &&
        assetDetail.production_company.seat,
      productionCompanyContact:
        assetDetail &&
        assetDetail.production_company &&
        assetDetail.production_company.contact,
      coProductionCompanies: assetDetail && assetDetail.co_production_companies,
      directors: directorsDataFunc(assetDetail && assetDetail.directors),
      contributors: contributorsDataFunc(
        assetDetail && assetDetail.contributors
      ),
      status: assetDetail && assetDetail.status,
      filmSetting: filmsettingDataFunc(assetDetail && assetDetail.film_setting),
      productionYear:
        assetDetail &&
        assetDetail.production_date &&
        new moment(
          getDateByYear(
            assetDetail && assetDetail.production_date === null
              ? ""
              : assetDetail.production_date
          )
        ),
      releaseYear:
        assetDetail &&
        assetDetail.release_date &&
        new moment(
          newYearDate(
            assetDetail && assetDetail.release_date === null
              ? ""
              : assetDetail.release_date
          )
        ),
      rightsOffered: rigthOffersDataFunc(
        assetDetail && assetDetail.rights_offered
      ),
      videoTrack: videoTrackDataFunc(assetDetail && assetDetail.video_tracks),
      audioTrack: audioTrackDataFunc(assetDetail && assetDetail.audio_tracks),
      subtitleTrack: assetDetail && assetDetail.subtitle_tracks,
      snippets: snippetsDataFunc(assetDetail && assetDetail.snippets),
      budget: assetDetail && assetDetail.budget,
    });
  }, [assetDetail]);

  const onFinish = (values) => {
    const seriesDataBody = (arr) => {
      let seriesData = [];
      if (arr && arr.length === 1 && !arr[0].name) {
        return false;
      }
      if (arr) {
        arr.map((data, index) =>
          seriesData.push({
            title: {
              value: data.name ? data.name : null,
              language: data && data.language ? data.language : null,
            },
            alternative_titles: data.multiLanguageTitle,
            music_genres: data.musicGenre,
            dance_genres: data.danceGenre,
            season: data && data.season ? data.season : null,
            episode: data && data.episode ? data.episode : null,
          })
        );
      }
      return seriesData && seriesData[0];
    };

    const contribuData = (arr) => {
      let contributData = [];
      if (arr) {
        arr.map((data, index) =>
          contributData.push({
            featured:
              (data && data.featured === "no" && false) ||
              (data && data.featured === "yes" && true),
            function: data && data.function,
          })
        );
      }
      return contributData;
    };

    const chapterDataBody = (arr) => {
      let chapterData = [];
      if (arr) {
        arr.map((data, index) =>
          chapterData.push({
            title: {
              value: data.name ? data.name : null,
              language: data.language ? data.language : null,
            },
            alternative_titles: data.multiLanguageTitle
              ? data.multiLanguageTitle
              : null,
            music_genres: data.musicGenre,
            dance_genres: data.danceGenre,
            duration:
              data && hhmmssFormate(data.duration ? data.duration : null),
            id: data.id ? data.id : null,
            contributors: contribuData(data.contributers),
          })
        );
      }
      return chapterData;
    };

    const awardsDataBody = (arr) => {
      let awardData = [];
      if (arr) {
        arr.map((data, index) =>
          awardData.push({
            name: data.name ? data.name : null,
            category: data.category ? data.category : null,
            position: data.position ? data.position : null,
            logo:
              data && data.logo && data.logo[0] && data.logo[0].logo
                ? data.logo[0].logo
                : null,
            year: data && getOnlyYear(data.year) ? data.year : null,
          })
        );
      }
      return awardData;
    };

    const FestivalsDataBody = (arr) => {
      let festivalData = [];
      if (arr) {
        arr.map((data, index) =>
          festivalData.push({
            name: data && data.name ? data.name : null,
            category: data && data.category ? data.category : null,
            url: data && data.url ? data.url : null,
            logo:
              data && data.logo && data.logo[0] && data.logo[0].logo
                ? data.logo[0].logo
                : null,
            year:
              data && data.year && getOnlyYear(data.year ? data.year : null),
          })
        );
      }
      return festivalData;
    };

    const contributorsDataBody = (arr) => {
      let contributorData = [];
      if (arr) {
        arr.map((data, index) =>
          contributorData.push({
            artist: {
              value: data.value ? data.value : null,
              country: data.country ? data.country : null,
            },
            function: data.function ? data.function : null,
            agent: {
              value:
                data && data.agent && data.agent[0] && data.agent[0].value
                  ? data.agent[0].value
                  : null,
              seat:
                data && data.agent && data.agent[0] && data.agent[0].seat
                  ? data.agent[0].seat
                  : null,
              contact:
                data && data.agent && data.agent[0] && data.agent[0].contact
                  ? data.agent[0].contact
                  : null,
            },
            featured:
              (data && data.featured === "no" && false) ||
              (data && data.featured === "yes" && true) ||
              null,
          })
        );
      }
      return contributorData;
    };

    const filmSettingDataBody = (arr) => {
      let filmSettingDataBody = [];
      if (arr) {
        arr.map((data, index) =>
          filmSettingDataBody.push({
            date: data && data.date ? new moment(data.date) : null,
            location: data && data.location ? data.location : null,
          })
        );
      }
      return filmSettingDataBody && filmSettingDataBody[0];
    };

    const checkTerritory = (data) => {
      if (data && data.territory) {
        if (
          (data.territory.length === 1 && data.territory[0] === "worldwide") ||
          (data && data.territory && data.territory === "worldwide")
        ) {
          return "all_but";
        }

        if (data.territory.length > 0 && data.territory[0] !== "worldwide") {
          return "only";
        }
      }
    };

    const rightOfferedDataBody = (arr) => {
      let rightOfferedData = [];
      if (arr) {
        arr.map((data, index) =>
          rightOfferedData.push({
            types: data && data.types,
            resale:
              (data && data.resale === "no" && false) ||
              (data && data.resale === "yes" && true) ||
              null,
            territories:
              (data &&
                data.territory &&
                data.territory.length === 1 &&
                data.territory[0] === "worldwide") ||
              (data && data.territory && data.territory === "worldwide")
                ? data.excluding
                : data.territory,
            exclusive:
              (data && data.exclusive === "no" && false) ||
              (data && data.exclusive === "yes" && true) ||
              null,
            type: checkTerritory(data),
            begin: data && data.beginDate ? new moment(data.beginDate) : null,
            end: data && data.endDate ? new moment(data.endDate) : null,
          })
        );
      }

      return rightOfferedData;
    };

    const snippetsDataBody = (arr) => {
      let snippetsDataBody = [];
      if (arr) {
        arr.map((data, index) =>
          snippetsDataBody.push({
            title: {
              value: data.snippetTitle ? data.snippetTitle : null,
              language: data.language ? data.language : null,
            },
            version: data.version ? data.version : null,
            time_in: data && data.timeIn ? hhmmssFormate(data.timeIn) : null,
            time_out: data && data.timeOut ? hhmmssFormate(data.timeOut) : null,
            alternative_titles: data.multiLanguageTitles
              ? data.multiLanguageTitles
              : null,
          })
        );
      }

      return snippetsDataBody;
    };

    const previewMetaDataBody = (arr) => {
      let previewMetaDataBody = [];
      if (arr) {
        arr.map((data, index) => previewMetaDataBody.push(data.preview));
      }

      return previewMetaDataBody;
    };

    const coProdcutionCompaniesData = (arr) => {
      let coProdData = [];
      if (arr) {
        arr.map((data, index) => {
          if (data._id) {
            delete data._id;
          }

          coProdData.push(data);
        });
      }

      return coProdData;
    };

    const commentsDataBody = (arr) => {
      let commentDataBody = [];
      if (arr) {
        arr.map((data, index) =>
          commentDataBody.push({
            external: data && data.external ? data.external : null,
            value: data && data.value ? data.value : null,
          })
        );
      }
      return commentDataBody;
    };

    const videotracksDataBody = (arr) => {
      let videoDataBody = [];
      if (arr) {
        arr.map((data, index) =>
          videoDataBody.push({
            resolution: data && data.resolution ? data.resolution : null,
            aspect_ratio: data && data.aspect_ratio ? data.aspect_ratio : null,
            is_3d:
              (data && data.is_3d === "yes" && true) ||
              (data && data.is_3d === "no" && false) ||
              null,
            color: data && data.color ? data.color : null,
            id: data && data.id ? data.id : null,
          })
        );
      }
      return videoDataBody;
    };

    const audiotracksDataBody = (arr) => {
      let audioDataBody = [];
      if (arr) {
        arr.map((data, index) =>
          audioDataBody.push({
            language: data && data.language ? data.language : null,
            channels: data && data.channels ? data.channels : null,
            gapless_play:
              (data && data.gapless_play === "yes" && true) ||
              (data && data.gapless_play === "no" && false) ||
              null,
            id: data && data.id ? data.id : null,
          })
        );
      }
      return audioDataBody;
    };

    const versionsDataBody = (arr) => {
      const getStringArray = (listArray, typeValue) => {
        let newArrayData = [];
        if (listArray && listArray.length > 0) {
          listArray.map((data, index) => {
            newArrayData.push(data[typeValue]);
          });
        }
        return newArrayData;
      };
      let versionsData = [];
      if (arr) {
        arr.map((data, index) => {
          const newObj = {};
          if (data.version_id) {
            newObj.id = data.version_id ? data.version_id : null;
          } else {
            newObj.id = "";
          }
          if (data.duration) {
            newObj.duration = hhmmssFormate(
              data.duration ? data.duration : null
            );
          }
          if (data.chapters && data.chapters.length > 0) {
            newObj.chapters = getStringArray(data.chapters, "chapter");
          } else {
            newObj.chapters = [];
          }
          if (data.video_tracks && data.video_tracks.length > 0) {
            newObj.video_tracks = getStringArray(
              data.video_tracks,
              "video_track"
            );
          } else {
            newObj.video_tracks = [];
          }
          if (data.audio_tracks && data.audio_tracks.length > 0) {
            newObj.audio_tracks = getStringArray(
              data.audio_tracks,
              "audio_track"
            );
          } else {
            newObj.audio_tracks = [];
          }
          if (data.subtitle_tracks && data.subtitle_tracks.length > 0) {
            newObj.subtitle_tracks = getStringArray(
              data.subtitle_tracks,
              "subtitle_track"
            );
          } else {
            newObj.subtitle_tracks = [];
          }
          versionsData.push(newObj);
        });
      }
      return versionsData;
    };

    const directorsData =
      values &&
      values.directors &&
      values.directors.length > 0 &&
      values.directors.map((data) => data.name);

    const assetData = {};

    if (values.owner) {
      assetData.owner = values.owner ? values.owner : null;
    }

    if (values.internalIdentification) {
      assetData.internal_identification = values.internalIdentification
        ? values.internalIdentification
        : null;
    }

    if (values.copyright_information) {
      assetData.copyright_information = values.copyright_information;
    }

    if (values.name) {
      assetData.title = {
        value: values.name ? values.name : null,
        language: values.language ? values.language : null,
      };
    }

    if (
      values &&
      values.alternativeTitle &&
      values.alternativeTitle.length > 0
    ) {
      assetData.alternative_titles = values.alternativeTitle
        ? values.alternativeTitle
        : [];
    }

    // if (values.iscc_code) {
    //   assetData.iscc_code = {
    //     iscc: "0001",
    //     tophash: "abc123",
    //     gmt: "",
    //     bit: [],
    //   };
    // }

    if (values && values.filmGenre && values.filmGenre.length > 0) {
      assetData.film_genres = values.filmGenre;
    }

    if (values && values.musicGenre && values.musicGenre.length > 0) {
      assetData.music_genres = values.musicGenre;
    }

    if (values && values.danceGenre && values.danceGenre.length > 0) {
      assetData.dance_genres = values.danceGenre;
    }

    if (
      values &&
      values.series &&
      values.series.length > 0 &&
      values.series[0].name
    ) {
      assetData.series = seriesDataBody(values.series);
    }

    // if (values && values.duration) {
    //   assetData.duration = hhmmssFormate(values.duration);
    // }

    if (values && values.version && values.version.length > 0) {
      assetData.versions = versionsDataBody(
        values.version ? values.version : null
      );
    }

    if (values && values.chapter && values.chapter.length > 0) {
      assetData.chapters = chapterDataBody(values.chapter);
    }

    if (values && values.ageRestriction && values.ageRestriction.length > 0) {
      assetData.age_restrictions = values.ageRestriction
        ? values.ageRestriction
        : null;
    }

    if (values && values.shortSummaries && values.shortSummaries.length > 0) {
      assetData.content_summaries_short = values.shortSummaries
        ? values.shortSummaries
        : null;
    }

    if (values && values.longSummaries && values.longSummaries.length > 0) {
      assetData.content_summaries_long = values.longSummaries
        ? values.longSummaries
        : null;
    }

    if (values && values.trailers && values.trailers.length > 0) {
      assetData.trailers = values.trailers ? values.trailers : null;
    }

    if (values && values.previewImages) {
      assetData.preview_image = Array.isArray(values.previewImages)
        ? values &&
          values.previewImages &&
          values.previewImages.length > 0 &&
          values.previewImages.map((data) => data.previewImages)
        : [values.previewImages];
    }

    if (values && values.comments && values.comments.length > 0) {
      assetData.comments = commentsDataBody(values.comments);
    }

    if (values && values.awards && values.awards.length > 0) {
      assetData.awards = awardsDataBody(values.awards);
    }

    if (values && values.festivals && values.festivals.length > 0) {
      assetData.festivals = FestivalsDataBody(values.festivals);
    }

    if (
      values.productionCompanyName ||
      values.productionCompanySeat ||
      values.productionCompanyContact
    ) {
      assetData.production_company = {
        value: values.productionCompanyName
          ? values.productionCompanyName
          : null,
        seat: values.productionCompanySeat
          ? values.productionCompanySeat
          : null,
        contact: values.productionCompanyContact
          ? values.productionCompanyContact
          : null,
      };
    }

    if (
      values &&
      values.coProductionCompanies &&
      values.coProductionCompanies.length > 0
    ) {
      assetData.co_production_companies = coProdcutionCompaniesData(
        values.coProductionCompanies
      );
    }

    if (values && values.contributors && values.contributors.length > 0) {
      assetData.contributors = contributorsDataBody(values.contributors);
    }

    if (
      values &&
      values.filmSetting &&
      values.filmSetting.length === 1 &&
      (values.filmSetting[0].date || values.filmSetting.location)
    ) {
      assetData.film_setting = filmSettingDataBody(values.filmSetting);
    }

    if (values && values.releaseYear) {
      assetData.release_date = getOnlyYear(
        values.releaseYear ? values.releaseYear : null
      );
    }

    if (values && values.productionYear) {
      assetData.production_date = getOnlyYear(
        values.productionYear ? values.productionYear : null
      );
    }

    if (values.status) {
      assetData.status = values.status ? values.status : null;
    }

    if (values && values.directors && values.directors.length > 0) {
      assetData.directors =
        values &&
        values.directors &&
        values.directors.length > 0 &&
        values.directors.map((data) => data.name);
    }

    if (values.reviews && values.pressClip && values.titleImage) {
      assetData.promotional_material = {
        reviews: values.reviews,
        links: values.pressClip,
        images: values.titleImage,
      };
    }

    if (values && values.rightsOffered && values.rightsOffered.length > 0) {
      assetData.rights_offered = rightOfferedDataBody(values.rightsOffered);
    }

    if (values && values.snippets && values.snippets.length > 0) {
      assetData.snippets = snippetsDataBody(values.snippets);
    }

    if (values.videoTrack && values.videoTrack.length > 0) {
      assetData.video_tracks = videotracksDataBody(values.videoTrack);
    }

    if (values.audioTrack && values.audioTrack.length > 0) {
      assetData.audio_tracks = audiotracksDataBody(values.audioTrack);
    }

    if (values.subtitleTrack && values.subtitleTrack.length > 0) {
      assetData.subtitle_tracks = values.subtitleTrack;
    }

    if (values.budget) {
      assetData.budget = values.budget ? values.budget : null;
    }

    // const data = {
    // owner: values.owner ? values.owner : "",
    // internal_identification: values.internalIdentification
    //   ? values.internalIdentification
    //   : "",
    // // // copyright_information: "disney",
    // title: {
    //   value: values.name ? values.name : "",
    //   language: values.language ? values.language : "",
    // },
    // alternative_titles: values.alternativeTitle
    //   ? values.alternativeTitle
    //   : [],
    // iscc_code: {
    //   iscc: "0001",
    //   tophash: "abc123",
    //   gmt: "",
    //   bit: [],
    // },
    // film_genres: values.filmGenre ? values.filmGenre : [],
    // music_genres: values.musicGenre ? values.musicGenre : [],
    // dance_genres: values.danceGenre ? values.danceGenre : [],

    // series: seriesDataBody(values.series)
    //   ? seriesDataBody(values.series)
    //   : [],
    // duration: values.duration ? hhmmssFormate(values.duration) : "",
    // chapters: chapterDataBody(values.chapter)
    //   ? chapterDataBody(values.chapter)
    //   : [],

    // age_restrictions: values.ageRestriction ? values.ageRestriction : [],
    // content_summaries_short: values.shortSummaries
    //   ? values.shortSummaries
    //   : [],
    // content_summaries_long: values.longSummaries ? values.longSummaries : [],
    // trailers: values.trailers ? values.trailers : [],
    // preview_images: previewMetaDataBody(values.previewImages)
    //   ? previewMetaDataBody(values.previewImages)
    //   : [],
    // comments: values.comments ? commentsDataBody(values.comments) : [],
    // awards: awardsDataBody(values.awards)
    //   ? awardsDataBody(values.awards)
    //   : [],
    // festivals: FestivalsDataBody(values.festivals)
    //   ? FestivalsDataBody(values.festivals)
    //   : "",
    // production_company: {
    //   value: values.productionCompanyName,
    //   seat: values.productionCompanySeat,
    //   contact: values.productionCompanyContact,
    // },
    // co_production_companies: values.coProductionCompanies
    //   ? coProdcutionCompaniesData(values.coProductionCompanies)
    //   : [],
    // contributors: contributorsDataBody(values.contributors)
    //   ? contributorsDataBody(values.contributors)
    //   : [],
    // film_setting: filmSettingDataBody(values.filmSetting)
    //   ? filmSettingDataBody(values.filmSetting)
    //   : [],
    // release_date: values.releaseYear ? getOnlyYear(values.releaseYear) : "",
    // production_date: values.productionYear
    //   ? getOnlyYear(values.productionYear)
    //   : "",
    // status: values.status,
    // directors: directorsData ? directorsData : [],
    // promotional_material: {
    //   reviews: values.reviews,
    //   links: values.pressClip,
    //   images: values.titleImage,
    // },
    // rights_offered:
    //   tabsCheckbox === false
    //     ? rightOfferedDataBody(values.rightsOffered)
    //       ? rightOfferedDataBody(values.rightsOffered)
    //       : []
    //     : "",
    // snippets: values.snippets && snippetsDataBody(values.snippets),
    // video_tracks: values.videoTrack && videotracksDataBody(values.videoTrack),
    // audio_tracks: values.audioTrack && audiotracksDataBody(values.audioTrack),
    // subtitle_tracks: values.subtitleTrack,
    // };

    onUpdateAsset(assetID, assetData).then(() => {
      setLoading(false);
      message.success("Metadata Updated Successfully");
      onGetAssetDetail(assetID);
    });
  };

  const showModal = (modalType) => {
    setVisible(true);
    setModalType(modalType);
    onGetAssetDetail(assetID);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    errorInfo &&
      errorInfo.errorFields &&
      errorInfo.errorFields.length > 0 &&
      errorInfo.errorFields.map((data) => message.error(data.errors[0]));
    setTabsChange(false);
    setValidationField(errorInfo.errorFields);
  };

  const showDetail = (id) => {
    setVisibleCatalogue(true);
  };

  const canceldetail = (e) => {
    setVisibleCatalogue(false);
  };

  return (
    <Fragment>
      <div className="metadata-switch">
        <Affix offsetTop={130}>
          <MetadaSwitchFilter
            setHelperText={setHelperText}
            helperText={helperText}
            setRequiredField={setRequiredField}
            requiredField={requiredField}
            setAllMetadata={setAllMetadata}
            allMetadata={allMetadata}
          />
        </Affix>
      </div>
      <div className="asset-full-form-style">
        <Spin size="large" spinning={loading}>
          {assetDetail && (
            <Form
              form={form}
              name="register"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              scrollToFirstError
            >
              <Fragment>
                <Row gutter={16} className="mb12">
                  <Col className="pl0_important" span={24}>
                    <Link to="#" onClick={() => setTabsChange(!tabsChange)}>
                      <div
                        className={
                          tabsChange === true
                            ? "metadata-tab-title"
                            : "metadata-tab-title metadata-tab-active"
                        }
                      >
                        <h3>General</h3>
                        <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
                      </div>
                    </Link>
                  </Col>

                  {helperText && (
                    <Col
                      span={24}
                      className={tabsChange === true && "hide-helper-text"}
                    >
                      <div className="heper-text">
                        <p>
                          Indicate the owner of this asset and provide
                          information on the asset's titles and identification.
                        </p>
                      </div>
                    </Col>
                  )}

                  <Col span={24}>
                    <div
                      className={
                        tabsChange === true
                          ? "metadata-tab-body metadata-tab-body_hide"
                          : "metadata-tab-body mb20"
                      }
                    >
                      <div className="metadata-field-box general-tab">
                        <OwnerForm />
                        <ISCCGenerate
                          assetID={assetID}
                          assetDetail={assetDetail}
                          onUpdateAsset={onUpdateAsset}
                          onGetAssetDetail={onGetAssetDetail}
                          onIsccGenerationProcess={onIsccGenerationProcess}
                          isccStatus={isccStatus}
                          onIsccCodeGeneration={onIsccCodeGeneration}
                          isccGeneration={isccGeneration}
                          mainUserRes={mainUserRes}
                          setTitleEdit={setTitleEdit}
                        />
                        <InternalIdentification requiredField={requiredField} />
                        <Titles />
                        <MultiLanguageTitles
                          formName="multi_language"
                          requiredField={requiredField}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Screener
                  validationField={validationField}
                  helperText={helperText}
                />
                <Trailer
                  validationField={validationField}
                  helperText={helperText}
                  requiredField={requiredField}
                />
                <Versions
                  validationField={validationField}
                  helperText={helperText}
                  requiredField={requiredField}
                />
                <FilmGenres
                  helperText={helperText}
                  validationField={validationField}
                />
                <MusicDanceGenres
                  helperText={helperText}
                  validationField={validationField}
                  requiredField={requiredField}
                />
                <CommentsForm
                  helperText={helperText}
                  requiredField={requiredField}
                />

                <Series requiredField={requiredField} helperText={helperText} />
                {/* <DurationForm
                  validationField={validationField}
                  helperText={helperText}
                /> */}
                <Chapters
                  helperText={helperText}
                  requiredField={requiredField}
                  tabsCheckbox={tabsCheckbox}
                />
                <AgeRestrictions
                  helperText={helperText}
                  requiredField={requiredField}
                />
                <ShortSummaries helperText={helperText} />
                <LongSummaries
                  helperText={helperText}
                  requiredField={requiredField}
                />

                <Awards helperText={helperText} requiredField={requiredField} />
                <Festivals
                  helperText={helperText}
                  requiredField={requiredField}
                />
                <PromotionalMaterial
                  helperText={helperText}
                  requiredField={requiredField}
                />
                <ProductionCompany
                  helperText={helperText}
                  requiredField={requiredField}
                />
                <CoProductionCompany
                  helperText={helperText}
                  requiredField={requiredField}
                />
                <Director helperText={helperText} />
                <Contributors
                  helperText={helperText}
                  requiredField={requiredField}
                />
                <Status helperText={helperText} />
                <FilmSetting
                  requiredField={requiredField}
                  helperText={helperText}
                />
                <ProductionReleaseDate
                  validationField={validationField}
                  helperText={helperText}
                  requiredField={requiredField}
                />
                <RightOffered
                  helperText={helperText}
                  setTabsCheckbox={setTabsCheckbox}
                  tabsCheckbox={tabsCheckbox}
                  requiredField={requiredField}
                  assetDetail={assetDetail}
                />
                <VideoTrack
                  helperText={helperText}
                  requiredField={requiredField}
                />
                <AudioTrack
                  helperText={helperText}
                  requiredField={requiredField}
                />
                <SubtitleTrack
                  helperText={helperText}
                  requiredField={requiredField}
                />
                <Snippets
                  helperText={helperText}
                  requiredField={requiredField}
                />

                <BudgetForm
                  helperText={helperText}
                  requiredField={requiredField}
                />
              </Fragment>

              <Form.Item className="update-asset-btn">
                <PermissionsComponent
                  permissions={"ASSET_METADATA_EDIT"}
                  component={() => (
                    <div>
                      <Button
                        type="primary"
                        onClick={() => showModal("view")}
                        className="code-generator mr-15"
                        disabled={
                          assetDetail && assetDetail.market_ready === true
                            ? false
                            : true
                        }
                      >
                        PREVIEW
                      </Button>

                      <Button
                        type="primary"
                        onClick={() => showDetail(assetDetail._id)}
                        className="code-generator mr-15"
                        disabled={
                          assetDetail && assetDetail.market_ready === true
                            ? false
                            : true
                        }
                      >
                        Detail
                      </Button>

                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        className="code-generator"
                      >
                        UPDATE ASSET
                      </Button>
                    </div>
                  )}
                />
              </Form.Item>
            </Form>
          )}
        </Spin>
        {visible && (
          <Modal
            visible={visible}
            onCancel={handleCancel}
            footer={false}
            className={modalType === "view" ? "detail-model" : "modal-style"}
            centered
          >
            {modalType === "view" && <AssetModal assetDetail={assetDetail} />}
          </Modal>
        )}

        <Modal
          visible={visibleCatalogue}
          onCancel={canceldetail}
          footer={false}
          className="modal-trading"
          width="100%"
        >
          <div className="app-header-trading-task">
            <HeaderComponent
              getSpecificUserDetail={getSpecificUserDetail}
              onGetSpecificUserDetail={onGetSpecificUserDetail}
              specificUserImage={specificUserImage}
              onGetSpecificUserImage={onGetSpecificUserImage}
              mainUserRes={mainUserRes}
              onGetMainUserDetail={onGetMainUserDetail}
              onLogout={onLogout}
              onGetNotifications={onGetNotifications}
              getNotificationsRes={getNotificationsRes}
            />
          </div>
          <div className="trading-task-detail ">
            <Button
              type="link"
              className="back-button m0"
              onClick={canceldetail}
            >
              <ArrowLeftOutlined /> Back to Asset Form
            </Button>
            <AssetDetails
              assetDetail={assetDetail}
              onGetInventorySeachDetail={onGetInventorySeachDetail}
              getInventorySeachDetailRes={getInventorySeachDetailRes}
              type="from-metadata-form"
            />
          </div>
        </Modal>
      </div>
    </Fragment>
  );
};

export default AssetsDetailOptions;
