import React from "react";
import { Button } from "antd";
import { lockIcon } from "./../../../Common/Icons";
import { Link } from "react-router-dom";

const index = ({ onChangeSelections }) => {
  // const onChangeInventory = (value) => {
  //   console.log("NEGOTIATIONS VALUE", value)
  // }
  return (
    <div className="my-market-inventory ">
      <div>
        <h3>Negotiation Room {lockIcon}</h3>
      </div>
      <div className="header-btn-groups import-asset-menu">
        <Button className="mr20" type="link">
          <Link to="/selections/qwerty12345">Select Rights</Link>
        </Button>
        <Button className="mr20" type="link">
          <Link to="/selections/5f4f71666e3c8ee2126379e0/proposal">
            Request Proposal
          </Link>
        </Button>
        <Button className="mr20 active" type="link">
          Negotiation Room
        </Button>
        <Button className="mr20" type="link">
          Closing Room
        </Button>
      </div>

      {/* <div className="radio-btn-style negotiation-btns">
        <Button type="default" className="agreement-btn">SIGN AGREEMENT</Button>
        <Radio.Group onChange={(e) => onChangeInventory(e.target.value)} defaultValue="START" buttonStyle="solid">
          <Radio.Button value="START">START NEGOTIATIONS</Radio.Button>
          <Radio.Button value="PAUSE">PAUSE NEGOTIATIONS</Radio.Button>
          <Radio.Button value="CANCEL">CANCEL NEGOTIATIONS</Radio.Button>
        </Radio.Group>
        <Button type="default" icon={<DownloadOutlined />} />
        <Button
          type="default"
          icon={<DownloadOutlined>}
        // onClick={() => showTableEditor('table_editor')}
        />
      </div> */}
    </div>
  );
};

export default index;
