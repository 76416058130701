import React, { useState, useEffect } from 'react';
import { Row, Col, Input, Form, TimePicker, DatePicker, Button } from 'antd';
import { DownOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const Trailer = ({
  validationField,
  helperText,
  requiredField
}) => {
  const [tabsChange, setTabsChange] = useState(true);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if ((validationField && validationField.length > 0) && validationField.map((item) => item.name[0] === "trailer")) {
      setTabsChange(false)
    }
  }, [validationField])

  const onChangeTime = time => {
    setValue(time);
  };

  const onChangeDate = (date, dateString) => {
    console.log(date, dateString);
  }

  return (
    <Row 
      gutter={16} 
      className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}
    >

      <Col className="pl0_important" span={24}>
        <Link to='#' onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={tabsChange === true ? "metadata-tab-title" : "metadata-tab-title metadata-tab-active"}
          >
            <h3>Trailers</h3>
            <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
          </div>
        </Link>
      </Col>

      {
        helperText &&
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text"><p>Provide a link that is openly accessible and not password-protected (e.g. Vimeo or YouTube). For ideal results, choose an aspect ratio of 16:9 and a resolution of 1920x1080px.</p></div>
        </Col>
      }

      <Col span={24}>
        <div className={tabsChange === true ? "metadata-tab-body metadata-tab-body_hide" : "metadata-tab-body"}>
          <Form.List name="trailers">
            {(fields, { add, remove }) => {
              return (
                <div className="metadata-field-box">
                  {fields.map(field => (
                    <div>
                      <Row gutter={16} className="mb20">

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Name</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'name']}
                                fieldKey={[field.fieldKey, 'name']}
                                className="full-width mr20 mb0"
                              >
                                <Input placeholder="Name" />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Launch Date</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'launch_date']}
                                fieldKey={[field.fieldKey, 'launch_date']}
                                className="full-width mr20 mb0"
                              >
                                <DatePicker onChange={onChangeDate} />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Length</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'length']}
                                fieldKey={[field.fieldKey, 'length']}
                                className="full-width mr20 mb0"
                              >
                                <TimePicker value={value} onChange={onChangeTime} />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Director</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'director']}
                                fieldKey={[field.fieldKey, 'director']}
                                className="full-width mr20 mb0"
                              >
                                <Input placeholder="Director" />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Url</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'url']}
                                fieldKey={[field.fieldKey, 'url']}
                                className="full-width mr20 mb0"
                                rules={[
                                  {
                                    pattern: new RegExp("https?://.+"),
                                    message: "Please enter the https://!"
                                  }
                                ]}
                              >
                                <Input placeholder="Trailer Url" />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <MinusCircleOutlined
                            className="ml10 remove-gray-icon"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <div className="d-flex align-item-center flex-end add-more-btn">
                    <Form.Item className="m0">
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '100%' }}
                      >
                        <PlusOutlined /> Add New
                      </Button>
                    </Form.Item>
                  </div>

                </div>
              );
            }}
          </Form.List>
        </div>
      </Col>
    </Row>
  )
}

export default Trailer;