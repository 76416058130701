import React, { Fragment, useState, useEffect } from "react";
import { Tag, Radio } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

import { getOnlyMinutes } from "../../../Common/reUseFunctions";

const AssetDetailText = ({ getInventorySeachDetailRes }) => {
  const [languageValue, setLanguageValue] = useState("");
  const [showSummary, setShowSummary] = useState(false);
  const [singleVersion, setSingleVersion] = useState({});
  const [chapterArr, setChapterArr] = useState([]);
  const [videoTrackArr, setVideoTrackArr] = useState([]);
  const [audioTrackArr, setAudioTrackArr] = useState([]);
  const [subtitleArr, setSubtitleArr] = useState([]);
  const [versionLength, setversionLength] = useState(0);
  const [versionIndex, setversionIndex] = useState(0);
  const [durationsArr, setdurationsArr] = useState([]);

  useEffect(() => {
    versionFirstValueSet(getInventorySeachDetailRes);
  }, [getInventorySeachDetailRes]);

  useEffect(() => {
    if (singleVersion) {
      saveVersionCompleteData(singleVersion);
    }
  }, [singleVersion]);

  const onChangeLanguage = (e) => {
    setLanguageValue(e.target.value);
    setShowSummary();
  };

  const versionFirstValueSet = (inventorySeachDetail) => {
    if (
      inventorySeachDetail &&
      inventorySeachDetail.versions &&
      inventorySeachDetail.versions.length > 0
    ) {
      setSingleVersion(inventorySeachDetail.versions[0]);
      setversionLength(inventorySeachDetail.versions.length);
      setversionIndex(0);
    }
  };

  const saveVersionCompleteData = (versionObj) => {
    const inventoryDetail =
      getInventorySeachDetailRes && getInventorySeachDetailRes;

    if (!versionObj || !inventoryDetail) {
      return;
    }

    let durations = [];
    let chapters = [];
    let audioTracks = [];
    let subtitleTracks = [];
    let videoTracks = [];

    if (versionObj.chapters && versionObj.chapters.length > 0) {
      versionObj.chapters.map((chapter) => {
        if (inventoryDetail.chapters && inventoryDetail.chapters.length > 0) {
          inventoryDetail.chapters.map((data) => {
            if (chapter === data.id) {
              chapters.push(data);
            }
          });
        }
      });
    }

    if (versionObj.audio_tracks && versionObj.audio_tracks.length > 0) {
      versionObj.audio_tracks.map((audio) => {
        if (
          inventoryDetail.audio_tracks &&
          inventoryDetail.audio_tracks.length > 0
        ) {
          inventoryDetail.audio_tracks.map((data) => {
            if (audio === data.id) {
              audioTracks.push(data);
            }
          });
        }
      });
    }

    if (versionObj.subtitle_tracks && versionObj.subtitle_tracks.length > 0) {
      versionObj.subtitle_tracks.map((subtitle) => {
        if (
          inventoryDetail.subtitle_tracks &&
          inventoryDetail.subtitle_tracks.length > 0
        ) {
          inventoryDetail.subtitle_tracks.map((data) => {
            if (subtitle === data.id) {
              subtitleTracks.push(data);
            }
          });
        }
      });
    }

    if (versionObj.video_tracks && versionObj.video_tracks.length > 0) {
      versionObj.video_tracks.map((video) => {
        if (
          inventoryDetail.video_tracks &&
          inventoryDetail.video_tracks.length > 0
        ) {
          inventoryDetail.video_tracks.map((data) => {
            if (video === data.id) {
              videoTracks.push(data);
            }
          });
        }
      });
    }

    if (versionObj.duration) {
      durations.push(versionObj.duration);
    }

    setdurationsArr(durations);
    setChapterArr(chapters);
    setVideoTrackArr(videoTracks);
    setAudioTrackArr(audioTracks);
    setSubtitleArr(subtitleTracks);
  };

  const nextVersionData = () => {
    setSingleVersion(getInventorySeachDetailRes.versions[versionIndex + 1]);
    setversionIndex(versionIndex + 1);
  };

  const previewVersionData = () => {
    setSingleVersion(getInventorySeachDetailRes.versions[versionIndex - 1]);
    setversionIndex(versionIndex - 1);
  };

  console.log("getInventorySeachDetailRes---->", getInventorySeachDetailRes);

  return (
    <Fragment>
      <div className="asset-detail-text">
        <div style={{ width: "42px" }} className="radio-btn-style lang-btn">
          <Radio.Group onChange={onChangeLanguage} buttonStyle="solid">
            {getInventorySeachDetailRes &&
              getInventorySeachDetailRes.alternative_titles &&
              getInventorySeachDetailRes.alternative_titles.length > 0 &&
              getInventorySeachDetailRes.audio_tracks.map((data, index) => (
                <Radio.Button key={index}>{data.language}</Radio.Button>
              ))}
            {/* {
              assetDetail && assetDetail.audioTrack && assetDetail.audioTrack.length > 4 &&
              <Button className="specific-role-btn specific-lang-btn" type="link" onClick={() => showAssetLanguages("languages", languages)}><u>+{assetDetail && assetDetail.audioTrack && assetDetail.audioTrack.length - 4}</u></Button>
            } */}
          </Radio.Group>
        </div>
        <div className="full-width">
          <div className="asset-detail-title">
            <div className="asset-d-title">
              <h3>
                {getInventorySeachDetailRes &&
                  getInventorySeachDetailRes.title &&
                  getInventorySeachDetailRes.title.value}
              </h3>
              <p>
                {versionIndex > 0 && versionLength > 1 ? (
                  <LeftOutlined onClick={previewVersionData} />
                ) : (
                  <LeftOutlined style={{ opacity: 0.3 }} />
                )}

                {singleVersion.id}

                {versionLength > 1 && versionIndex !== versionLength - 1 ? (
                  <RightOutlined onClick={nextVersionData} />
                ) : (
                  <RightOutlined style={{ opacity: 0.3 }} />
                )}
              </p>
            </div>
            <p className="duration">
              <span>
                {getInventorySeachDetailRes &&
                  getInventorySeachDetailRes.production_date}
              </span>
              {durationsArr.map((data) => getOnlyMinutes(data))}
            </p>
            <div className="asset-d-tags mb5">
              <span>Film Genre:</span>
              {getInventorySeachDetailRes &&
                getInventorySeachDetailRes.film_genres.map((data, index) => (
                  <Tag key={index}>{data}</Tag>
                ))}
            </div>
            <div className="asset-d-tags">
              <span>Music Genre:</span>
              {getInventorySeachDetailRes &&
                getInventorySeachDetailRes.music_genres.map((data, index) => (
                  <Tag key={index}>{data}</Tag>
                ))}
            </div>
          </div>
          <div className="asset-d-g-list">
            <div className="w33-per">
              <div>
                <h4>PRODUCTION COMPANY</h4>
                <ul>
                  {
                    <li>
                      {getInventorySeachDetailRes &&
                        getInventorySeachDetailRes.production_company &&
                        getInventorySeachDetailRes.production_company.value}
                    </li>
                  }
                </ul>
              </div>
            </div>
            <div className="w33-per d-flex justify-center">
              <div>
                <h4>CO-PRODUCTION</h4>
                <ul>
                  {getInventorySeachDetailRes &&
                    getInventorySeachDetailRes.co_production_companies &&
                    getInventorySeachDetailRes.co_production_companies.map(
                      (data, index) => <li key={index}>{data.value}</li>
                    )}
                </ul>
              </div>
            </div>
            <div className="w33-per d-flex justify-center">
              <div>
                <h4>DIRECTOR</h4>
                <ul>
                  {getInventorySeachDetailRes &&
                    getInventorySeachDetailRes.directors &&
                    getInventorySeachDetailRes.directors.map((data, index) => (
                      <li key={index}>{data}</li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="asset-d-g-list">
            <div className="w33-per">
              <div>
                <h4>FEATURED ARTISTS</h4>
                <ul>
                  {getInventorySeachDetailRes &&
                    getInventorySeachDetailRes.contributors.map(
                      (data, index) => {
                        return (
                          <li key={index}>
                            {`${data && data.agent && data.agent.value} (${
                              data.function
                            })`}
                          </li>
                        );
                      }
                    )}
                </ul>
              </div>
            </div>
            <div className="w33-per d-flex justify-center">
              <div>
                <h4>STATUS</h4>
                <ul>
                  {(getInventorySeachDetailRes &&
                    getInventorySeachDetailRes.status === "completed" &&
                    "Completed") ||
                    (getInventorySeachDetailRes &&
                      getInventorySeachDetailRes.status === "in progress" &&
                      "Non Market-Ready") ||
                    (getInventorySeachDetailRes &&
                      getInventorySeachDetailRes.status === "ideation" &&
                      "Market-Ready") ||
                    (getInventorySeachDetailRes &&
                      getInventorySeachDetailRes.status === "notMarketReady" &&
                      "Non Market-Ready")}
                </ul>
              </div>
            </div>
          </div>
          <div className="asset-d-g-list">
            <div>
              <h4>SUMMARY</h4>
              {getInventorySeachDetailRes &&
                getInventorySeachDetailRes.content_summaries_short.map(
                  (data, index) => {
                    return (
                      <p
                        key={index}
                        className={
                          showSummary
                            ? "show-full-summary"
                            : "show-half-summary"
                        }
                      >
                        {data.value}
                      </p>
                    );
                  }
                )}
              {/* {
                assetDetail && assetDetail.shortSummaries && assetDetail.shortSummaries[0] && assetDetail.shortSummaries[0].text.length > 100 &&
                <Button onClick={() => setShowSummary(!showSummary)} type="link"><u>More</u></Button>
              } */}
            </div>
          </div>
          <div className="asset-d-g-list">
            <div className="w33-per">
              <div>
                <h4>AGE RESTRICTIONS</h4>
                <ul>
                  {getInventorySeachDetailRes &&
                    getInventorySeachDetailRes.age_restrictions.map(
                      (data, index) => <li key={index}>{data.value}</li>
                    )}
                </ul>
              </div>
            </div>
            <div className="w33-per d-flex justify-center">
              <div>
                <h4>VIDEO TRACKS</h4>
                <ul>
                  {videoTrackArr &&
                    videoTrackArr.length > 0 &&
                    videoTrackArr.map((data, index) => (
                      <li key={index}>{`${data.resolution} (${
                        data.aspect_ratio
                      }, ${data.color}, ${data.is_3d ? "3D" : ""})`}</li>
                    ))}
                </ul>
              </div>
            </div>
            <div className="w33-per d-flex justify-center subtitles-lang">
              <div>
                <h4>SUBTITLES</h4>
                <ul>
                  {subtitleArr &&
                    subtitleArr.length > 0 &&
                    subtitleArr.map((data, index) => (
                      <li key={index}>{data.language}</li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="asset-d-g-list">
            <div className="w40-per">
              <div>
                <h4>AUDIO TRACKS</h4>
                <ul>
                  {audioTrackArr &&
                    audioTrackArr.length > 0 &&
                    audioTrackArr.map((data, index) => (
                      <li
                        key={index}
                      >{`${data.language} (${data.channels})`}</li>
                    ))}
                </ul>
              </div>
            </div>
            <div>
              <h4>CONTRIBUTORS</h4>
              <ul>
                {getInventorySeachDetailRes &&
                  getInventorySeachDetailRes.contributors.map((data, index) => (
                    <li key={index}>
                      {data && data.artist && data.artist.value}{" "}
                      {`(${data.function})`}
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <div className="asset-d-g-list">
            <div className="w40-per">
              <div>
                <h4>CHAPTERS</h4>
                <ol>
                  {chapterArr &&
                    chapterArr.length > 0 &&
                    chapterArr.map((data, index) => (
                      <li key={index}>{data.title.value}</li>
                    ))}
                </ol>
              </div>
            </div>
            <div>
              <h4>TRACKS/SNIPPET</h4>
              <ol>
                {getInventorySeachDetailRes &&
                  getInventorySeachDetailRes.snippets.map((data, index) => (
                    <li key={index}>{data.title.value}</li>
                  ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AssetDetailText;
