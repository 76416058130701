import React, { Fragment } from "react";
import RequestProposalTabList from "./RequestProposalTabList";

const VendorAssetList = ({
  onCreateTradingList,
  postTradingTaskRes,
  assetSelectionFunc,
  selectionDetail,
  userManagementList,
  getUserListRes,
  mainUserRes,
  departmentsWithUsersRes,
  setAssetObject,
  history,
  selectionAssetsListRes,
}) => {
  return (
    <Fragment>
      {/* {
        TabListData && TabListData.map((data, index) => {
          return (
            <RequestProposalTabList
              key={index}
              TabData={data}
            />
          )
        })
      } */}

      <RequestProposalTabList
        onCreateTradingList={onCreateTradingList}
        postTradingTaskRes={postTradingTaskRes}
        assetSelectionFunc={assetSelectionFunc}
        setAssetObject={setAssetObject}
        history={history}
        selectionAssetsListRes={selectionAssetsListRes}
        selectionDetail={selectionDetail}
        userManagementList={userManagementList}
        getUserListRes={getUserListRes}
        mainUserRes={mainUserRes}
        departmentsWithUsersRes={departmentsWithUsersRes}
      />
    </Fragment>
  );
};

export default VendorAssetList;
