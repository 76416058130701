import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Modal, Spin, Pagination } from "antd";

import HeaderFilter from "./HeaderFilter/TopTitleSection";
import MemberProfile from "./PremiumMemberProfile";
import GeneralCatalogues from "./GeneralCataloguesGrid";
import PremiumCatalogues from "./PremiumCataloguesGrid";

import { SelectionModelComp, InventoryModelComp } from "../../../Containers";
import {
  GET_GENERAL_CATALOGUES_SUCCESS,
  GET_HIGHLIGHTS_OF_ORGANIZATION_SUCCESS,
} from "../../../store/Constants";
import { singular } from "../../../utils/helper";
import { getRolesPermissions } from "./../../Common/reUseFunctions";

const CataloguesPremiumProfile = ({
  onGetGeneralCatalogue,
  onGetHighlights,
  mainUserRes,
}) => {
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [assets, setAssets] = useState([]);
  const [organization, setOrganization] = useState({});
  const [highlights, setHighlights] = useState({
    assets: [],
    special_catalogues: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [heroImage, setHeroImage] = useState();
  const [logoImage, setLogoImage] = useState();

  const { id } = useParams();

  useEffect(() => {
    if (getRolesPermissions(mainUserRes, "organization.show") === false) {
      setLoading(true);
      getCatalogueList();
    }
  }, [currentPage, mainUserRes]);

  const showModal = (value) => {
    setVisible(true);
    setModalType(value);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const getCatalogueList = () => {
    const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;

    onGetGeneralCatalogue(skip, 9, id)
      .then((res) => {
        if (res.type === GET_GENERAL_CATALOGUES_SUCCESS) {
          setOrganization(res.payload.organization);
          setAssets(res.payload.assets);
          setLogoImage(res.payload.organization.logo);
          setHeroImage(res.payload.organization.promotional_banner);
        }

        return onGetHighlights();
      })
      .then((res) => {
        setLoading(false);
        const data = {};

        if (res.type === GET_HIGHLIGHTS_OF_ORGANIZATION_SUCCESS) {
          const highlightsRes = res.payload;

          delete res.payload.error;

          Object.keys(highlightsRes).forEach((key) => {
            const meta = singular(key);
            data[key] = highlightsRes[key].length
              ? highlightsRes[key].map((highlight) => ({
                  ...highlight,
                  order: highlight.order,
                  _id: highlight._id,
                  [`${meta}_data`]: highlight[meta],
                  [meta]: highlight[meta]._id,
                }))
              : [];
          });

          setHighlights(data);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <Fragment>
      <Spin size="large" spinning={loading}>
        <Row gutter={16}>
          <Col className="mb25" span={24}>
            <HeaderFilter catalogue_name={organization.name} />
            <MemberProfile
              data={organization}
              logoImage={logoImage}
              heroImage={heroImage}
            />
            <PremiumCatalogues
              showModal={showModal}
              data={organization}
              highlights={highlights}
              setSelectedData={setSelectedData}
            />
            <GeneralCatalogues
              showModal={showModal}
              data={assets}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              loading={loading}
              setSelectedData={setSelectedData}
            />
          </Col>
        </Row>
      </Spin>

      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        className={modalType === "inventory" ? "detail-model" : "modal-style"}
        width={450}
      >
        {modalType === "add_to_selection" && (
          <SelectionModelComp
            handleCloseSelection={handleCancel}
            selectedData={selectedData}
          />
        )}
        {modalType === "inventory" && (
          <InventoryModelComp showTableEditor={showModal} />
        )}
      </Modal>
    </Fragment>
  );
};

export default CataloguesPremiumProfile;
