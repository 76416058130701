import React from "react";
import { Collapse, Tag } from "antd";
import { toDateString } from "./../../Common/reUseFunctions";

const { Panel } = Collapse;

const LicenseAssetCollapse = ({ sellingAsset, getAllTradingTaskRes }) => {
  return (
    <div className="License-assets-collapse">
      <h2>
        {sellingAsset === "selling-assets"
          ? "SELLING ASSETS"
          : "PURCHASING ASSETS"}
      </h2>
      {getAllTradingTaskRes &&
        getAllTradingTaskRes.data &&
        getAllTradingTaskRes.data.assets.map((data, indexOne) => {
          return (
            <Collapse key={indexOne} accordion>
              <Panel header={data.title} key="1">
                {data &&
                  data.formal_license_definitions &&
                  data.formal_license_definitions.length > 0 &&
                  data.formal_license_definitions.map((items, index) => {
                    return (
                      <div key={index} className="slection-asset-view">
                        <div className="formal-license-view">
                          <div className="formal-license-row">
                            <div className="formal-license-name">
                              <span>TERRITORIES</span>
                            </div>
                            <div className="formal-license-data">
                              {items.territories.map((data, index) => {
                                return <Tag key={index}>{data}</Tag>;
                              })}
                            </div>
                          </div>

                          <div className="formal-license-row">
                            <div className="formal-license-name">
                              <span>EXCLUDING</span>
                            </div>
                            <div className="formal-license-data">
                              {items.excluding.map((data, index) => {
                                return <Tag key={index}>{data}</Tag>;
                              })}
                            </div>
                          </div>

                          <div className="formal-license-row">
                            <div className="formal-license-name">
                              <span>CHANNELS</span>
                            </div>
                            <div className="formal-license-data">
                              {items.channels.map((data, index) => {
                                return <Tag key={index}>{data}</Tag>;
                              })}
                            </div>
                          </div>

                          <div className="formal-license-row">
                            <div className="formal-license-name">
                              <span>RESALE ALLOWED</span>
                            </div>
                            <div className="formal-license-data">
                              <span>
                                {items.resale_allowed === true &&
                                  "Yes" &&
                                  items.resale_allowed === false &&
                                  "No"}
                              </span>
                            </div>
                          </div>

                          <div className="formal-license-row">
                            <div className="formal-license-name">
                              <span>EXCLUSIVE LICENSE</span>
                            </div>
                            <div className="formal-license-data">
                              <span>
                                {items.exclusive_license === true &&
                                  "Yes" &&
                                  items.exclusive_license === false &&
                                  "No"}
                              </span>
                            </div>
                          </div>

                          <div className="formal-license-row">
                            <div className="formal-license-name">
                              <span>START DATE</span>
                            </div>
                            <div className="formal-license-data">
                              <span>{toDateString(items.start_date)}</span>
                            </div>
                          </div>

                          <div className="formal-license-row">
                            <div className="formal-license-name">
                              <span>END DATE</span>
                            </div>
                            <div className="formal-license-data">
                              <span>{toDateString(items.end_date)}</span>
                            </div>
                          </div>
                          {/* <div className="formal-license-btn">
                      <Button onClick={() => showModal("license_edit", data.id, items)} type="link"><u>Edit License</u></Button>
                      <Button onClick={() => showModal("add_definition", data.id)} type="link" className="add-definition-btn">{pluseFilledIcon} Add New Definition</Button>
                      <Button type="link" onClick={() => delFormalLicense(items)} className="dell-definition-btn"><CloseOutlined /> Delete</Button>
                    </div> */}
                        </div>
                      </div>
                    );
                  })}
              </Panel>
            </Collapse>
          );
        })}
    </div>
  );
};

export default LicenseAssetCollapse;
