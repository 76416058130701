import React, { useState } from "react";
import { Radio, Button, Form, Input, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";

const AddNewSelectionBtn = ({ onCreateSelection, onGetSelectionsList }) => {
  const [showSelectionForm, setShowSelectionForm] = useState(false);
  const [search, setSearch] = useState("");
  const [selectionType, setSelectionType] = useState("");

  const onChangeSelectionType = (e) => {
    setSelectionType(e.target.value);
  };

  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  const onShowSelectionForm = (value) => {
    setShowSelectionForm(value);
  };

  const onCloseSelectionForm = (value) => {
    setShowSelectionForm(value);
  };

  const onFinish = (values) => {
    const data = {
      name: values.name,
      type: values.type,
    };
    onCreateSelection(data).then(() => onGetSelectionsList());
    message.success("Successfully Created");
    setShowSelectionForm(false);
  };

  return (
    <div>
      {showSelectionForm === false ? (
        <Button
          onClick={() => onShowSelectionForm(true)}
          block
          className="darkgray-btn"
        >
          <PlusOutlined /> Add New Selection
        </Button>
      ) : null}
      {showSelectionForm && (
        <div className="add-select-new">
          <h4>Add New Selection</h4>
          <Form name="basic" onFinish={onFinish}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your Selection name!",
                },
              ]}
            >
              <Input
                value={search}
                onChange={onSearch}
                placeholder="Selection name..."
                // className="width-300"
              />
            </Form.Item>

            <div className="add-select-update">
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: "Please Selection Type!",
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value="sale">Sale</Radio>
                  <Radio value="purchase">Purchase</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item>
                <Button type="link" htmlType="submit">
                  Save
                </Button>
                <Button type="link" onClick={() => onCloseSelectionForm(false)}>
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default AddNewSelectionBtn;
