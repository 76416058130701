import React, { useEffect } from "react";
import { Row, Col, Avatar, Button } from "antd";
import { Link } from "react-router-dom";
import {
  getUrlSecondLastId,
  getRolesPermissions,
} from "./../../../Common/reUseFunctions";

const ChatUserInfo = ({
  showModal,
  videoCall,
  userId,
  onCancelNegotiation,
  cancelNegotiationRes,
  location,
  history,
  getAllTradingTaskRes,
  mainUserRes,
}) => {
  useEffect(() => {
    if (cancelNegotiationRes && cancelNegotiationRes.status === true) {
      history.push("/trading-tasks");
    }
  }, [cancelNegotiationRes]);

  const onCancelNego = () => {
    if (getRolesPermissions(mainUserRes, "trading-task.cancel") === false) {
      const locationUrl = location.pathname;
      const getUrlPath = getUrlSecondLastId(locationUrl);
      onCancelNegotiation(getUrlPath);
    }
  };

  return (
    <div className="contract-header">
      <Row gutter={16}>
        <Col span={12}>
          <div className="contract-asset-profile">
            {/* <Avatar size={50} src={getAllTradingTaskRes && getAllTradingTaskRes.data && getAllTradingTaskRes.data.contact_id_object && getAllTradingTaskRes.data.contact_id_object.photo} /> */}
            <Avatar
              size={50}
              src={
                getAllTradingTaskRes &&
                getAllTradingTaskRes.data &&
                getAllTradingTaskRes.data.contact_id_object &&
                getAllTradingTaskRes.data.contact_id_object.photo
              }
            />
            <div>
              <div className="" id="current-call-details-container">
                <div id="current-call-details">
                  <div id="current-call-target">
                    <div className="current-call-data-value">
                      <h3>
                        {getAllTradingTaskRes &&
                          getAllTradingTaskRes.data &&
                          getAllTradingTaskRes.data.contact_id_object &&
                          getAllTradingTaskRes.data.contact_id_object.name}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              {/* <h3>Ruth Marshall</h3> */}
              <p>
                {getAllTradingTaskRes &&
                  getAllTradingTaskRes.data &&
                  getAllTradingTaskRes.data.contact_id_object &&
                  getAllTradingTaskRes.data.contact_id_object.description}
              </p>
            </div>
          </div>
        </Col>
        <Col className="chat-header-butns" span={12}>
          {/* <div className={videoCall ? "disply-show start-call-btn" : "displya-hide start-call-btn"}>
            <span>
              <button type="button" id="bt-webrtc-connect">Connect</button>
            </span>
          </div>
          {
            videoCall ?
              // <span className="call-progress">Call in Progress 02:00:36</span> 
              <div className="" id="current-call-details-container">
                <div id="current-call-details">
                  <div id="current-call-duration">
                    <div className="current-call-data-value"></div>
                  </div>
                </div>
              </div>
              :
              <Tooltip title="Call">
                <Button onClick={() => showModal("rules_regulation")} shape="circle" icon={phoneIcon} />
              </Tooltip>
          } */}
          <div className="view-cancel-btn">
            {getRolesPermissions(mainUserRes, "trading-task.logs") === false ? (
              <Button type="default" onClick={() => showModal("file_history")}>
                VIEW NEGOTIATION LOG
              </Button>
            ) : (
              ""
            )}

            {getRolesPermissions(mainUserRes, "trading-task.cancel") ===
            false ? (
              <Button onClick={() => onCancelNego()} type="default">
                CANCEL NEGOTIATIONS
              </Button>
            ) : (
              ""
            )}

            {/* <Link to="/selections/lkjhggghgjf56765u56yth/proposal-closing"></Link> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ChatUserInfo;
