import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SelectionsModelComp from "../Components/ContentArea/LicenseTrading/InventorySearch/SelectionsModal";

import {
  getSelectionsList,
  getSelectionDetail,
  createSelection,
  updateSelection,
  deleteSelection,
  addAssetInSelection,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    selectionsList: state.selectionReducer.selectionsList,
    selectionDetail: state.selectionReducer.selectionDetail,
    createSelection: state.selectionReducer.createSelection,
    updateSelection: state.selectionReducer.updateSelection,
    deleteSelection: state.selectionReducer.deleteSelection,
    addAssetInSelectionRes: state.inventorySearchReducer.addAssetInSelectionRes,
    mainUserRes: state.userReducer.mainUserRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetSelectionsList: bindActionCreators(getSelectionsList, dispatch),
    onGetSelectionDetail: bindActionCreators(getSelectionDetail, dispatch),
    onCreateSelection: bindActionCreators(createSelection, dispatch),
    onUpdateSelection: bindActionCreators(updateSelection, dispatch),
    onDeleteSelection: bindActionCreators(deleteSelection, dispatch),
    onAddAssetInSelection: bindActionCreators(addAssetInSelection, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectionsModelComp);
