import React, { Fragment, useState } from 'react';
import { Form, Input, Button, Row, Col, DatePicker } from 'antd';
import { MinusCircleOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const Index = ({
  helperText,
  requiredField
}) => {

  const [awardLogo, setAwardLogo] = useState('');
  const [festivalUrl, setFestivalUrl] = useState('');
  const [tabsChange, setTabsChange] = useState(true);

  const onChangeLogo = (e) => {
    setAwardLogo(e.target.value);
  }
  const onChangeUrl = (e) => {
    setFestivalUrl(e.target.value);
  }

  const config = {
    rules: [{ type: 'object', required: true, message: 'Please select time!' }],
  };

  return (

    <Row 
      gutter={16} 
      className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}
    >

      <Col className="pl0_important" span={24}>
        <Link to='#' onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={tabsChange === true ? "metadata-tab-title" : "metadata-tab-title metadata-tab-active"}
          >
            <h3>Festival</h3>
            <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
          </div>
        </Link>
      </Col>

      {
        helperText &&
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text"><p>Name the festival/s this production was presented at.</p></div>
        </Col>
      }

      <Col span={24}>
        <div className={tabsChange === true ? "metadata-tab-body metadata-tab-body_hide" : "metadata-tab-body"}>
          <Form.List name="festivals">
            {(fields, { add, remove }) => {
              return (
                <div className="metadata-field-box">
                  {fields.map(field => (
                    <div>
                      <Row gutter={16} className="mb20">

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Festival Name</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'name']}
                                fieldKey={[field.fieldKey, 'name']}
                                className="full-width mr10 mb20"
                              >
                                <Input placeholder="Festival name" />
                              </Form.Item>
                            </div>
                          </div>

                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Festival Category</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'category']}
                                fieldKey={[field.fieldKey, 'category']}
                                className="full-width mr10 mb20"
                              >
                                <Input placeholder="Category" />
                              </Form.Item>
                            </div>
                          </div>

                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Festival Year</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                {...config}
                                name={[field.name, 'year']}
                                fieldKey={[field.fieldKey, 'year']}
                                rules={[
                                  {
                                    pattern: new RegExp("[0-9]{4}(\-[0-1][0-9]\-[0-3][0-9])?"),
                                  }
                                ]}
                                className="full-width mr10 mb20"
                              >
                                <DatePicker
                                  picker="year"
                                  className="full-width"
                                  getPopupContainer={trigger => trigger.parentElement}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Festival Url</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'url']}
                                fieldKey={[field.fieldKey, 'url']}
                                rules={[
                                  {
                                    pattern: new RegExp("https?://.+"),
                                    message: "Please enter the https://!"
                                  }
                                ]}
                                className="full-width mr10 mb20"
                              >
                                <Input placeholder="Link" value={festivalUrl} onChange={onChangeUrl} />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Add Logo</span></h3></div>
                            <div className="metadata-filed">
                              <Form.List name={[field.fieldKey, 'logo']}>
                                {(fields, { add, remove }) => {
                                  return (
                                    <Fragment>
                                      {fields.map((field, index) => (
                                        <div key={field} className="d-flex align-item-center mb0">
                                          <Form.Item
                                            required={false}
                                            key={field.key}
                                            className="full-width"
                                          >
                                            <div key={field.key} className="d-flex align-item-center mb0">
                                              <Form.Item
                                                {...field}
                                                name={[field.name, 'logo']}
                                                fieldKey={[field.fieldKey, 'logo']}
                                                validateTrigger={['onChange', 'onBlur']}
                                                rules={[
                                                  {
                                                    pattern: new RegExp("https?://.+"),
                                                    message: "Please enter the https://!"
                                                  }
                                                ]}
                                                noStyle
                                              >
                                                <Input placeholder="Logo link" value={awardLogo} onChange={onChangeLogo} />
                                              </Form.Item>
                                              {fields.length > 0 ? (
                                                <MinusCircleOutlined
                                                  className="ml10 remove-circle"
                                                  onClick={() => {
                                                    remove(field.name, field.fieldKey);
                                                  }}
                                                />
                                              ) : null}
                                            </div>
                                          </Form.Item>
                                        </div>
                                      ))}
                                      {
                                        fields.length > 0 ? null :
                                          <Form.Item>
                                            <Button
                                              type="link"
                                              onClick={() => {
                                                add();
                                              }}
                                              style={{ width: '100%' }}
                                            >
                                              <PlusOutlined /> Add Logo
                                      </Button>
                                          </Form.Item>
                                      }
                                    </Fragment>
                                  );
                                }}
                              </Form.List>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <MinusCircleOutlined
                            className="ml10 remove-gray-icon"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <div className="d-flex align-item-center flex-end add-more-btn">
                    <Form.Item className="m0">
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '100%' }}
                      >
                        <PlusOutlined /> Add Festival
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              );
            }}
          </Form.List>
        </div>
      </Col>
    </Row>
  );
};

export default Index;