import React from 'react';
import { Switch } from 'antd';

const InventorySwitchFilter = ({
  setMarketReady,
  marketReady,
  setNotMarketReady,
  notMarketReady,

  setPublishedAssets,
  publishedAssets,
}) => {

  const onNonNarketReadyAssets = (checked) => {
    setNotMarketReady(checked);
  };

  const onPublishedAssets = (checked) => {
    setPublishedAssets(checked);
  };

  const onMarketReadyAssets = (checked) => {
    setMarketReady(checked);
  };

  return (
    <div>
      <div className="inventry-switch-btns">
        <span>
          <Switch
            checked={publishedAssets}
            onChange={() => onPublishedAssets(!publishedAssets)}
            size="small"
          />
        Published Assets
          </span>
        <span>
          <Switch
            checked={marketReady}
            onChange={() => onMarketReadyAssets(!marketReady)}
            size="small"
          /> Market-Ready Assets
         </span>
        <span>
          <Switch
            checked={notMarketReady}
            onChange={() => onNonNarketReadyAssets(!notMarketReady)}
            size="small"
          />
          Non Market-Ready Assets
          </span>
      </div>
    </div>
  )
}

export default InventorySwitchFilter;