import React, { useEffect, useState } from "react";
import { message, Modal } from "antd";
import LicenseSign from "./LicenseSign";
import OwnershipLicensePublish from "./OwnershipLicensePublish";
import { CheckOutlined } from "@ant-design/icons";

import PermissionsComponent from "./../../../../Common/PermissionsComponent";
import MetadataShare from "./MetadataShare";
import MetadataReShare from "./MetadataReShare";
import WalletModalCom from "./WalletModal";
import { getBinaryCachePath } from "node-sass/lib/extensions";

const bulletsArr = [
  // { title: "Title Provided", label: "title_provided", active: true },
  {
    title: "ISCC code generating",
    label: "content_code_generating",
    active: false,
  },
  {
    title: "ISCC code generated",
    label: "content_code_generated",
    active: false,
  },
  {
    title: "Metadata market-ready",
    label: "metadata_market_ready",
    active: false,
  },
  {
    title: "License being checked by IMZ",
    label: "license_being_signed",
    active: false,
    color: "grey",
  },
  {
    title: "There is no license conflict",
    label: "license_signed",
    active: false,
    color: "grey",
  },
  {
    title: "Ownership license publishing",
    label: "license_publishing",
    active: false,
    color: "grey",
  },
  {
    title: "Ownership license published",
    label: "license_published",
    active: false,
    color: "grey",
  },
  {
    title: "Asset sharing status",
    label: "metadata_share",
    active: false,
    color: "grey",
  },
  {
    title: "All metadata are up to date",
    label: "metadata_up_to_date",
    active: false,
    color: "grey",
  },
  // { title: '0 sub licenses sold', label: 'licenses_sold', active: false }
];

const ProgressScale = ({
  assetID,
  onGetAssetDetail,
  assetDetail,
  onUpdateAsset,
  onLoadSignLicense,
  signLicense,
  onSignatureLicenseProcess,
  signatureStatus,
  onRequestLicenseSignature,
  reqSignature,
  onPublishLicenseBlockchain,
  licensePublish,
  onIsccGenerationProcess,
  isccStatus,
  onGetWalletList,
  walletList,
  sessionRes,

  onPublishAssetMetadata,
  publishAsset,

  onUnpublishAssetMetadata,
  UnpublishAsset,
  mainUserRes,
  isccGeneration,
  setTitleEdit,
}) => {
  const [progresslist, setProgresslist] = useState(bulletsArr);
  const [WalletModal, setWalletModal] = useState(false);
  const [WalletAddress, setWalletAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [reLoading, setReLoading] = useState(false);
  const [bulletsGray, setbulletsGray] = useState(null);

  useEffect(() => {
    const isccCode =
      (assetDetail && assetDetail.iscc && assetDetail.iscc.value && true) ||
      (assetDetail &&
        assetDetail.iscc_code &&
        assetDetail.iscc_code.status === "processing");
    const licenseSigned = assetDetail && assetDetail.signature;
    const marketReadyCheck = assetDetail && assetDetail.market_ready;

    let progressArr = progresslist;
    progresslist.map((data, index) => {
      if (
        data.label === "content_code_generating" ||
        data.label === "content_code_generated"
      ) {
        return (progresslist[index].active = isccCode ? true : false);
      }

      if (data.label === "metadata_market_ready") {
        return (progresslist[index].active = marketReadyCheck);
      }

      // if (
      //   data.label === "license_being_signed" ||
      //   data.label === "license_signed"
      // ) {
      //   return (progresslist[index].active =
      //     licenseSigned && licenseSigned.includes("EUMLC") ? true : false);
      // }

      // if (
      //   data.label === "license_publishing" ||
      //   data.label === "license_published"
      // ) {
      //   return (progresslist[index].active =
      //     assetDetail && assetDetail.tx && !assetDetail.tx.includes("WIP_")
      //       ? true
      //       : false);
      // }
      // if (
      //   data.label === "metadata_share" ||
      //   data.label === "metadata_up_to_date"
      // ) {
      //   return (progresslist[index].active =
      //     assetDetail && assetDetail.shared_at ? true : false);
      // }
      // if (data.label === "metadata_up_to_date") {
      //   return (progresslist[index].active =
      //     assetDetail &&
      //     assetDetail.metadata_synced &&
      //     assetDetail.metadata_synced.same === true
      //       ? true
      //       : false);
      // }
    });

    setProgresslist(progressArr);
  }, [assetDetail, progresslist]);

  const showWalletModel = () => {
    setWalletModal(true);
  };

  const handleCancel = () => {
    setWalletModal(false);
  };

  const handleWalletCancel = () => {
    if (WalletAddress) {
      handleCancel();
      message.success("Wallet select successfully");
    } else {
      message.warning("Please select wallet");
    }
  };

  const publishMetadata = async () => {
    setLoading(true);
    const id = assetDetail && assetDetail._id;
    await onPublishAssetMetadata(id)
      .then(async () => {
        setLoading(false);
        message.success("Asset Shared On Market Inventory Successfully");
        await onGetAssetDetail(id);
        setTitleEdit(true);
        setTitleEdit(false);
      })
      .catch(() => setLoading(false));
  };

  const publishMetadataReshare = async () => {
    setReLoading(true);
    const id = assetDetail && assetDetail._id;
    await onPublishAssetMetadata(id)
      .then(async () => {
        setReLoading(false);
        message.success("Asset Shared On Market Inventory Successfully");
        await onGetAssetDetail(id);
        setTitleEdit(true);
        setTitleEdit(false);
      })
      .catch(() => setReLoading(false));
  };

  const unPublishMetadata = async () => {
    setLoading(true);
    const id = assetDetail && assetDetail._id;
    await onUnpublishAssetMetadata(id)
      .then(async () => {
        setLoading(false);
        message.success("Asset UnPublished Successfully");
        await onGetAssetDetail(id);
        setTitleEdit(true);
        setTitleEdit(false);
      })
      .catch(() => setLoading(false));
  };

  return (
    <div className="custom-timeline">
      <div className="asset-checklist">Asset Checklist</div>
      <div className="list-of-bullets">
        {progresslist.map((data, index) => {
          return (
            <div key={index} className="item-wrapper">
              <div className="item" key={index}>
                <span
                  className={data.active === true ? "bullet active" : "bullet"}
                >
                  {data.active === true && <CheckOutlined />}
                </span>
                <span className={data.color === "grey" ? "color-grey" : "text"}>
                  {data.title}
                </span>
              </div>

              {
                <PermissionsComponent
                  permissions={"SIGN_LICENSE_OWNERSHIP"}
                  component={() =>
                    data.label === "license_being_signed" && (
                      <div className="ml30">
                        {/* {assetDetail && assetDetail.market_ready === true ? (
                          <LicenseSign
                            onGetAssetDetail={onGetAssetDetail}
                            assetDetail={assetDetail}
                            onUpdateAsset={onUpdateAsset}
                            onLoadSignLicense={onLoadSignLicense}
                            signLicense={signLicense}
                            onSignatureLicenseProcess={
                              onSignatureLicenseProcess
                            }
                            signatureStatus={signatureStatus}
                            onRequestLicenseSignature={
                              onRequestLicenseSignature
                            }
                            reqSignature={reqSignature}
                            sessionRes={sessionRes}
                            mainUserRes={mainUserRes}
                            setTitleEdit={setTitleEdit}
                          />
                        ) : (
                          ""
                        )} */}
                      </div>
                    )
                  }
                />
              }

              {data.label === "license_publishing" && (
                <div className="ml30">
                  {/* {assetDetail &&
                  assetDetail.signature &&
                  assetDetail.signature.includes("EUMLC:ISCC") ? (
                    <OwnershipLicensePublish
                      onGetAssetDetail={onGetAssetDetail}
                      assetDetail={assetDetail}
                      onUpdateAsset={onUpdateAsset}
                      onLoadSignLicense={onLoadSignLicense}
                      signLicense={signLicense}
                      onSignatureLicenseProcess={onSignatureLicenseProcess}
                      signatureStatus={signatureStatus}
                      onRequestLicenseSignature={onRequestLicenseSignature}
                      reqSignature={reqSignature}
                      onPublishLicenseBlockchain={onPublishLicenseBlockchain}
                      licensePublish={licensePublish}
                      onIsccGenerationProcess={onIsccGenerationProcess}
                      isccStatus={isccStatus}
                      sessionRes={sessionRes}
                      showWalletModel={showWalletModel}
                      WalletAddress={WalletAddress}
                      mainUserRes={mainUserRes}
                      setTitleEdit={setTitleEdit}
                    />
                  ) : (
                    ""
                  )} */}
                </div>
              )}

              {/* {data.label === "metadata_share" && (
                <div className="ml30">
                  {assetDetail &&
                  assetDetail.tx &&
                  !assetDetail.tx.includes("WIP_") ? (
                    <MetadataShare
                      assetDetail={assetDetail}
                      publishMetadata={publishMetadata}
                      unPublishMetadata={unPublishMetadata}
                      loading={loading}
                      mainUserRes={mainUserRes}
                    />
                  ) : (
                    ""
                  )}
                </div>
              )} */}

              {/* {data.label === "metadata_up_to_date" && (
                <div className="ml30">
                  {assetDetail &&
                  assetDetail.metadata_synced &&
                  assetDetail.metadata_synced.same === false &&
                  assetDetail &&
                  assetDetail.shared_at ? (
                    <MetadataReShare
                      publishMetadataReshare={publishMetadataReshare}
                      reLoading={reLoading}
                    />
                  ) : (
                    ""
                  )}
                </div>
              )} */}
            </div>
          );
        })}
      </div>
      {WalletModal && (
        <Modal
          title={false}
          visible={WalletModal}
          onCancel={handleCancel}
          footer={false}
          className="modal-style"
          width={400}
        >
          <WalletModalCom
            setWalletAddress={setWalletAddress}
            handleWalletCancel={handleWalletCancel}
            onGetWalletList={onGetWalletList}
            walletList={walletList}
          />
        </Modal>
      )}
    </div>
  );
};

export default ProgressScale;
