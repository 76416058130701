import React, { useState } from "react";
import { Input, Button, message } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { sendIcon } from "./../../Common/Icons";
import PropTypes from "prop-types";
import { getRolesPermissions } from "./../../Common/reUseFunctions";

const AddMessage = ({
  onPostSendMessage,
  postSendMessageRes,
  tradingTaskId,
  getAllTradingTaskRes,
  onGetAllMessage,
  mainUserRes,
}) => {
  const [value, setValue] = useState("");

  const onSubmitEnter = (e) => {
    if (e.key === "Enter") {
      setValue("");
    }
  };

  const onClickButton = (e) => {
    addMessageFunc(value);
    setValue("");
  };

  const addMessageFunc = (messages) => {
    const messageBody = {
      trading_task_id:
        getAllTradingTaskRes &&
        getAllTradingTaskRes.data &&
        getAllTradingTaskRes.data._id,
      from_contact_id:
        getAllTradingTaskRes &&
        getAllTradingTaskRes.data &&
        getAllTradingTaskRes.data.assigned_to_object &&
        getAllTradingTaskRes.data.assigned_to_object._id,
      to_contact_id:
        getAllTradingTaskRes &&
        getAllTradingTaskRes.data &&
        getAllTradingTaskRes.data.contact_id_object &&
        getAllTradingTaskRes.data.contact_id_object._id,
      type: "text",
      message: messages,
    };
    if (getRolesPermissions(mainUserRes, "chat.create") === false) {
      onPostSendMessage(messageBody).then((res) => {
        if (res.type === "POST_ADD_MESSAGE_SUCCESS") {
          message.success(
            res && res.payload && res.payload.data && res.payload.data.message
          );
          onGetAllMessage(
            getAllTradingTaskRes &&
              getAllTradingTaskRes.data &&
              getAllTradingTaskRes.data._id
          );
        }
      });
    } else {
      message.error("You Don't Have Permission");
    }
  };

  const onChangeValue = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="d-flex align-item-center mt15 mr20 chat-searchbar">
      <Input
        size="large"
        placeholder="Type a Message"
        value={value}
        onChange={onChangeValue}
        onKeyPress={onSubmitEnter}
      />
      <div className="d-flex align-item-center">
        <Button type="link" className="attached-btn">
          <PaperClipOutlined />
        </Button>
        <Button
          type="link"
          className="send-msg-btn"
          onClick={onClickButton}
          disabled={value ? false : true}
        >
          {sendIcon}
        </Button>
      </div>
    </div>
  );
};

AddMessage.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default AddMessage;
