import React, { Fragment } from "react";
import { Row, Col, Skeleton } from "antd";
import AddAssetsComp from "./AddAssets";
import Placeholder from "../../../../statics/images/placeholder.jpg";
import { getRolesPermissions } from "./../../../Common/reUseFunctions";

const ProfileSection = ({ catalogue, showCatalogue, mainUserRes }) => {
  return (
    <Fragment>
      <Row gutter={24}>
        <Col className="mb40" span={13}>
          <div className="organization-profile-detail">
            <div className="member-profile-text">
              <p>{catalogue.description}</p>
              <div className="total-assets">
                <h3>{catalogue.assets ? catalogue.assets.length : 0}</h3>
                <p>Total assets</p>
              </div>
            </div>
          </div>
        </Col>
        <Col className="mb40" span={11}>
          <div className="member-profile-img">
            {catalogue.banner && (
              <img
                src={`${process.env.REACT_APP_API_BASE_URL}special-catalogues/${catalogue.banner}/image`}
              />
            )}
            {/* <PromotionalBanner /> */}
          </div>
        </Col>
      </Row>
      <Row gutter={16}>
        {getRolesPermissions(mainUserRes, "special-catalogue-item.create") ===
        false ? (
          <Col className="mb40" span={6}>
            <AddAssetsComp showCatalogue={showCatalogue} />
          </Col>
        ) : (
          ""
        )}

        {catalogue &&
          catalogue.assets &&
          catalogue.assets.map((asset) => {
            return getRolesPermissions(
              mainUserRes,
              "special-catalogue-item.list"
            ) === false ? (
              <Col className="mb25" span={6} key={asset._id}>
                <div className="new-assets-grid">
                  {/* {asset.preview_image ? <img src={asset.preview_image} alt={asset.title.value} width={'100%'} /> : <Skeleton.Image />} */}
                  {asset.preview_image ? (
                    <div
                      className={"catalogue-bg"}
                      style={{ backgroundImage: `url(${asset.preview_image})` }}
                    >
                      <img
                        src={Placeholder}
                        alt={asset.title.value}
                        width={"100%"}
                      />
                    </div>
                  ) : (
                    <Skeleton.Image />
                  )}
                  <div className="new-assets-info">
                    <h3>{asset.title.value}</h3>
                  </div>
                </div>
              </Col>
            ) : (
              ""
            );
          })}
      </Row>
    </Fragment>
  );
};

export default ProfileSection;
