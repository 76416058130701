import React from 'react';
import { Button } from 'antd';
import {lockIcon} from './../../../Common/Icons';
import { Link } from 'react-router-dom'; 

const index = ({ onChangeSelections }) => {
  // const onChangeInventory = (value) => {
  //   console.log("NEGOTIATIONS VALUE", value)
  // }
  return (
    <div className="my-market-inventory ">
      <div><h3>Closing Room {lockIcon}</h3></div>
      <div className="header-btn-groups import-asset-menu">
        <Button className="mr20" type="link"><Link to="/selections/qwerty12345">Select Rights</Link></Button>
        <Button className="mr20" type="link"><Link to="/selections/5f4f71666e3c8ee2126379e0/proposal">Request Proposal</Link></Button>
        <Button className="mr20" type="link">Negotiation Room</Button>
        <Button className="mr20 active" type="link">Closing Room</Button>
      </div>
    </div>
  )
}

export default index;