import React, { useState } from 'react';
import { Form, Button, Select } from 'antd';

const SAVEDDROPDOWN = [
  'Search Preset A',
  'Search Preset B',
  'Search Preset C',
  'Search Preset D',
  'Search Preset E',
  'Search Preset F',
];

const SavedPresetFormFilter = ({
  showModal,
}) => {

  const [savedSearch, setSavedSearch] = useState("");
  const onChangeSavedSearch = (value) => {
    setSavedSearch(value)
  }
  const filteredSearch = SAVEDDROPDOWN.filter(o => !savedSearch.includes(o));

  return (
    <div className="saved-search-sec">
      <div className="saved-search-btn">
        <h4 className="header-filter-title">SAVED PRESETS</h4>
        <Button type="link" onClick={() => showModal("search_preset")}><u>View License Presets</u></Button>
      </div>
      <Form.Item
        name="saved_search"
        rules={[{ required: true, message: 'Select Genre' }]}
        className="full-width"
      >
        <Select
          placeholder="Select Preset"
          onChange={onChangeSavedSearch}
        >
          {filteredSearch.map(item => (
            <Select.Option key={item} value={item}>
              {item}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  )
}

export default SavedPresetFormFilter;