import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  DatePicker,
  Select,
  Radio,
  // Checkbox,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
import ExclusingTerritories from "./ExcludingTerritories";

import { countryList } from "./../../../../../../statics/data/countryList";
import { countryListTerritories } from "./../../../../../../statics/data/countryListTerritories";

const { Option } = Select;

const OPTIONS = [
  "All rights",
  "Audio only",
  "Download on Demand",
  "Download to Own",
  "DVD",
  "Educational",
  "Film Festivals",
  "Free TV",
  "Hotels",
  "in-flight",
  "non-commercial",
  "Pay TV",
  "Public Viewing",
  "Ships",
  "Stream on Demand",
  "Theatrical",
];

const Index = ({
  helperText,
  setTabsCheckbox,
  tabsCheckbox,
  requiredField,
  assetDetail,
}) => {
  const [form] = Form.useForm();
  const [selectedItems, setSelectedItems] = useState([]);
  // const [value, setValue] = useState('');
  const [selectedContry, setSelectedContry] = useState([]);
  const [tabsChange, setTabsChange] = useState(true);
  const [selectTerritory, setSelectTerritory] = useState([]);

  const handleChangeCountry = (selectedContry) => {
    setSelectedContry(selectedContry);
  };

  const handleChange = (selectedItems) => {
    setSelectedItems(selectedItems);
  };

  // const onChange = (e) => {
  //   console.log(`checked = ${e.target.checked}`);
  //   setTabsCheckbox(e.target.checked);
  // };

  const onSelectTerritories = (value) => {
    setSelectTerritory(value);
  };

  const config = {
    rules: [{ type: "object", required: true, message: "Please select time!" }],
  };

  console.log("assetDetail--->", assetDetail);

  const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));
  return (
    <Row gutter={16} className="mb5">
      <Col className="pl0_important" span={24}>
        <Link to="#" onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={
              tabsChange === true
                ? "metadata-tab-title"
                : "metadata-tab-title metadata-tab-active"
            }
          >
            <h3>Rights Offered</h3>
            {/* <div className="tabs-check-box">
              <Checkbox onChange={onChange}></Checkbox>
            </div> */}
            <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
          </div>
        </Link>
      </Col>

      {helperText && (
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text">
            <p>Define the license rights available for this asset.</p>
          </div>
        </Col>
      )}

      <Col span={24} disabled={tabsCheckbox ? true : null}>
        <div
          className={
            tabsChange === true
              ? "metadata-tab-body metadata-tab-body_hide"
              : "metadata-tab-body"
          }
        >
          <Form.List name="rightsOffered">
            {(fields, { add, remove }) => {
              return (
                <div className="metadata-field-box">
                  {fields.map((field) => (
                    <div>
                      <Row gutter={16} className="mb20">
                        {/* <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className="required"><span>Types</span></h3></div>
                            <div className="metadata-filed">
                              <Form.List name={[field.fieldKey, 'types']}>
                                {(fields, { add, remove }) => {
                                  return (
                                    <div>
                                      {fields.map((field, index) => (

                                        <div key={field.key} className="d-flex align-item-center mb0">
                                          <Row gutter={16} className="full-width mb0">

                                            <Col span={24}>
                                              <Form.Item
                                                {...field}
                                                name={[field.fieldKey, 'type']}
                                                fieldKey={[field.fieldKey, 'type']}
                                                validateTrigger={['onChange', 'onBlur']}
                                                rules={[{ required: true, message: 'Please select your type!', type: 'array' }]}
                                                style={{ width: '100%' }}
                                                className="mb0"
                                              >
                                                <Select
                                                  mode="multiple"
                                                  placeholder="Select Type..."
                                                  value={selectedItems}
                                                  onChange={handleChange}
                                                  style={{ width: '100%' }}
                                                >
                                                  {filteredOptions.map(item => (
                                                    <Select.Option key={item} value={item}>
                                                      {item}
                                                    </Select.Option>
                                                  ))}
                                                </Select>
                                              </Form.Item>
                                            </Col>
                                          </Row>
                                          {fields.length > 0 ? (
                                            <MinusCircleOutlined
                                              className="ml10 remove-circle-tow"
                                              onClick={() => {
                                                remove(field.name, field.fieldKey);
                                              }}
                                            />
                                          ) : null}
                                        </div>
                                      ))}

                                      <Form.Item>
                                        <Button
                                          type="link"
                                          onClick={() => {
                                            add();
                                          }}
                                          style={{ width: '100%' }}
                                        >
                                          <PlusOutlined /> Add Type
                                      </Button>
                                      </Form.Item>

                                    </div>
                                  );
                                }}
                              </Form.List>
                            </div>
                          </div>
                        </Col> */}

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="required">
                                <span>Types</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.fieldKey, "types"]}
                                fieldKey={[field.fieldKey, "types"]}
                                validateTrigger={["onChange", "onBlur"]}
                                rules={[
                                  {
                                    type: "array",
                                  },
                                ]}
                                style={{ width: "100%" }}
                                className="mb0"
                              >
                                <Select
                                  mode="multiple"
                                  placeholder="Select Type..."
                                  value={selectedItems}
                                  onChange={handleChange}
                                  style={{ width: "100%" }}
                                  disabled={tabsCheckbox ? true : null}
                                >
                                  {filteredOptions.map((item) => (
                                    <Select.Option key={item} value={item}>
                                      {item}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="required">
                                <span>Resale</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.fieldKey, "resale"]}
                                fieldKey={[field.fieldKey, "resale"]}
                                defaultValue="yes"
                              >
                                <Radio.Group
                                  disabled={tabsCheckbox ? true : null}
                                >
                                  <Radio value="yes">Yes</Radio>
                                  <Radio value="no">No</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <ExclusingTerritories
                          rightOffered={
                            assetDetail &&
                            assetDetail.rights_offered &&
                            assetDetail.rights_offered[field.fieldKey]
                          }
                          field={field}
                        />

                        {/* <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="required">
                                <span>Type</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.fieldKey, "type"]}
                              >
                                <Radio.Group
                                  disabled={tabsCheckbox ? true : null}
                                >
                                  <Radio value="all_but">AllBut</Radio>
                                  <Radio value="only">Only</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </div>
                          </div>
                        </Col> */}

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="required">
                                <span>Exclusive</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.fieldKey, "exclusive"]}
                              >
                                <Radio.Group
                                  disabled={tabsCheckbox ? true : null}
                                >
                                  <Radio value="yes">Yes</Radio>
                                  <Radio value="no">No</Radio>
                                </Radio.Group>
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="required">
                                <span>Begin Date</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                {...config}
                                name={[field.name, "beginDate"]}
                                fieldKey={[field.fieldKey, "beginDate"]}
                                rules={[
                                  {
                                    pattern: new RegExp(
                                      "[0-9]{4}([0-1][0-9]'[0-3][0-9])?"
                                    ),
                                  },
                                ]}
                                className="full-width mr10 mb20"
                              >
                                <DatePicker
                                  className="full-width"
                                  disabled={tabsCheckbox ? true : null}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name">
                              <h3 className="required">
                                <span>End Date</span>
                              </h3>
                            </div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                {...config}
                                name={[field.name, "endDate"]}
                                fieldKey={[field.fieldKey, "endDate"]}
                                rules={[
                                  {
                                    pattern: new RegExp(
                                      "[0-9]{4}([0-1][0-9][0-3][0-9])?"
                                    ),
                                  },
                                ]}
                                className="full-width mr10 mb20"
                              >
                                <DatePicker
                                  className="full-width"
                                  disabled={tabsCheckbox ? true : null}
                                />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <MinusCircleOutlined
                            className="ml10 remove-gray-icon"
                            onClick={() => {
                              remove(field.name);
                            }}
                            disabled={tabsCheckbox ? true : null}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <div className="d-flex align-item-center flex-end add-more-btn">
                    <Form.Item className="m0">
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: "100%" }}
                        disabled={tabsCheckbox ? true : null}
                      >
                        <PlusOutlined /> Add Rights Offered
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              );
            }}
          </Form.List>
        </div>
      </Col>
    </Row>
  );
};

export default Index;
