import React, { useState, Fragment } from "react";
import { Row, Col, Modal } from "antd";

import HeaderFilter from "./HeaderFilter";
import SelectionAssetsList from "./SelectionAssetList";
import SelectionAssetView from "./SelectionAssetListView";
import FormalLicenseEdit from "./FormalLicenseEdit";

import LicensePresetModal from "./../../../Common/ColumnsMappingPreset";
import SaveLicensePresetModal from "./../../../Common/Components/SaveMappingPreset";
import { getRolesPermissions } from "./../../../Common/reUseFunctions";

const SelectionAssets = ({
  onChangeSelections,
  onDeleteSelectionAssets,
  selection,
  location,
  selectionAssetsListRes,
  onGetSelectionAssets,
  onAddFormalLicenseDefinition,
  selectionsID,
  history,
  getFormalLicenseRes,
  onGetFormalLicenseList,
  onDeleteFormalLicense,
  deleteFormalLicenseRes,
  onUpdateFormalLicense,
  updateFormalLicenseRes,
  onPostTradingTask,
  postTradingTaskRes,
  mainUserRes,
}) => {
  const [visible, setVisible] = useState(false);
  const [visiblePreset, setVisiblePreset] = useState(false);
  const [visibleLicensePreset, setVisibleLicensePreset] = useState(false);
  const [modelType, setModelType] = useState(false);
  const [selectedAssetId, setSelectedAssetId] = useState("");
  const [updateFormalLicense, setUpdateFormalLicense] = useState({});
  const [licenseId, setlicenseId] = useState("");
  const [hasLicense, setHasLicense] = useState(true);
  const [noLicense, setNoLicense] = useState(true);

  const handleCancel = (e) => {
    setVisible(false);
  };

  const showModalLicensePreset = (e) => {
    setVisibleLicensePreset(true);
  };

  const handleCancelLicensePreset = (e) => {
    setVisibleLicensePreset(false);
  };

  const assetSelectionFunc = (data) => {
    setSelectedAssetId(data);
  };

  const showModal = (e, data) => {
    setVisible(true);
    setModelType(e);
    setUpdateFormalLicense(data);
    setlicenseId(data);
  };

  const handleCancelPreset = (e) => {
    setVisiblePreset(false);
  };

  const showModalPreset = (e) => {
    setVisiblePreset(true);
    setVisible(false);
  };

  return (
    <Fragment>
      <div className="select-right-filter">
        <HeaderFilter
          setHasLicense={setHasLicense}
          setNoLicense={setNoLicense}
        />
      </div>
      <Row gutter="16" style={{ marginTop: "30px" }}>
        <Col span={12}>
          <SelectionAssetsList
            onChangeSelections={onChangeSelections}
            selection={selection}
            onGetSelectionAssets={onGetSelectionAssets}
            selectionAssetsListRes={selectionAssetsListRes}
            location={location}
            onDeleteSelectionAssets={onDeleteSelectionAssets}
            assetSelectionFunc={assetSelectionFunc}
            onPostTradingTask={onPostTradingTask}
            postTradingTaskRes={postTradingTaskRes}
            getFormalLicenseRes={getFormalLicenseRes}
            onGetFormalLicenseList={onGetFormalLicenseList}
            history={history}
            hasLicense={hasLicense}
            noLicense={noLicense}
            mainUserRes={mainUserRes}
          />
        </Col>
        <Col span={12}>
          {/* {
            ((selectionAssetsListRes && selectionAssetsListRes.assets && selectionAssetsListRes.assets.length > 0) &&
            (getFormalLicenseRes &&  getFormalLicenseRes.formal_license_definitions && getFormalLicenseRes.formal_license_definitions.length > 0)) && */}
          {getRolesPermissions(mainUserRes, "selection-license.list") ===
          false ? (
            <SelectionAssetView
              location={location}
              showModal={showModal}
              showModalLicensePreset={showModalLicensePreset}
              getFormalLicenseRes={getFormalLicenseRes}
              onGetFormalLicenseList={onGetFormalLicenseList}
              selectedAssetId={selectedAssetId}
              onDeleteFormalLicense={onDeleteFormalLicense}
              deleteFormalLicenseRes={deleteFormalLicenseRes}
              licenseId={licenseId}
              mainUserRes={mainUserRes}
            />
          ) : (
            ""
          )}
          {/* } */}
        </Col>
      </Row>

      {visible && (
        <Modal
          visible={visible}
          onCancel={handleCancel}
          footer={false}
          className="modal-style"
          width={610}
        >
          <FormalLicenseEdit
            handleCancel={handleCancel}
            licenseId={licenseId}
            modelType={modelType}
            showModal={showModalPreset}
            onAddFormalLicenseDefinition={onAddFormalLicenseDefinition}
            location={location}
            selectedAssetId={selectedAssetId}
            selectionsID={selectionsID}
            onGetFormalLicenseList={onGetFormalLicenseList}
            onUpdateFormalLicense={onUpdateFormalLicense}
            updateFormalLicenseRes={updateFormalLicenseRes}
            updateFormalLicense={updateFormalLicense}
            onGetSelectionAssets={onGetSelectionAssets}
            mainUserRes={mainUserRes}
          />
        </Modal>
      )}

      {visiblePreset && (
        <Modal
          visible={visiblePreset}
          onCancel={handleCancelPreset}
          footer={false}
          className="modal-style"
          width={450}
        >
          <LicensePresetModal
            handleCancel={handleCancelPreset}
            modelType={modelType}
            showModal={showModal}
            ViewSearchPreset="search_preset"
          />
        </Modal>
      )}

      <SaveLicensePresetModal
        handleCancel={handleCancelLicensePreset}
        visible={visibleLicensePreset}
        saveLicenesPreset="saveLicenesPreset"
      />
    </Fragment>
  );
};

export default SelectionAssets;
