import * as types from '../Constants';
import { openNotificationWithIcon } from '../../Components/Common/reUseFunctions';

export const assetMetadataReducer = (state = {}, action) => {
  switch (action.type) {
    case types.ISCC_CODE_UPDATING_SUCCESS:
      return { ...state, isccUpdating: action.payload }
    case types.ISCC_CODE_UPDATING_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      break;
    case types.ISCC_CODE_GENERATION_SUCCESS:
      return { ...state, isccGeneration: action.payload }
    case types.ISCC_CODE_GENERATION_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, isccGeneration: action.payload }
    case types.ISCC_CODE_GENERATION_PROCESS_SUCCESS:
      return { ...state, isccStatus: action.payload }
    case types.ISCC_CODE_GENERATION_PROCESS_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      break;
    case types.LOAD_SIGN_LICENSE_SUCCESS:
      return { ...state, signLicense: action.payload }
    case types.LOAD_SIGN_LICENSE_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      break;
    case types.REQUEST_SIGNATURE_SUCCESS:
      return { ...state, reqSignature: action.payload }
    case types.REQUEST_SIGNATURE_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, reqSignature: action.payload }
    case types.LICENSE_REQUEST_SIGNATURE_PROCESS_SUCCESS:
      return { ...state, signatureStatus: action.payload }
    case types.LICENSE_REQUEST_SIGNATURE_PROCESS_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      break;
    case types.PUBLISH_LICENSE_BLOCKCHAIN_SUCCESS:
      return { ...state, licensePublish: action.payload }
    case types.PUBLISH_LICENSE_BLOCKCHAIN_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      break;
    case types.PUBLISH_ASSET_METADATA_SUCCESS:
      return { ...state, publishAsset: action.payload }
    case types.PUBLISH_ASSET_METADATA_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      break;
    case types.UNPUBLISH_ASSET_METADATA_SUCCESS:
      return { ...state, UnpublishAsset: action.payload }
    case types.UNPUBLISH_ASSET_METADATA_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      break;
    default:
      return state
  }
}