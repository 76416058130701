import React, { useState, useEffect } from "react";
import { Modal, message } from "antd";
import Filter from "./HeaderFilter";
import UserManagementBox from "./List";
import AddNewUserModal from "./AddNewUser";
import CascadingSort from "../../../Common/CascadingSort";
import TableEditor from "../../../Common/TableEditor";
import { userRole } from "../../../../statics/data/userRole";
import DeleteConfirmation from "../../../Common/Components/DeleteConfirmation";
import BlockUserModal from "./BlockUserModal";
import ProfileStatusModal from "./ProfileStatusModal";
import SpecificUserRoles from "./SpecificUserRoles";
import { getRolesPermissions } from "../../../Common/reUseFunctions";

const UserManagement = ({
  rolesList,
  onGetRolesList,
  updateUserInfoRes,
  onUpdateUserInfo,
  onProfileStatus,
  onUserDelete,
  onGetUnblockSpecificUser,
  departmentList,
  onGetDepartmentList,
  mainUserRes,
  userManagementList,
  onGetUserManagementList,
  onClickSelection,
  sessionRes,
  onCreateNewUser,
  getSpecificUserDetail,
  onGetSpecificUserDetail,
  onGetBlockSpecificUser,
}) => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [blockedFilter, setBlockedFilter] = useState(true);
  const [unBlockedFilter, setUnBlockedFilter] = useState(true);
  const [statusFilter, setStatusFilter] = useState(true);
  const [pendingFilter, setPendingFilter] = useState(true);
  const [modalType, setModalType] = useState("");
  const [visible, setVisible] = useState(false);
  const [visibleTE, setVisibleTE] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [delConfirm, setDelConfirm] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (getRolesPermissions(mainUserRes, "user.list") === false) {
      setLoading(true);
      getUsersList(
        currentPage,
        9,
        checkingBlockedStatus(),
        checkingStatus(),
        checkingPendingStatus(),
        search
      );
    }
  }, [
    currentPage,
    blockedFilter,
    unBlockedFilter,
    statusFilter,
    search,
    pendingFilter,
    mainUserRes,
  ]);

  const checkingBlockedStatus = () => {
    if (blockedFilter === true) {
      return "blocked";
    }
    if (blockedFilter === false) {
      return "";
    }
  };

  const checkingStatus = () => {
    if (statusFilter === true) {
      return "active";
    }
    if (statusFilter === false) {
      return "";
    }
  };

  const checkingPendingStatus = () => {
    if (pendingFilter === true) {
      return "pending";
    }
    if (pendingFilter === false) {
      return "";
    }
  };

  const onChangeSelectionType = (e) => {};

  const showModal = (modalType, selectedSpecificData) => {
    setVisible(true);
    setModalType(modalType);
    setSelectedData(selectedSpecificData);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const showTableEditor = (modalType, selectedSpecificData) => {
    setVisibleTE(true);
    setModalType(modalType);
    setSelectedData(selectedSpecificData);
  };

  const cancelTableEditor = (e) => {
    setVisibleTE(false);
  };

  const delConfirmModal = (data) => {
    setSelectedData(data);
    setDelConfirm(true);
  };

  const closeDelConfirmModal = () => {
    setDelConfirm(false);
  };

  const onDeletingItem = (modalType) => {
    setModalType(modalType);
    setDelConfirm(false);
    onUserDelete(selectedData._id).then((res) => {
      getUsersList(
        currentPage,
        9,
        checkingBlockedStatus(),
        checkingStatus(),
        checkingPendingStatus(),
        search
      );
      message.success(res && res.payload && res.payload.message);
    });
  };

  const onBlockSpecificUser = () => {
    setLoading(true);

    if (selectedData.status === "pending" || selectedData.status === "active") {
      onGetBlockSpecificUser(selectedData._id).then(() => {
        const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
        onGetUserManagementList(
          skip,
          9,
          checkingBlockedStatus(),
          checkingStatus(),
          checkingPendingStatus(),
          search
        ).then((res) => {
          message.success("Profile successfully blocked");
        });
        setLoading(false);
        cancelTableEditor();
      });
    }
    if (selectedData.status === "blocked") {
      onGetUnblockSpecificUser(selectedData._id).then(() => {
        const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
        onGetUserManagementList(
          skip,
          9,
          checkingBlockedStatus(),
          checkingStatus(),
          checkingPendingStatus(),
          search
        ).then((res) => {
          message.success("Profile successfully unblocked");
        });
        setLoading(false);
        cancelTableEditor();
      });
    }
  };

  const getUsersList = (
    currentPage,
    limit,
    isBlocked,
    isStatus,
    isPending,
    search
  ) => {
    if (getRolesPermissions(mainUserRes, "user.list") === false) {
      const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
      onGetUserManagementList(
        skip,
        limit,
        isBlocked,
        isStatus,
        isPending,
        search
      )
        .then((res) => {
          setLoading(false);
        })
        .catch((res) => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const onSearch = (e) => {
    setCurrentPage(1);
    setSearch(e.target.value);
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      <Filter
        onSearch={onSearch}
        search={search}
        showModal={showModal}
        onChangeSelectionType={onChangeSelectionType}
        sessionRes={sessionRes}
        showTableEditor={showTableEditor}
        setStatusFilter={setStatusFilter}
        statusFilter={statusFilter}
        setBlockedFilter={setBlockedFilter}
        blockedFilter={blockedFilter}
        setUnBlockedFilter={setUnBlockedFilter}
        unBlockedFilter={unBlockedFilter}
        setPendingFilter={setPendingFilter}
        mainUserRes={mainUserRes}
        pendingFilter={pendingFilter}
      />

      <UserManagementBox
        loading={loading}
        showModal={showModal}
        onClickSelection={onClickSelection}
        userRole={userRole}
        delConfirmModal={delConfirmModal}
        showTableEditor={showTableEditor}
        userManagementList={userManagementList}
        onChangePagination={onChangePagination}
        mainUserRes={mainUserRes}
        currentPage={currentPage}
      />

      {visible && (
        <Modal
          title={false}
          visible={visible}
          onCancel={handleCancel}
          footer={false}
          className="modal-style"
          width={520}
        >
          <AddNewUserModal
            handleCancel={handleCancel}
            modalType={modalType}
            selectedData={selectedData}
            currentPage={currentPage}
            search={search}
            onCreateNewUser={onCreateNewUser}
            getSpecificUserDetail={getSpecificUserDetail}
            onGetSpecificUserDetail={onGetSpecificUserDetail}
            onGetUserManagementList={onGetUserManagementList}
            rolesList={rolesList}
            onGetRolesList={onGetRolesList}
            updateUserInfoRes={updateUserInfoRes}
            onUpdateUserInfo={onUpdateUserInfo}
            checkingBlockedStatus={checkingBlockedStatus}
            checkingStatus={checkingStatus}
            checkingPendingStatus={checkingPendingStatus}
            departmentList={departmentList}
            onGetDepartmentList={onGetDepartmentList}
          />
        </Modal>
      )}

      {visibleTE && (
        <Modal
          visible={visibleTE}
          onCancel={cancelTableEditor}
          footer={false}
          className="modal-style"
          width={
            (modalType === "user-roles" && 420) ||
            (modalType === "block-user" && 520) ||
            (modalType === "table_editor" && 740) ||
            (modalType === "cascading_sort" && 500) ||
            (modalType === "change-status" && 520)
          }
        >
          {modalType === "user-roles" && (
            <SpecificUserRoles selectedData={selectedData} />
          )}
          {modalType === "block-user" && (
            <BlockUserModal
              onBlockSpecificUser={onBlockSpecificUser}
              loading={loading}
              text="When blocked, this user won't be able to log in to your system temporarily, until you unblock her/him again."
              textUnblock="When unblocking, this user will be able to log in to your system again with the same credentials s/he had before."
              selectedData={selectedData}
            />
          )}

          {modalType === "table_editor" && <TableEditor />}

          {modalType === "cascading_sort" && <CascadingSort />}
          {modalType === "change-status" && (
            <ProfileStatusModal
              selectedData={selectedData}
              currentPage={currentPage}
              search={search}
              onProfileStatus={onProfileStatus}
              handleCancel={() => setVisibleTE(false)}
              getSpecificUserDetail={getSpecificUserDetail}
              onGetSpecificUserDetail={onGetSpecificUserDetail}
              onGetUserManagementList={onGetUserManagementList}
              checkingBlockedStatus={checkingBlockedStatus}
              checkingStatus={checkingStatus}
              checkingPendingStatus={checkingPendingStatus}
            />
          )}
        </Modal>
      )}

      <DeleteConfirmation
        visible={delConfirm}
        handleCancel={closeDelConfirmModal}
        onDelete={onDeletingItem}
        text="Some of your colleagues might loose their permissions. This action cannot be undone."
      />
    </div>
  );
};

export default UserManagement;
