import { apiNew } from "../../service/newApi";
import * as types from "../Constants";
import { calculateSearchFilter } from "../../Components/Common/reUseFunctions";

export const getMarketReady = (search, skip, limit) => {
  const options = {
    url: `foreign?q=${encodeURIComponent(
      JSON.stringify({ title: { $regex: search } })
    )}&skip=${skip}&limit=${limit}`,
  };

  options.types = [
    types.GET_MARKET_READY_SUCCESS,
    types.GET_MARKET_READY_FAILURE,
  ];

  return apiNew.get(options);
};
export const getInventory = (
  limit,
  skip,
  columns,
  inventoryType,
  title = ""
) => {
  const options = {
    url: `inventory/items?columns=${columns}&skip=${skip}&limit=${limit}&inventory_type=${inventoryType}&title=${title}`,
  };

  options.types = [
    types.GET_INVENTORY_LIST_SUCCESS,
    types.GET_INVENTORY_LIST_FAILURE,
  ];

  return apiNew.get(options);
};

export const getInventoryList = (
  inventorySearch,
  limit,
  skip,
  inventoryType,
  searchResult,
  cascadingSort,
  orderDisorder
) => {
  const queryFilter = calculateSearchFilter(searchResult);
  const options = {
    url: `inventory/items?title=${inventorySearch}&skip=${skip}&limit=${limit}&inventory_type=${inventoryType}${queryFilter}&sort=${cascadingSort}`,
    // url: `inventory/items?title=${inventorySearch}&skip=${skip}&limit=${limit}&inventory_type=${inventoryType}${queryFilter}&sort=${orderDisorder}`,
  };

  options.types = [
    types.GET_INVENTORY_LIST_SUCCESS,
    types.GET_INVENTORY_LIST_FAILURE,
  ];

  return apiNew.get(options);
};

export const addAssetInSelection = (id, data) => {
  const options = {
    url: `selections/${id}/items`,
  };

  options.types = [
    types.ADD_ASSET_IN_SELECTION_SUCCESS,
    types.ADD_ASSET_IN_SELECTION_FAILURE,
  ];

  return apiNew.post(options, data);
};

export const getInventorySeachDetail = (id) => {
  const options = {
    url: `inventory/items/${id}`,
  };

  options.types = [
    types.GET_INVENTORY_DETAIL_SUCCESS,
    types.GET_INVENTORY_DETAIL_FAILURE,
  ];

  return apiNew.get(options);
};

export const updateInventoryHeader = () => {
  const options = {
    url: `inventory/headers`,
  };

  options.types = [
    types.UPDATE_INVENTORY_HEADER_SUCCESS,
    types.UPDATE_INVENTORY_HEADER_FAILURE,
  ];

  return apiNew.put(options);
};

export const getInventoryHeader = () => {
  const options = {
    url: `inventory/headers`,
  };

  options.types = [
    types.GET_INVENTORY_HEADER_SUCCESS,
    types.GET_INVENTORY_HEADER_FAILURE,
  ];

  return apiNew.get(options);
};
