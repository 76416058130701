import React, { useState,useEffect  } from 'react';

const PermissionsComponent = ({
    component: Component,
    permissions,
    mainUserRes,
}) => {
    const [isPermissions, setIsPermissions] = useState(false);

    useEffect(() => {
        if (mainUserRes) {
          getUserPermissions(mainUserRes)
        }
    }, [mainUserRes])
    
    const getUserPermissions = (mainUser) => {
        let allPermissions = [];

        if (mainUser && mainUser.roles && mainUser.roles.length > 0) {
            mainUser.roles.map((data, index) => {
            allPermissions = [...allPermissions, ...data.permissions];
            });
        }
        const uniqPermissoins = allPermissions.filter(function(item, pos) {
            return allPermissions.indexOf(item) == pos;
        })
        if (uniqPermissoins && uniqPermissoins.includes(permissions)) {
            setIsPermissions(false)
        } else {
            setIsPermissions(true)
        }
    }
     
  return (
    <div>
        {
            isPermissions === false ? <Component /> : <div className="makeDisabled"><Component /></div>  
        }
    </div>
  )
}

export default PermissionsComponent;