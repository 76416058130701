import { connect } from "react-redux";
import MessagesListComponent from "./../Components/ContentArea/ChatApp/MessagesList";
import { bindActionCreators } from "redux";

import { getAllMessage } from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    getAllMessageRes: state.sendMessageReducer.getAllMessageRes,
    mainUserRes: state.userReducer.mainUserRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetAllMessage: bindActionCreators(getAllMessage, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesListComponent);
