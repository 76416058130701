import React from "react";
import { Button } from "antd";
import { pdfFileIcon } from "./../../Common/Icons";
import { HistoryOutlined } from "@ant-design/icons";

const LicenceAgreementSec = ({ getAllTradingTaskRes, showModal }) => {
  return (
    <div className="licence-agreement-sec l-a-section">
      <h2>LICENCE AGREEMENT</h2>
      <div className="l-a-file">
        <Button type="link" target="_blank">
          {pdfFileIcon}
          {getAllTradingTaskRes &&
            getAllTradingTaskRes.data &&
            getAllTradingTaskRes.data.agreement_file &&
            getAllTradingTaskRes.data.agreement_file.substring(0, 50) + "..."}
        </Button>
      </div>
      <p>
        The Agreement will only be shared with your contractor and is not stored
        on any platform server.
      </p>
      <Button onClick={() => showModal("file_history")} type="link">
        <HistoryOutlined /> Negotiation Log
      </Button>
    </div>
  );
};

export default LicenceAgreementSec;
