import React, { useState, useEffect } from "react";
import { Row, Col, Checkbox, Button, Badge } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { exportIcon } from "../../../../Common/Icons";
import { toDateString, TimeFormate } from "./../../../../Common/reUseFunctions";

const PreviewModal = ({
  showModal,
  setApproved,
  tradingTaskId,
  cancelTableEditor,
  onGetAllTradingTask,
  getAllTradingTaskRes,
  onUpdateTradingTaskApproval,
}) => {
  const [activeClass, setActiveClass] = useState("");

  useEffect(() => {
    onGetAllTradingTask(tradingTaskId);
  }, []);

  const updateApproval = (task_id, approval_id) => {
    onUpdateTradingTaskApproval(task_id, approval_id, { approved: true }).then(
      () => {
        onGetAllTradingTask(tradingTaskId);
        setApproved(true);
      }
    );
  };

  const onAssetSelection = (id) => {
    setActiveClass(id);
  };

  return (
    <Row gutter={16}>
      <div className="no-asset-header">
        <h3>
          {exportIcon}{" "}
          {getAllTradingTaskRes &&
            getAllTradingTaskRes.data &&
            getAllTradingTaskRes.data.company_title}
        </h3>
      </div>
      <div className="preview-tabslist">
        <div className="mb10">
          <div className="vendor-selection-header">
            <div className="vendor-name">
              <p>
                Form{" "}
                <strong>
                  {getAllTradingTaskRes &&
                    getAllTradingTaskRes.data &&
                    getAllTradingTaskRes.data.name}
                </strong>{" "}
                by{" "}
                <strong>
                  {getAllTradingTaskRes &&
                    getAllTradingTaskRes.data &&
                    getAllTradingTaskRes.data.selection_created_by}
                </strong>
              </p>
            </div>
          </div>
          {getAllTradingTaskRes &&
            getAllTradingTaskRes.data &&
            getAllTradingTaskRes.data.assets &&
            getAllTradingTaskRes.data.assets.map((data, index) => {
              return (
                <div
                  key={index}
                  className="inventory-slection-assets vendor-selection"
                >
                  <div
                    onClick={() => onAssetSelection(data._id)}
                    className={
                      activeClass === data._id
                        ? "inventory-slection-asset active"
                        : "inventory-slection-asset" ||
                          (activeClass === data[0]._id &&
                            "inventory-slection-asset active")
                    }
                  >
                    <div className="i-slection-name">
                      <Checkbox checked={data} value="A">
                        {data.title}
                      </Checkbox>
                    </div>
                    <div className="i-selecion-icon">
                      <RightOutlined />
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="preview-modal-info pt20_imp">
        <h4>DEADLINE</h4>
        <div className="preview-modal-sec">
          <div className="mr4rem">
            <span>
              {getAllTradingTaskRes &&
                getAllTradingTaskRes.data &&
                toDateString(getAllTradingTaskRes.data.deadline)}
            </span>
          </div>
          <div>
            <span>
              {getAllTradingTaskRes &&
                getAllTradingTaskRes.data &&
                TimeFormate(getAllTradingTaskRes.data.deadline)}
            </span>
          </div>
        </div>
      </div>
      <div className="preview-modal-info">
        <h4>COMMENTS</h4>
        <div className="preview-modal-sec">
          <div>
            <span>
              {getAllTradingTaskRes &&
                getAllTradingTaskRes.data &&
                getAllTradingTaskRes.data.comments}
            </span>
          </div>
        </div>
      </div>
      {getAllTradingTaskRes &&
        getAllTradingTaskRes.data &&
        getAllTradingTaskRes.data.type === "purchase" &&
        getAllTradingTaskRes.data.approvals &&
        getAllTradingTaskRes.data.approvals.length && (
          <div className="preview-modal-info mb40">
            <h4>APPROVALS</h4>
            <div className="ant-row preview-modal-sec justify-between">
              {getAllTradingTaskRes.data.approvals.map((approval, index) => (
                <div key={index} className="ant-col-8 mb10 inlineflex">
                  <Badge
                    color={approval.approved ? "36E15E" : "#FF3131"}
                    className="badge-dot-style"
                  />
                  <Row gutter={16}>
                    <Col span={24}>
                      <h4>{approval.department}</h4>
                      <span>{approval.user}</span>
                    </Col>
                    <Col span={24}>
                      <div className="request-approvals-btns">
                        {!approval.approved ? (
                          <Button
                            type="primary"
                            size={"small"}
                            onClick={() =>
                              updateApproval(
                                getAllTradingTaskRes.data._id,
                                approval._id
                              )
                            }
                          >
                            REQUEST APPROVALS
                          </Button>
                        ) : (
                          <Button
                            className="apr"
                            size={"small"}
                            type="secondary"
                          >
                            APPROVED
                          </Button>
                        )}
                        {/* <Button className="req" size={'small'} disabled type="secondary">REQUESTED</Button> */}
                      </div>
                    </Col>
                  </Row>
                </div>
              ))}
            </div>
          </div>
        )}
      <div className="no-asset-btns preview-modal-btns">
        <Button onClick={() => showModal("invite-preveiw")} type="default">
          INVITE FOR NEGOTIATION
        </Button>
        <Button onClick={cancelTableEditor} type="primary">
          REQUEST PROPOSAL
        </Button>
      </div>
    </Row>
  );
};

export default PreviewModal;
