import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Modal, message } from "antd";
import LicenseContract from "./LicenseContract";
import LicenseSellingContract from "./LicenseSellingContract";
import ProposalHeaderFilter from "./ProposalHeaderFilter";
import RulesRegulationModal from "./Chat/RulesRegulationModal";
import NegotiationInvitation from "./Chat/NegotiationInvitationModal";
import FormalLicenseEdit from "./FormalLicenseEdit";
import Filehistory from "./../SelectionProposal/FileHistory";
import InvitePersonsInRoom from "../../Common/InvitePersonsInRoom";
import SignNowModal from "./SignNowModal";
import { getUrlSecondLastId } from "../../Common/reUseFunctions";
import DeleteConfirmation from "../../Common/Components/DeleteConfirmation";

const SelectionProposalClosing = ({
  history,
  location,
  onGetLogsList,
  getLogsListRes,
  onGetAllTradingTask,
  getAllTradingTaskRes,
  onGetOrganizationProfile,
  getOrganizationProfileRes,
  getFormalLicenseRes,
  onGetFormalLicense,
  onAddSignSale,
  onGetSign,
  getSignRes,
  onGetSignSale,
  onGetAssetIdSale,
  onGetOrganizationImage,
  cancelNegotiationRes,
  onCancelNegotiation,
}) => {
  const [logoImage, setLogoImage] = useState();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalType, setModalType] = useState(false);
  const [delConfirm, setDelConfirm] = useState(false);
  const [signSaleConfirm, setSignSaleConfirm] = useState(false);
  const [signSaleloading, setSignSaleLoading] = useState(false);

  useEffect(() => {
    const locationUrl = location.pathname;
    const tradingTaskId = getUrlSecondLastId(locationUrl);
    onGetAllTradingTask(tradingTaskId);
    onGetLogsList(tradingTaskId);
  }, []);

  useEffect(() => {
    onGetOrganizationProfile().then((res) => {
      onGetOrganizationImage(res && res.payload && res.payload.logo).then(
        (res) => {
          const blob = new Blob([res.payload], {
            type: "application/octet-stream",
          });
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            var base64String = reader.result;
            setLogoImage(base64String);
          };
        }
      );
    });
  }, []);

  const delConfirmModal = (value) => {
    setDelConfirm(true);
    setModalType(value);
  };

  const onChangeSelections = (e) => {
    // setChangeSelectionView(e)
  };

  const closeDelConfirmModal = () => {
    setDelConfirm(false);
    setSignSaleConfirm(false);
  };

  const showModal = (value) => {
    setVisible(true);
    setModalType(value);
  };

  const onVideoCall = () => {
    setVisible(false);
    // setVideoCall(true)
  };

  const handleCancel = (value) => {
    setVisible(false);
    // setShowChat(value)
  };

  // const dropVideoCall = () => {
  //   setVideoCall(false)
  // }

  const onConfirmDelete = () => {
    setDelConfirm(false);
    setLoading(true);
    const locationUrl = location.pathname;
    const tradingTaskId = getUrlSecondLastId(locationUrl);
    onGetSign(tradingTaskId).then((res) => {
      message.success(
        res && res.payload && res.payload.data && res.payload.data.message
      );
      setLoading(false);
    });
  };

  const SignSaleConfirmModal = (value) => {
    setSignSaleConfirm(true);
    setModalType(value);
  };

  const onConfirmSignSale = () => {
    setSignSaleConfirm(false);
    setSignSaleLoading(true);
    const locationUrl = location.pathname;
    const tradingTaskId = getUrlSecondLastId(locationUrl);
    if (
      getAllTradingTaskRes &&
      getAllTradingTaskRes.data &&
      getAllTradingTaskRes.data.assets &&
      getAllTradingTaskRes.data.assets.length > 0
    ) {
      getAllTradingTaskRes.data.assets.map((data) => {
        return onGetSignSale(tradingTaskId, data.id).then((response) => {
          onGetAssetIdSale(data.id).then((res) => {
            if (res && res.type === "GET_ASSET_ID_SALE_SUCCESS") {
              const signSaleBody = {
                license: response && response.payload,
              };
              onAddSignSale(
                res && res.payload && res.payload.asset_id,
                signSaleBody
              ).then((responseData) => {
                message.success(
                  responseData &&
                    responseData.payload &&
                    responseData.payload.message
                );
              });
              setSignSaleLoading(false);
            }
          });
        });
      });
    }
  };

  return (
    <Fragment>
      <ProposalHeaderFilter
        onChangeSelections={onChangeSelections}
        showModal={showModal}
      />
      <div className="licnse-contract-sec">
        <Row gutter={16} className="mt20 margin--24">
          <Col span={12}>
            <LicenseContract
              history={history}
              showModal={showModal}
              getAllTradingTaskRes={getAllTradingTaskRes}
              getOrganizationProfileRes={getOrganizationProfileRes}
              getFormalLicenseRes={getFormalLicenseRes}
              onGetFormalLicense={onGetFormalLicense}
              location={location}
              onGetSign={onGetSign}
              delConfirmModal={delConfirmModal}
              loading={loading}
              logoImage={logoImage}
              cancelNegotiationRes={cancelNegotiationRes}
              onCancelNegotiation={onCancelNegotiation}
            />
          </Col>
          <Col span={12}>
            <LicenseSellingContract
              history={history}
              showModal={showModal}
              sellingAsset="selling-assets"
              getAllTradingTaskRes={getAllTradingTaskRes}
              getOrganizationProfileRes={getOrganizationProfileRes}
              getFormalLicenseRes={getFormalLicenseRes}
              onGetFormalLicense={onGetFormalLicense}
              location={location}
              onGetSign={onGetSign}
              getSignRes={getSignRes}
              SignSaleConfirmModal={SignSaleConfirmModal}
              signSaleloading={signSaleloading}
            />
          </Col>
        </Row>
      </div>
      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={false}
        className={
          modalType === "file_history"
            ? "modal-style history-modal"
            : "modal-style"
        }
        width={
          (modalType === "rules_regulation" && 500) ||
          (modalType === "send_invitation" && 800) ||
          (modalType === "file_history" && 500) ||
          (modalType === "license_edit" && 650) ||
          (modalType === "add_definition" && 650) ||
          (modalType === "invite_person" && 440) ||
          (modalType === "sign_now" && 440)
        }
      >
        {modalType === "rules_regulation" && (
          <RulesRegulationModal
            onVideoCall={onVideoCall}
            handleCancel={handleCancel}
            // setUserId={setUserId}
          />
        )}
        {modalType === "send_invitation" && (
          <NegotiationInvitation handleCancel={handleCancel} />
        )}
        {visible &&
          (modalType === "license_edit" || modalType === "add_definition") && (
            <FormalLicenseEdit
              handleCancel={handleCancel}
              modalType={modalType}
            />
          )}
        {modalType === "file_history" && (
          <Filehistory getLogsListRes={getLogsListRes} />
        )}
        {modalType === "invite_person" && (
          <InvitePersonsInRoom handleCancel={handleCancel} />
        )}
        {modalType === "sign_now" && (
          <SignNowModal handleCancel={handleCancel} />
        )}
      </Modal>
      <DeleteConfirmation
        visible={
          (modalType === "Sign Now" && delConfirm) ||
          (modalType === "Sign Sale" && signSaleConfirm)
        }
        handleModalCancel={closeDelConfirmModal}
        onDelete={
          (modalType === "Sign Now" && onConfirmDelete) ||
          (modalType === "Sign Sale" && onConfirmSignSale)
        }
        modalType={modalType}
      />
    </Fragment>
  );
};

export default SelectionProposalClosing;
