import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SelectionsDetail from "../Components/ContentArea/SelectionDetailCom";

import {
  getSelectionsList,
  getSelectionDetail,
  createSelection,
  updateSelection,
  deleteSelection,
  getSelectionAssets,
  delecteSelectionAssets,
  addFormalLicenseDefinition,
  getFormalLicenseList,
  deleteFormalLicense,
  updateFormalLicense,
  postTradingTask,
  getAllOrganization,
  createTradingList,
} from "../store/Actions";

const mapStateToProps = (state) => {
  return {
    selectionsList: state.selectionReducer.selectionsList,
    selectionDetail: state.selectionReducer.selectionDetail,
    createSelection: state.selectionReducer.createSelection,
    updateSelection: state.selectionReducer.updateSelection,
    deleteSelection: state.selectionReducer.deleteSelection,
    sessionRes: state.userReducer.sessionRes,
    selectionAssetsListRes: state.selectionReducer.selectionAssetsListRes,
    deleteAssetSelectionRes: state.selectionReducer.deleteAssetSelectionRes,
    addFormalLicenseRes: state.selectionReducer.addFormalLicenseRes,
    getFormalLicenseRes: state.selectionReducer.getFormalLicenseRes,
    deleteFormalLicenseRes: state.selectionReducer.deleteFormalLicenseRes,
    updateFormalLicenseRes: state.selectionReducer.updateFormalLicenseRes,
    postTradingTaskRes: state.requestProposalReducer.postTradingTaskRes,
    getAllOrganizationRes: state.selectionReducer.getAllOrganizationRes,
    createTradingListRes: state.userReducer.createTradingListRes,
    mainUserRes: state.userReducer.mainUserRes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetSelectionsList: bindActionCreators(getSelectionsList, dispatch),
    onGetSelectionDetail: bindActionCreators(getSelectionDetail, dispatch),
    onCreateSelection: bindActionCreators(createSelection, dispatch),
    onUpdateSelection: bindActionCreators(updateSelection, dispatch),
    onDeleteSelection: bindActionCreators(deleteSelection, dispatch),
    onGetSelectionAssets: bindActionCreators(getSelectionAssets, dispatch),
    onDeleteSelectionAssets: bindActionCreators(
      delecteSelectionAssets,
      dispatch
    ),
    onAddFormalLicenseDefinition: bindActionCreators(
      addFormalLicenseDefinition,
      dispatch
    ),
    onGetFormalLicenseList: bindActionCreators(getFormalLicenseList, dispatch),
    onDeleteFormalLicense: bindActionCreators(deleteFormalLicense, dispatch),
    onUpdateFormalLicense: bindActionCreators(updateFormalLicense, dispatch),
    onPostTradingTask: bindActionCreators(postTradingTask, dispatch),
    onGetAllOrganization: bindActionCreators(getAllOrganization, dispatch),
    onCreateTradingList: bindActionCreators(createTradingList, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectionsDetail);
