import * as types from '../Constants';
import { openNotificationWithIcon } from '../../Components/Common/reUseFunctions';

export const assetReducer = (state = {}, action) => {
  switch (action.type) {
    case types.GET_ASSETS_LIST_SUCCESS:
      return { ...state, assetsList: action.payload }
    case types.GET_ASSETS_LIST_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, assetsList: action.payload }
    case types.GET_ASSET_DETAIL_SUCCESS:
      return { ...state, assetDetail: action.payload }
    case types.GET_ASSET_DETAIL_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, assetDetail: action.payload }
    case types.CREATE_ASSET_SUCCESS:
      return { ...state, createAsset: action.payload }
    case types.CREATE_ASSET_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, createAsset: action.payload }
    case types.UPDATE_ASSET_SUCCESS:
      return { ...state, updateAsset: action.payload }
    case types.UPDATE_ASSET_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, updateAsset: action.payload }
    case types.DELETE_ASSET_SUCCESS:
      return { ...state, deleteAsset: action.payload }
    case types.DELETE_ASSET_FAILURE:
      openNotificationWithIcon(
        'error',
        'Error',
        "Please Try Again"
      );
      return { ...state, deleteAsset: action.payload }
    default:
      return state
  }
}