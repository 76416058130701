import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Select } from 'antd';
import { MinusCircleOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { multiLanguages } from './../../../../../../statics/data/multiLanguages'

const Index = ({
  helperText,
  requiredField
}) => {
  const [selectedLang, setSelectedLang] = useState([]);
  const [tabsChange, setTabsChange] = useState(true);

  const onChangeLanguage = value => {
    setSelectedLang(value);

  };
  return (
    <Row 
      gutter={16} 
      className={requiredField === true ? "metadata-tab-body_hide" : "mb5"}
    >

      <Col className="pl0_important" span={24}>
        <Link to='#' onClick={() => setTabsChange(!tabsChange)}>
          <div
            className={tabsChange === true ? "metadata-tab-title" : "metadata-tab-title metadata-tab-active"}
          >
            <h3>Subtitle Track</h3>
            <i className="ant-menu-submenu-arrow ant-menu-submenu-arrow-list"></i>
          </div>
        </Link>
      </Col>

      {
        helperText &&
        <Col span={24} className={tabsChange === true && "hide-helper-text"}>
          <div className="heper-text"><p>Indicate the subtitles available for this asset.</p></div>
        </Col>
      }

      <Col span={24}>
        <div className={tabsChange === true ? "metadata-tab-body metadata-tab-body_hide" : "metadata-tab-body"}>
          <Form.List name="subtitleTrack">
            {(fields, { add, remove }) => {
              return (
                <div className="metadata-field-box">
                  {fields.map(field => (
                    <div>
                      <Row gutter={16} className="mb20">
                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Language</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'language']}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  {
                                    pattern: new RegExp("[a-z]{2}(\-[A-Z]{2})?"),
                                    message: "Please type the Language"
                                  }
                                ]}
                                className="full-width mr20 mb0"
                              >
                                <Select
                                  placeholder="Select language"
                                  value={selectedLang}
                                  onChange={onChangeLanguage}
                                  style={{ width: '100%' }}
                                  showSearch
                                  filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  getPopupContainer={trigger => trigger.parentElement}
                                >
                                  {multiLanguages.map((item, index) => (
                                    <Select.Option key={index} value={item.value}>
                                      {item.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>
                          </div>
                        </Col>

                        <Col span={24}>
                          <div className="metadata-field-row">
                            <div className="metadata-field-name"><h3 className=""><span>Subtitle Tracks Id</span></h3></div>
                            <div className="metadata-filed">
                              <Form.Item
                                {...field}
                                name={[field.name, 'id']}
                              >
                                <Input placeholder="Id" />
                              </Form.Item>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <MinusCircleOutlined
                            className="ml10 remove-gray-icon"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}

                  <div className="d-flex align-item-center flex-end add-more-btn">
                    <Form.Item className="m0">
                      <Button
                        type="link"
                        onClick={() => {
                          add();
                        }}
                        style={{ width: '100%' }}
                      >
                        <PlusOutlined /> Add Subtitle Track
                      </Button>
                    </Form.Item>
                  </div>

                </div>
              );
            }}
          </Form.List>
        </div>
      </Col>
    </Row>
  );
};

export default Index;