import React from "react";
import { Input, Row, Col, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

const Filter = ({ onSearch, search }) => {
  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  return (
    <Row gutter={16} className="mb15 add-selection-filter">
      <Col span={24}>
        <h2>Selections</h2>
      </Col>
      <Col span={8}>
        <Select
          defaultValue="All"
          style={{ width: 120 }}
          onChange={handleChange}
        >
          <Option value="All">All</Option>
          <Option value="last_selections">Last Selections</Option>
          <Option value="new_selections">New Selections</Option>
        </Select>
      </Col>
      <Col span={16}>
        <Input
          value={search}
          onChange={onSearch}
          prefix={<SearchOutlined />}
          placeholder="Search Selections"
          className="width-300"
        />
      </Col>
    </Row>
  );
};

export default Filter;
