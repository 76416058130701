import React, { useEffect, useState } from "react";
import { Row, Col, Collapse, Button } from "antd";
import { Link } from "react-router-dom";
import { RightOutlined, DownOutlined } from "@ant-design/icons";

import RequestProposalTab from "./RequestProposalTab";
import RequestProposalForm from "./RequestProposalForm";
import { data } from "jquery";

const { Panel } = Collapse;

const RequestProposalTabList = ({
  onCreateTradingList,
  postTradingTaskRes,
  selectionDetail,
  userManagementList,
  getUserListRes,
  mainUserRes,
  departmentsWithUsersRes,
  setAssetObject,
  history,
  selectionAssetsListRes,
}) => {
  const [tabsChange, setTabsChange] = useState(false);
  const [assetId, setAssetId] = useState("");

  // const genExtra = () => (
  //   <div className="i-selecion-btn s-col-actions">
  //     <Button type="link">Remove</Button>
  //     <RightOutlined />
  //   </div>
  // );

  useEffect(() => {
    if (
      postTradingTaskRes &&
      postTradingTaskRes.data &&
      postTradingTaskRes.data.organizations &&
      postTradingTaskRes.data.organizations.length > 0
    ) {
      if (
        postTradingTaskRes &&
        postTradingTaskRes.data &&
        postTradingTaskRes.data.organizations[0] &&
        postTradingTaskRes.data.organizations[0].assets &&
        postTradingTaskRes.data.organizations[0].assets.length > 0
      ) {
        onSelectAsset(postTradingTaskRes.data.organizations[0].assets[0]);
      }
    }
  }, [postTradingTaskRes]);

  const onSelectAsset = (data) => {
    setAssetObject(data);
    setAssetId(data.id);
  };

  return (
    <div className="License-assets-collapse c-col-main trading-task-detail-tabs">
      {postTradingTaskRes &&
        postTradingTaskRes.data &&
        postTradingTaskRes.data.organizations &&
        postTradingTaskRes.data.organizations.length > 0 &&
        postTradingTaskRes.data.organizations.map((data, index) => {
          return (
            <div className="request-proposal-tab" key={index}>
              <Row gutter={16} className="mb0">
                <Col className="pl0_important" span={24}>
                  <Link to="#" onClick={() => setTabsChange(!tabsChange)}>
                    <div
                      className={
                        tabsChange === true
                          ? "request-proposal-header"
                          : "request-proposal-header request-proposal-header-active"
                      }
                    >
                      <h3>{data.name}</h3>
                      <DownOutlined />
                    </div>
                  </Link>
                </Col>
                <Col span={24}>
                  <div
                    className={
                      tabsChange === false
                        ? "metadata-tab-body metadata-tab-border mb20"
                        : "metadata-tab-body metadata-tab-body_hide"
                    }
                  >
                    {data &&
                      data.assets &&
                      data.assets.length > 0 &&
                      data.assets.map((items, index) => {
                        return (
                          <div key={index}>
                            {/* <RequestProposalTab /> */}
                            <div
                              onClick={() => onSelectAsset(items)}
                              className={
                                assetId === items.id
                                  ? "proposal-tabs proposal-tabs-active"
                                  : "proposal-tabs"
                              }
                            >
                              <h3>{items.name}</h3>
                              <div>
                                <Button type="link">
                                  <u>Remove</u>
                                </Button>
                                <span>
                                  <RightOutlined />
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    <div className="metadata-tab-body metadata-tab-border mb20">
                      <RequestProposalForm
                        onCreateTradingList={onCreateTradingList}
                        selectionAssetsListRes={selectionAssetsListRes}
                        selectionDetail={selectionDetail}
                        data={data}
                        history={history}
                        userManagementList={userManagementList}
                        getUserListRes={getUserListRes}
                        mainUserRes={mainUserRes}
                        departmentsWithUsersRes={departmentsWithUsersRes}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
    </div>
  );
};

export default RequestProposalTabList;
